import React, { useState } from "react";
import { Rate, Typography } from "antd";
import {
  FireOutlined,
  ClockCircleOutlined,
  PictureOutlined,
  PlayCircleOutlined,
  CheckOutlined,
  StarOutlined,
} from "@ant-design/icons";
import moment from "moment";
import ReactPlayer from "react-player";
import { postRequest } from "../../../utils/api.utils";
import FeedbackDrawer from "../orderCards/FeedbackDrawer";

const ChadhavaUpdates = ({ order }) => {
  const [isFeedbackOpen, setIsFeedbackOpen] = useState(false);
  const [rating, setRating] = useState(order?.feedback?.rating || 0);

  // Extracting Order Status
  const isPending = order.chadawa_status?.toUpperCase() === "PENDING";
  const isConducted = order.chadawa_status?.toUpperCase() === "CONDUCTED";
  const isDelivered = order.chadawa_status?.toUpperCase() === "DELIVERED";

  const handleRating = async (value) => {
    setRating(value);
    await postRequest(`/api/v1/chadawa/updateOrderFeedback`, {
      id: order.id,
      feedback: { rating: value },
    });
    setIsFeedbackOpen(true);
  };

  const updates = [
    {
      icon: isPending ? (
        <ClockCircleOutlined className="text-yellow-500 text-xs" />
      ) : (
        <CheckOutlined className="text-green-500 text-xs" />
      ),
      text: (
        <div className="text-xs">
          <strong>
            Your Chadhawa is scheduled for{" "}
            {moment(order.chadawa_starting_at).format("DD MMMM YYYY")}
          </strong>
        </div>
      ),
    },
    {
      icon:
        isConducted || isDelivered ? (
          <CheckOutlined className="text-green-500 text-xs" />
        ) : (
          <ClockCircleOutlined className="text-gray-500 text-xs" />
        ),
      text:
        isConducted || isDelivered ? (
          <div className="text-xs">
            <strong>
              Your Chadhawa has been successfully offered at the temple.
            </strong>
            <p className="text-sm text-gray-500 mt-1">
              The temple priests have completed the offering on your behalf.
            </p>
          </div>
        ) : (
          <div className="text-xs">
            <p>You will receive a message when chadhawa is offered.</p>
          </div>
        ),
    },
    {
      icon: isDelivered ? (
        <CheckOutlined className="text-green-500 text-xs" />
      ) : (
        <PlayCircleOutlined className="text-gray-500 text-xs" />
      ),
      text: isDelivered ? (
        <div className="text-xs">
          <strong>
            Your Chadhawa video is now available! Offerings start at{" "}
            {order.sankalp_time}.
          </strong>
          <a
            href={order.video_url}
            target="_blank"
            rel="noopener noreferrer"
            className="block text-blue-600 mt-1"
          >
            Watch Video →
          </a>
          <div className="rounded-lg mt-2 w-full bg-gray-200 flex items-center justify-center">
            <ReactPlayer
              height="90%"
              width="100%"
              playing={false}
              muted={false}
              controls
              url={order?.video_url}
            />
          </div>
        </div>
      ) : (
        <div className="text-xs">
          <p>Your Chadhawa video will be available in 2-3 days.</p>
        </div>
      ),
    },
  ];


  return (
    <div
      id={"pujaUpdates"}
      className="bg-white p-4 mx-4 mt-4 rounded-xl border"
    >
      <FeedbackDrawer
        open={isFeedbackOpen}
        setOpen={setIsFeedbackOpen}
        order={order}
        rating={rating}
      />
      <h2 className="text-sm font-semibold text-gray-800 mb-4">
        Chadhawa Updates
      </h2>

      <div className="border border-gray-300 rounded-xl p-4 transition-shadow  mt-2">
        {isConducted || isDelivered ? (
          <div className="">
            {!isDelivered && (
              <Typography.Text>
                Your Puja video will be shared in 2-3 days after the Puja
              </Typography.Text>
            )}
            {isDelivered && (
              <>
                <Typography.Text>
                Chadhawa Video has arrived. Offerings in your name starts at{" "}
                  <strong>{order?.sankalp_time}</strong>
                </Typography.Text>

                <a
                  href={order.video_url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="rounded-lg mt-1 w-full bg-gray-200 flex items-center justify-center">
                    <ReactPlayer
                      height="90%"
                      width="100%"
                      className="rounded-lg"
                      playing={false}
                      muted={false}
                      controls
                      url={order?.video_url}
                    />
                  </div>
                </a>
              </>
            )}
          </div>
        ) : (
          <div className="">
            Your Puja video will be shared in 2-3 days after the Puja
          </div>
        )}

        {/* <div className="mt-2">
        {isDelivered  && (
          <>
            {
              <div>
                <Typography.Text className="text-sm font-medium">
                  How was your puja experience at Devpunya?
                </Typography.Text>
                <div className="block">
                  <Rate
                    desc=""
                    className="text-3xl"
                    // onChange={(e) => onSubmitStart(e)}
                    // value={value}
                  />
                </div>

                {order?.feedback?.rating &&
                !order?.feedback?.suggestions ? (
                  <div
                    // onClick={() => setopenFeedbackDrawer(true)}
                    className="text-orange-500  mt-4 text-xs"
                  >
                    Share your feedback
                  </div>
                ) : (
                  ""
                )}
              </div>
            }
          </>
        )}
      </div> */}
      </div>

      {/* <div className="relative">
        {updates.map((update, index) => (
          <div key={index} className="flex items-start relative">
            <div
              className={`absolute left-[18px] ${
                index !== updates.length - 1 ? "h-full" : "h-0"
              }`}
              style={{
                borderLeft: "2px dotted #d1d5db",
                top: "32px",
                zIndex: 0,
              }}
            ></div>

            <div
              className={`z-10 bg-white p-2 rounded-full flex justify-center items-center border ${
                isDelivered ? "border-green-500" : "border-gray-300"
              } relative`}
            >
              {update.icon}
            </div>

            <div className="ml-6 flex-1 pb-6">
              <p className="text-gray-800 text-sm leading-relaxed">
                {update.text}
              </p>
            </div>
          </div>
        ))}
      </div> */}
    </div>
  );
};

export default ChadhavaUpdates;
