import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Button, Breadcrumb, Divider, Collapse, Typography } from "antd";
import { HomeOutlined } from "@ant-design/icons";
import Page from "../../layouts/Page";
import { getRequest } from "../../utils/api.utils";
import Loader from "../../components/Loader";
import PoojaCarousel from "../pooja/PoojaCarousel";
import WhyChooseDevPunya from "../../components/WhyChooseDevPunya";
import CustomerReviews from "../../components/CustomerReviews";
import BenefitsSection from "../../components/BenefitsSection";
import AdditionalKavachItems from "../../components/AdditionalKavachItems";
import Footer from "../../layouts/Footer";
import { IoArrowForward } from "react-icons/io5";
import { abhimantritAddToCart } from "../../events/facebookPexelsEvent";

const AbhimantritProductDetails = () => {
  const { id } = useParams(); // Get the product ID from the URL
  const navigate = useNavigate();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false); // State to control description toggle

  const getAbhimantritProduct = async () => {
    const res = await getRequest(
      `/noauth-api/v1/abhimantrit_product/getProductListing?product_id=${id}`
    );
    setProduct(res.results);
    setLoading(false);
  };

  useEffect(() => {
    getAbhimantritProduct();
  }, [id]);

  const toggleDescription = () => {
    setIsDescriptionExpanded(!isDescriptionExpanded);
  };

  if (loading)
    return (
      <Page>
        <Loader />
      </Page>
    );
  if (!product) {
    return (
      <Page>
        <div className="text-center py-20">
          <h2 className="text-2xl text-gray-700">No product found.</h2>
          <Button type="primary" onClick={() => navigate("/products")}>
            Go Back
          </Button>
        </div>
      </Page>
    );
  }

  const benefitsItems =
    product.tabs?.data.find((tab) => tab.title === "Benefits")?.data || [];

  const faqItems =
    product.tabs?.data.find((tab) => tab.key === "FAQs")?.data || [];
  const faqData = faqItems.map((item, index) => ({
    key: index + 1,
    label: (
      <Typography.Text className="font-semibold text-xs">
        {item.title}
      </Typography.Text>
    ),
    children: (
      <Typography.Text className="text-xs">{item.description}</Typography.Text>
    ),
  }));

  const testimonials =
    product.tabs?.data.find((tab) => tab.key === "reviews") || [];

  const whyChooseUs =
    product.tabs?.data.find((tab) => tab.key === "Why_Choose") || [];

  const Additional_Kavach_Items =
    product.tabs?.data.find((tab) => tab.key === "Additional_Kavach_Items") ||
    [];

  return (
    <Page>
      <div className="max-w-5xl mx-auto px-2 bg-white shadow-lg rounded-lg min-h-screen">
        <PoojaCarousel product={product} setBgBlur={() => {}} />

        <div className="mt-2">
          {/* Product Details */}
          <div className="p-2 w-full">
            <h1 className="text-2xl font-bold text-gray-900 ">
              {product.name}
            </h1>

            <h3
              className="text-sm text-orange-500"
              // style={{
              //   display: "-webkit-box",
              //   WebkitBoxOrient: "vertical",
              //   WebkitLineClamp: 1, // Limits the text to 1 lines
              //   overflow: "hidden",
              //   textOverflow: "ellipsis",
              // }}
            >
              {product.benefit}
            </h3>
            <p className="text-base text-gray-700 ">
              {isDescriptionExpanded
                ? product.description
                : `${product.description?.slice(0, 120)}...`}
              <span
                className="text-blue-500 text-xs"
                onClick={toggleDescription}
              >
                {isDescriptionExpanded ? "read Less" : "read More"}
              </span>
            </p>

            <div className="flex items-center mt-4">
              <p className="text-2xl text-green-600 font-bold">
                ₹{product.discounted_price}
              </p>
              <p className="text-xl text-gray-500 font-bold line-through ml-2">
                ₹{product.price}
              </p>
            </div>
          </div>
        </div>

        {Additional_Kavach_Items?.data?.length > 0 && (
          <AdditionalKavachItems
            items={Additional_Kavach_Items?.data}
            title={Additional_Kavach_Items?.title}
          />
        )}

        {benefitsItems?.length > 0 && (
          <BenefitsSection benefits={benefitsItems} />
        )}

        {/* Why Choose DevPunya Section */}
        {whyChooseUs.data?.length > 0 && (
          <WhyChooseDevPunya
            title={whyChooseUs.title}
            reasons={whyChooseUs.data}
          />
        )}

        {/* Testimonials Section */}
        {testimonials?.data?.length > 0 && (
          <CustomerReviews
            title={testimonials.title}
            reviews={testimonials?.data}
          />
        )}

        {/* FAQ Section */}
        <div className="mt-2">
          <h3 className="text-xl font-medium">FAQ</h3>
          <div className="my-4">
            <Collapse defaultActiveKey={[1]} items={faqData} />
          </div>
        </div>

        <Footer />
        <Divider />
        <div className={`p-1 w-full  absolute bg-white bottom-0 left-0 right-0`}>
          {/* <Button
            size="large"
            className="w-full border-none text-white font-medium "
            onClick={() => navigate(`/abhimantrit_product/${product.id}/order`)}
          >
            Book Now
          </Button> */}

          <div className="w-full  shadow px-4 py-1 bg-white">
            <button
              onClick={() =>
              {
                abhimantritAddToCart()
                navigate(`/abhimantrit_product/${product.id}/order`)
              }
              }
              className="bg-green-500 font-semibold w-full py-2 rounded-lg h-full text-white text-xl flex items-center justify-center space-x-2 relative group"
            >
              Book Now
              <IoArrowForward className="text-white text-xl " />
            </button>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default AbhimantritProductDetails;
