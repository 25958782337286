import React, { useEffect, useState } from "react";
import { CloseCircleOutlined, EditOutlined } from "@ant-design/icons";
import Logo from "../../assets/images/logo_image.jpeg";
import { postRequest } from "../../utils/api.utils";
import { Divider, Drawer, message, Spin } from "antd";
import { useNavigate } from "react-router-dom";
import {
  countryOptions,
  getCountryCode,
  getUTMParams,
  validatePhoneNumber,
} from "../../utils/helper.utils";
import OTPInput from "react-otp-input";
import "./authBlur.css";
import { leadPX } from "../../events/facebookPexelsEvent";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import Page from "../../layouts/Page";
import vd from "../../assets/open_loading.gif";
import bg_auth from "../../assets/bg_auth.png";
import PhoneInput from "./PhoneInput";

type AuthProps = {
  isEdit?: boolean;
  isOpen?: boolean;
  redirectUrl?: string;
  btnText?: string;
  setIsOpenOutside?: any;
  redirectData?: any;
  onSubmit?: any;
  outSideClickHandler?: any;
  eventHandler?: any;
};

const AuthBlur: React.FC<AuthProps> = ({
  isEdit,
  isOpen,
  redirectUrl,
  btnText,
  setIsOpenOutside,
  redirectData,
  onSubmit,
  outSideClickHandler,
  eventHandler,
}) => {
  const userJSON = localStorage.getItem("user");
  const user: any = userJSON ? JSON.parse(userJSON) : null;
  let cCode = getCountryCode();

  let location_info = localStorage.getItem("location_info")
    ? JSON.parse(localStorage.getItem("location_info") as string)
    : null;

  let country_code = countryOptions.find(
    (item) => item?.short_name?.toLowerCase() === cCode
  )?.dial_code;

  let isIndia = cCode === "in";

  const [whatsAppNumber, setWhatsAppNumber] = useState(
    user?.userDetails?.phone || ""
  );
  const [name, setName] = useState(user?.userDetails?.fullname || "");
  const [gotra, setGotra] = useState("");
  const [email, setEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isButtonEnable, setIsButtonEnable] = useState(isIndia ? true : false);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(user ? false : true);
  const [countryCode, setCountryCode] = useState(country_code || "91");

  const navigate = useNavigate();

  useEffect(() => {
    if (isOpen) {
      setOpen(true);
    }

    leadPX();
  }, [isOpen]);

  useEffect(() => {
    let _wtspnum = whatsAppNumber?.slice(3);
    if (_wtspnum?.length === 10 && isIndia) {
      setIsButtonEnable(true);
    } else {
      setIsButtonEnable(false);
    }
  }, [whatsAppNumber]);

  useEffect(() => {
    if (email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      setIsEmailValid(emailRegex.test(email));
    } else {
      setIsEmailValid(true);
    }
  }, [email]);

  const [OTPComponent, setOTPComponent] = useState(false);
  const [otp, setOTP] = useState("");
  const [sessionIdData, setSessionIdData] = useState<any>(null);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setOTP("");

    if (!isEmailValid) {
      message.error("Please enter a valid email address");
      return;
    }

    const utm = getUTMParams();

    let reqBody: any = {};

    const userAgent = window.navigator?.userAgent;
    const platform = window.navigator?.platform;
    const randomString =
      Math.random().toString(20).substring(2, 14) +
      Math.random().toString(20).substring(2, 14);

    const deviceID = `${userAgent}-${platform}-${randomString}`;

    if (isIndia) {
      reqBody = {
        isdCode: "+" + countryCode,
        phone: whatsAppNumber?.slice(3),
        fullname: name,
        email: email || undefined, // Send email only if provided
        countrycode: cCode || "in",
      };
    } else {
      reqBody = {
        isdCode: "+" + countryCode,
        phone: whatsAppNumber?.slice(3),
        // channel: "WHATSAPP",
        fullname: name,
        email: email || undefined,
        countrycode: cCode || "in",
      };
    }

    if (location_info) {
      reqBody.location = location_info;
    }
    if (deviceID) {
      reqBody.device = deviceID;
    }
    if (utm) {
      reqBody.utm = utm;
    }

    if (!validatePhoneNumber(reqBody.phone)) {
      message.error("Please enter a valid mobile number");
      return;
    }

    setLoading(true);

    postRequest(`/noauth-api/v1/login/user/getotp`, reqBody)
      .then((data: any) => {
        setSessionIdData(data.data.results);

        postRequest("/noauth-api/v1/login/user/verifyotp", {
          ...reqBody,
          otp: "2262",
          session_id: data.data.results.session_id,
        })
          .then((res) => {
            setLoading(false);

            localStorage.setItem(
              "user",
              JSON.stringify({ ...res?.data?.results })
            );
            if (window.location.pathname === "/auth") {
              navigate("/");
            } else if (redirectUrl) {
              if (eventHandler) {
                eventHandler();
              }
              navigate(redirectUrl, { state: redirectData });
            } else if (onSubmit) {
              
              onSubmit(res?.data?.results);
              setOpen(false);
            } else {
              window.location.reload();
            }
            setLoading(false);
          })
          .catch((err) => {})
          .finally(() => {
            setLoading(false);
          });

        // if (isIndia) {
        //   postRequest("/noauth-api/v1/login/user/verifyotp", {
        //     ...reqBody,
        //     otp: "2262",
        //     session_id: data.data.results.session_id,
        //   })
        //     .then((res) => {
        //       setLoading(false);

        //       localStorage.setItem(
        //         "user",
        //         JSON.stringify({ ...res?.data?.results })
        //       );
        //       if (window.location.pathname === "/auth") {
        //         navigate("/");
        //       } else if (redirectUrl) {
        //         if(eventHandler){
        //           eventHandler()
        //         }
        //         navigate(redirectUrl, { state: redirectData });
        //       } else if (onSubmit) {
        //         onSubmit();
        //         setOpen(false);
        //       } else {
        //         window.location.reload();
        //       }
        //       setLoading(false);
        //     })
        //     .catch((err) => {})
        //     .finally(() => {
        //       setLoading(false);
        //     });
        // } else {
        //   setOTPComponent(true);
        //   message.success("OTP sent successfully");
        //   setLoading(false);
        //   setTimeout(() => {
        //     setOTP(String(data.data.results.otp_session.otp));
        //   }, 500);
        // }
      })
      .catch(() => {
        setLoading(false);
        message.error("Something went wrong. Please try again later");
      });
  };

  const verifyOTP = (e?: any) => {
    e?.preventDefault();
    if (!sessionIdData) {
      message.error("We are facing some issue. Please try again later");
      return;
    }

    if (!otp) {
      message.error("Please enter OTP");
      return;
    }

    if (otp.length !== 4) {
      message.error("Please enter a valid OTP");
      return;
    }

    setLoading(true);

    let reqBody: any = {
      otp: otp || "2262",
      phone: whatsAppNumber?.slice(3),
      session_id: sessionIdData?.session_id,
      fullname: name,
      email: email || undefined,
      utm: getUTMParams(),
      countrycode: cCode || "in",
      isdCode: "+" + countryCode,
    };

    if (location_info) {
      reqBody.location = location_info;
    }

    const userAgent = window.navigator.userAgent;
    const platform = window.navigator.platform;
    const randomString =
      Math.random().toString(20).substring(2, 14) +
      Math.random().toString(20).substring(2, 14);

    const deviceID = `${userAgent}-${platform}-${randomString}`;

    if (deviceID) {
      reqBody.device = deviceID;
    }

    postRequest("/noauth-api/v1/login/user/verifyotp", reqBody)
      .then((res) => {
        localStorage.setItem("user", JSON.stringify({ ...res?.data?.results }));
        if (window.location.pathname === "/auth") {
          navigate("/");
        } else if (redirectUrl) {
          navigate(redirectUrl, { state: redirectData });
        } else if (onSubmit) {
          onSubmit();
          setOpen(false);
        } else {
          window.location.reload();
        }
        setLoading(false);
      })
      .catch((err) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const handleGoogleAuth = async (credentialResponse: any) => {

    setLoading(true);
    let reqBody: any = {};

    if (location_info) {
      reqBody.location = location_info;
    }

    const userAgent = window.navigator.userAgent;
    const platform = window.navigator.platform;
    const randomString =
      Math.random().toString(20).substring(2, 14) +
      Math.random().toString(20).substring(2, 14);

    const deviceID = `${userAgent}-${platform}-${randomString}`;

    if (deviceID) {
      reqBody.device = deviceID;
    }

    await postRequest(`/noauth-api/v1/auth/google/callback`, {
      credential: credentialResponse.credential,
      client_id: credentialResponse.clientId,
      utm: getUTMParams(),
      countrycode: cCode || "in",
      ...reqBody,
    })
      .then((res: any) => {
        localStorage.setItem("user", JSON.stringify({ ...res?.data?.results }));
        setLoading(false);

        if (window.location.pathname === "/auth") {
          navigate("/");
        } else if (redirectUrl) {
          navigate(redirectUrl, { state: redirectData });
        } else if (onSubmit) {
          onSubmit();
          setOpen(false);
        } else {
          window.location.reload();
        }
      })
      .catch((Err) => {
        setLoading(false);
      });
  };

  let isOutsideIndia =  cCode !== "in"

  return (
    <>
      <>
        <>
          {isEdit && (
            <span
              onClick={() => setOpen(true)}
              className="text-blue-500 underline cursor-pointer"
            >
              <EditOutlined /> Edit
            </span>
          )}

          {open && (
            <div className="modal-container">
              <div className="modal-content">
                <div className="flex items-center justify-center w-full">
                  <img
                    src={Logo}
                    alt="logo"
                    className="object-contain w-[200px]"
                  />
                </div>
                <div className="py-4">
                    
                    <form
                    onSubmit={OTPComponent ? verifyOTP : handleSubmit}
                    className="modal-form"
                  >
                    <h2 className="text-center font-semibold mt-2 mb-3">
                      {isIndia ? "Provide Contact Details" : "Login Details"}
                    </h2>

                    {/* WhatsApp Number Field */}
                    <PhoneInput
                      disabled={OTPComponent}
                      setWhatsAppNumber={setWhatsAppNumber}
                      countryCode={countryCode}
                      setCountryCode={setCountryCode}
                      whatsAppNumber={whatsAppNumber}
                    />
                    <span className="text-xs text-gray-500">
                      We will share Puja updates, timings & Puja video on your WhatsApp &
                      email
                    </span>
                    {OTPComponent ? (
                      <>
                        <span className="input-label block mt-4 text-base text-gray-600 text-center">
                          Enter your OTP{" "}
                        </span>
                        <span className="block text-sm text-gray-500 text-center">
                          ✅ An 4 digit code has been sent to +{countryCode}{" "}
                          {whatsAppNumber}{" "}
                          <span
                            onClick={() => {
                              setOTPComponent(false);
                            }}
                          >
                            {" "}
                            <EditOutlined />{" "}
                          </span>
                        </span>
                      </>
                    ) : (
                      <>
                        {/* Name Field */}
                        <label>
                          <span className="input-label mt-4">Name</span>
                          <div className="input-wrapper">
                            <input
                              type="text"
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                              className="input-field"
                              placeholder="Enter Your Name"
                            />
                          </div>
                        </label>

                        {/* Email Field */}
                        <label>
                          <span className="input-label mt-4">
                            Email (Optional)
                          </span>
                          <div className="input-wrapper">
                            <input
                              type="email"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              className={`input-field ${
                                email && !isEmailValid ? "border-red-500" : ""
                              }`}
                              placeholder="Enter Your Email"
                            />
                          </div>
                          {!isEmailValid && (
                            <p className="text-red-500 text-sm">
                              Invalid email address
                            </p>
                          )}
                        </label>
                      </>
                    )}
                    {OTPComponent && (
                      <div className="w-full">
                        <OTPInput
                          shouldAutoFocus
                          inputStyle={{
                            width: "50px",
                            height: "50px",
                            margin: "0 8px",
                            fontSize: "20px",
                            borderRadius: "8px",
                            border: "1px solid #ddd",
                            textAlign: "center",
                            outline: "none",
                            boxShadow: "0 0 5px rgba(0,0,0,0.1)",
                          }}
                          containerStyle={{
                            justifyContent: "center",
                            marginTop: "16px",
                          }}
                          value={otp}
                          onChange={setOTP}
                          numInputs={4}
                          renderSeparator={<span className="block"></span>}
                          renderInput={(props) => <input {...props} />}
                        />
                      </div>
                    )}

                    {/* Submit Button */}
                    <button
                      type="submit"
                      className={`submit-button bg-orange-500   ${
                        loading && "disabled"
                      }`}
                      disabled={loading}
                    >
                      {loading ? (
                        <Spin />
                      ) : btnText || OTPComponent ? (
                        btnText || "Verify"
                      ) : (
                        "Submit"
                      )}
                    </button>
                  </form>

                  {isOutsideIndia && <>
                    <Divider>Or</Divider>

                      <GoogleOAuthProvider clientId="721636515726-af5b7frsqgtilqfune1iegsmga3ucop0.apps.googleusercontent.com">
                      {loading ? (
                        <div className="text-center text-3xl">
                          <Spin />
                        </div>
                      ) : (
                        <GoogleLogin
                          onSuccess={(credentialResponse) => {
                            console.log(credentialResponse);
                            handleGoogleAuth(credentialResponse);
                          }}
                          onError={() => {
                            console.log("Login Failed");
                          }}
                          useOneTap
                        />
                      )}
                    </GoogleOAuthProvider>
                    </>}
                  </div>
              </div>
              <div
                className="modal-backdrop"
                onClick={() => {
                  setIsOpenOutside && setIsOpenOutside();
                  outSideClickHandler && outSideClickHandler();
                  setOpen(false);
                }}
              />
            </div>
          )}
        </>
      </>
    </>
  );
};

export default AuthBlur;
