import React, { useState } from "react";
import { countryOptions, getCountryCode } from "../../utils/helper.utils";
import { Drawer } from "antd";
import Input from "react-phone-number-input/input";

export default function PhoneInput({
  setCountryCode,
  countryCode,
  whatsAppNumber,
  setWhatsAppNumber,
  disabled,
  isOptional = false,
  noTitle= false,
  notRequired = true
}) {
  const cCode = getCountryCode();

  const [openCountryCode, setOpenCountryCode] = useState(false);

  let listing_country = localStorage.getItem("listing_country");

  // Ensure data is parsed properly
  try {
    listing_country = listing_country
      ? typeof listing_country === "string"
        ? JSON.parse(listing_country)
        : listing_country
      : null;
  } catch (error) {
    console.error("Error parsing listing_country:", error);
    listing_country = null; // Fallback to null if parsing fails
  }

  // 1. in,listing,current location
  let flt = countryOptions.filter(
    (cnt) =>
      cnt?.short_name?.toLowerCase() === cCode ||
      cnt?.short_name?.toLowerCase() === listing_country?.country_code
  );

  let isIndiaExist = flt.find((cnt) => cnt?.short_name?.toLowerCase() === "in");
  let fltredCountryOptions = flt;

  if (!isIndiaExist) {
    fltredCountryOptions.push({
      name: "India",
      dial_code: "91",
      emoji: "🇮🇳",
      short_name: "IN",
    });
  }  

  return (
    <label>
      {!noTitle && <span className="input-label">
        WhatsApp Number{" "}
        {isOptional ? (
          <span className="text-gray-400 font-light">(Optional)</span>
        ) : (
          "*"
        )}
      </span>}
      <div className="input-wrapper">
        <span
          onClick={() => {
            if (cCode !== "in") {
              setOpenCountryCode(true);
            }
          }}
          className="prefix"
        >
          +{countryCode}
        </span>
        <Drawer
          closable={false}
          height={"30vh"}
          placement="bottom"
          open={openCountryCode}
          className="rounded-t-3xl"
          onClose={() => setOpenCountryCode(false)}
        >
          {fltredCountryOptions.map((item, index) => (
            <div
              key={index}
              className={`w-full text-lg border-b px-2 mb-4 border-gray-200 py-2 cursor-pointer ${
                countryCode === item.dial_code
                  ? "bg-orange-600 text-white rounded-lg"
                  : ""
              }`}
              onClick={() => {
                setCountryCode(item.dial_code);
                setOpenCountryCode(false);
              }}
            >
              {item.emoji} {item.name} (+{item.dial_code})
            </div>
          ))}
        </Drawer>
        <Input
          country="IN"
          onChange={(e) => {
            setWhatsAppNumber(e);
          }}
          value={whatsAppNumber}
          className="input-field w-full"
          placeholder="Enter WhatsApp Number"
          disabled={disabled}
        />
      </div>
    </label>
  );
}