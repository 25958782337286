import React from "react";
import { IoClose } from "react-icons/io5";
import { Button } from "antd";

const ConfirmationDrawer = ({ visible, onClose, formData, wish, gotra, onOrderPlace }) => {
  if (!visible) return null;

  const userJSON = localStorage.getItem("user");
  const user = userJSON ? JSON.parse(userJSON) : null;

  return (
    <>
      {/* Background Overlay */}
      <div
        className="fixed inset-0 bg-black bg-opacity-40 z-50 flex justify-center items-end transition-all duration-300"
        onClick={onClose}
      ></div>

      {/* Drawer */}
      <div
        className="fixed bottom-0 left-0 bg-white rounded-t-3xl p-6 w-full max-h-[60%] overflow-y-auto z-50"
        onClick={(e) => e.stopPropagation()}
      >
        {/* Close Icon */}
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-semibold text-gray-800">
            Please review your details
          </h3>
          <IoClose
            className="text-2xl text-gray-600 cursor-pointer"
            onClick={onClose}
          />
        </div>

        {/* Details Section */}
        <div className="mb-6">
          <h4 className="text-sm font-semibold text-gray-800">
            Members participating in the puja
          </h4>
          <p className="text-sm text-gray-500 mb-4">
            Panditji will take these names along with gotra during the puja.
          </p>
          <ul className="list-decimal pl-5 mt-2 text-gray-600">
            {formData.map((member, index) =>
              member.name ? (
                <li className="mb-2 text-base font-medium" key={index}>
                  {member.name}
                </li>
              ) : null
            )}
          </ul>
        </div>

        <div className="text-sm text-gray-600 space-y-3">
          <p>
            <strong>Gotra:</strong> {gotra}
          </p>
          <p>
            <strong>WhatsApp number:</strong> {user?.userDetails?.phone || "N/A"}
          </p>
          <p>
            <strong>Your wish:</strong> {wish || "N/A"}
          </p>
        </div>

        {/* Single Action */}
        <div className="mt-8">
          <Button
            className="w-full bg-green-500 text-white text-lg font-bold h-12 rounded-lg hover:bg-green-600"
            onClick={onOrderPlace}
          >
            Pay Now
          </Button>
        </div>
      </div>
    </>
  );
};

export default ConfirmationDrawer;