import React, { useState } from "react";
import { Button, Input, Rate, message } from "antd";
import {
  FrownOutlined,
  MehOutlined,
  SmileOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { postRequest } from "../../../utils/api.utils"; // Assuming you already have this utility function

const FeedbackDrawer = ({ open, setOpen, orderStatusData, rating }) => {
  const [selectedEmoji, setSelectedEmoji] = useState(
    rating || orderStatusData?.feedback?.rating || 0
  );
  const [otherSuggestion, setOtherSuggestion] = useState(
    orderStatusData?.feedback?.suggestions || ""
  );
  const [loading, setLoading] = useState(false);

  const onClose = () => {
    setOpen(false);
  };

  const customIcons = {
    1: <FrownOutlined />,
    2: <FrownOutlined />,
    3: <MehOutlined />,
    4: <SmileOutlined />,
    5: <SmileOutlined />,
  };

  const handleSubmit = async () => {
    const feedbackData = {
      rating:rating || selectedEmoji,
      suggestions: otherSuggestion,
    };


    setLoading(true);
    try {
      const res = await postRequest("/api/v1/pooja/updatePoojaOrder", {
        feedback: feedbackData,
        userId: orderStatusData?.user_id,
        id: orderStatusData?.id,
      });

      if (res && res?.data.success) {
        onClose();
        message.success("Thank you for your feedback!");
      } else {
        console.error("Error in submitting feedback:", res?.error);
        message.error("Failed to submit feedback. Please try again.");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error("An error occurred while submitting feedback.");
    }
  };

  return (
    <>
      {/* Background Blur */}
      {open && (
        <div
          className="fixed inset-0 bg-black bg-opacity-40 backdrop-blur-sm z-40"
          onClick={onClose}
        ></div>
      )}

      {/* Custom Drawer */}
      <div
        className={`fixed bottom-0 left-0 w-full bg-white rounded-t-3xl shadow-xl z-50 transition-transform duration-300 ${
          open ? "translate-y-0" : "translate-y-full"
        }`}
        style={{ height: "40vh" }}
      >
        {/* Header */}
        <div className="flex justify-between items-center px-6 py-4 border-b border-gray-200">
          <h2 className="text-lg font-bold text-orange-500">
            Your Feedback
          </h2>
          <CloseOutlined
            onClick={onClose}
            className="text-gray-400 cursor-pointer text-xl"
          />
        </div>

        {/* Content */}
        <div className="p-6 flex flex-col  h-full">
          {/* Rating Section */}
         {!rating && <div className="text-center">
            <p className="text-sm font-semibold text-gray-700 mb-4">
              How would you rate your experience?
            </p>
            <Rate
              className="text-4xl text-orange-500"
              character={({ index }) => customIcons[index + 1]}
              onChange={(value) => setSelectedEmoji(value)}
              value={selectedEmoji}
            />
          </div>}

          {/* Suggestions */}
          <div className="">
            <p className="text-sm font-semibold text-gray-700 mb-2">
              Share your suggestions to help us improve
            </p>
            <Input.TextArea
              rows={4}
              placeholder="Enter your feedback here..."
              value={otherSuggestion}
              onChange={(e) => setOtherSuggestion(e.target.value)}
              className="border border-gray-300 rounded-lg"
            />
          </div>

          {/* Submit Button */}
          <div className="text-center mt-8">
            <Button
              loading={loading}
              type="primary"
              className="text-white font-bold bg-orange-500 w-full h-12 rounded-lg shadow-md hover:bg-orange-600"
              onClick={handleSubmit}
            >
              Submit Feedback
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default FeedbackDrawer;