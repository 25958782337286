import React from "react";
import {
  PhoneFilled,
  CopyOutlined,
  MailOutlined,
  WhatsAppOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
import { message } from "antd";
import ShareWithFriends from "../../../components/ShareWithFriends";

const HelpAndSupport = ({ noCall,pujaOrderDetails }) => {
  const phoneNumber = "+919599224564";

  const handleCopyNumber = () => {
    navigator.clipboard.writeText(phoneNumber);
    message.success("Phone number copied to clipboard!");
  };

  return (
    <div id="help" className="bg-white w-full px-4 mb-4">
      <h2 className="text-lg font-semibold text-gray-800 mb-4">
        Help & Support for Booking
      </h2>

      {/* Action Buttons */}
      <div className="flex flex-wrap items-center justify-center space-x-4 w-full">
        {/* Email Us */}
        <a
          href={`mailto:devpunyahome@gmail.com`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="bg-gray-100 p-4 rounded-lg flex items-center justify-center flex-1 shadow-sm cursor-pointer hover:bg-gray-200 transition">
            <MailOutlined className="text-red-500 text-xl mr-2" />
            <span className="text-gray-800 font-medium text-sm">Email</span>
          </div>
        </a>

        {/* Whatsapp Us */}
        <a
          href={`https://wa.me/919079620532`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="bg-gray-100 p-4 rounded-lg flex items-center justify-center flex-1 shadow-sm cursor-pointer hover:bg-gray-200 transition">
            <WhatsAppOutlined className="text-green-500 text-xl mr-2" />
            <span className="text-gray-800 font-medium text-sm">Whatsapp</span>
          </div>
        </a>
        {!noCall && (
          <a
            href={`tel:919079620532`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="bg-gray-100 p-4 rounded-lg flex w-full items-center justify-center shadow-sm cursor-pointer hover:bg-gray-200 transition">
              <PhoneOutlined className="text-blue-500 text-xl mr-2" />
              <span className="text-gray-800 font-medium text-sm">Call</span>
            </div>
          </a>
        )}
     
      </div>
    </div>
  );
};

export default HelpAndSupport;
