import React from "react";
import { IoClose } from "react-icons/io5";
import { Button } from "antd";

const InfoDrawer = ({ visible, onClose }) => {
  if (!visible) return null;

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-40 z-50 flex justify-center items-end transition-all duration-300"
      onClick={onClose}
    >
      <div
        className="bg-white rounded-t-3xl p-6 w-full max-h-[50%] overflow-y-auto"
        onClick={(e) => e.stopPropagation()}
      >
        {/* Close Icon */}
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-semibold text-gray-800">
            I do not know my Lineage (Gotra), what should I do?
          </h3>
          <IoClose className="text-2xl text-gray-600 cursor-pointer" onClick={onClose} />
        </div>
        <p className="text-sm text-gray-600 mb-6">
          If you do not know your lineage (gotra), in this situation, you can consider
          your lineage as Kashyapa because Rishi Kashyapa is a sage whose descendants
          can be found in every caste. Therefore, he is considered a revered sage. The
          priest will chant these details during the worship.
        </p>
        <Button
          className="w-full bg-orange-500 text-white font-semibold h-12 rounded-lg"
          onClick={onClose}
        >
          Okay
        </Button>
      </div>
    </div>
  );
};

export default InfoDrawer;