import React from "react";
import { IoClose } from "react-icons/io5";

const WhatToDoDrawer = ({ isDrawerOpen,title, toggleDrawer, pujaDetails }) => {
  let guidelines =  pujaDetails?.guidelines?.puja_guidelines || pujaDetails?.packages?.guidelines?.puja_guidelines
   
  
  if(!guidelines) return(
    <></>
  )

  return (
    <>
      {/* Background Blur */}
      {isDrawerOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-30 backdrop-blur-sm z-50"
          onClick={toggleDrawer}
        ></div>
      )}

      {/* Custom Drawer */}
      <div
        className={`fixed bottom-0 left-0 w-full bg-white transition-transform duration-300 ${
          isDrawerOpen ? "translate-y-0" : "translate-y-full"
        } z-50 rounded-t-2xl`}
        style={{
          height: "90%",
          overflowY: "auto",
        }}
      >
        {/* Drawer Header */}
        <div
          className="bg-white px-4 py-3 border-b border-gray-200 rounded-t-2xl"
          style={{
            borderTopLeftRadius: "16px",
            borderTopRightRadius: "16px",
          }}
        >
          <div className="flex justify-between items-center">
            <h2 className="text-lg font-semibold text-gray-900">
              {title || "Puja Guidelines"}
            </h2>
            <button
              className="text-gray-600"
              onClick={toggleDrawer}
              aria-label="Close Drawer"
            >
              <IoClose className="h-6 w-6" />
            </button>
          </div>
        </div>

        {/* Drawer Body */}
        <div className="px-6 py-4 space-y-6">
          {/* Puja Image */}
          {pujaDetails.images && (
            <div className="text-center">
              <img
                src={pujaDetails.images[0]}
                alt="Puja Guide"
                className="rounded-lg mb-4 max-w-full h-auto"
              />
            </div>
          )}

          {/* Prayers */}
          <div>
            <h3 className="text-lg font-semibold text-gray-800 mb-2">Prayers</h3>
            <div className="text-sm text-gray-700 space-y-2">
              <p>
                <strong>Mantra (English):</strong> {guidelines.prayers.mantra.english}
              </p>
              <p>
                <strong>Mantra (Sanskrit):</strong> {guidelines.prayers.mantra.sanskrit}
              </p>
              <p>
                <strong>Chanting:</strong> {guidelines.prayers.chanting}
              </p>
              <p>
                <strong>Intention:</strong> {guidelines.prayers.intention}
              </p>
            </div>
          </div>

          {/* Guidelines */}
          <div>
            <h3 className="text-lg font-semibold text-gray-800 mb-2">Dos</h3>
            <ul className="list-disc pl-6 text-sm text-gray-700 space-y-2">
              {guidelines.dos.map((doItem, index) => (
                <li key={index}>{doItem}</li>
              ))}
            </ul>
          </div>

          <div>
            <h3 className="text-lg font-semibold text-gray-800 mb-2">Dont's</h3>
            <ul className="list-disc pl-6 text-sm text-gray-700 space-y-2">
              {guidelines.donts.map((dontItem, index) => (
                <li key={index}>{dontItem}</li>
              ))}
            </ul>
          </div>

          {/* Routine */}
          <div>
            <h3 className="text-lg font-semibold text-gray-800 mb-2">Routine</h3>
            <ul className="list-disc pl-6 text-sm text-gray-700 space-y-2">
              <li>
                <strong>Setup:</strong> {guidelines.routine.setup}
              </li>
              <li>
                <strong>Morning:</strong> {guidelines.routine.morning}
              </li>
              <li>
                <strong>Purification:</strong> {guidelines.routine.purification}
              </li>
            </ul>
          </div>

          {/* Eating Habits */}
          <div>
            <h3 className="text-lg font-semibold text-gray-800 mb-2">Eating Habits</h3>
            <ul className="list-disc pl-6 text-sm text-gray-700 space-y-2">
              <li>
                <strong>Avoid:</strong> {guidelines.eating_habits.avoid}
              </li>
              <li>
                <strong>Fasting:</strong> {guidelines.eating_habits.fasting}
              </li>
            </ul>
          </div>

          <p className="text-center text-orange-600 font-bold mt-6">🙏</p>
        </div>
      </div>
    </>
  );
};

export default WhatToDoDrawer;