import { useEffect, useState, useRef } from "react";
import { getRequest } from "../utils/api.utils";
import TrendingChadhaavaCard from "../pages/chdhava/components/TrendingChadhaavaCard";

const ChadhawaRecommends = ({ chadhaIds }) => {
  const [items, setItems] = useState([]);
  const scrollRef = useRef(null);

  useEffect(() => {
    const fetchChadhaavaDetails = async () => {
      const fetchedItems = [];

      for (const id of chadhaIds) {
        try {
          const res = await getRequest(`/noauth-api/v1/chadawa/getProductListing?product_id=${id}`);
          if (res && res.results && !res.results.deletedAt) {
            fetchedItems.push(res.results);
          }
        } catch (error) {
          console.error(`Error fetching Chadhaava ID ${id}:`, error);
        }
      }

      setItems(fetchedItems);
    };

    fetchChadhaavaDetails();
  }, [chadhaIds]);

  return (
    <div className="w-full relative mb-4">
      <div
        ref={scrollRef}
        className={`flex ${items.length === 1 ? "justify-center" : "overflow-x-auto scroll-smooth snap-x snap-mandatory space-x-2 scrollbar-hide"}`}
      >
        {items.map((item, index) => (
          <div
            key={index}
            className="flex-shrink-0 snap-start"
            style={{
              minWidth: items.length === 1 ? "100%" : "90%", 
              maxWidth: items.length === 1 ? "100%" : "90%",
            }}
          >
            <TrendingChadhaavaCard item={item} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ChadhawaRecommends;