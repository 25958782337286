import React, { useEffect, useState } from "react";
import Page from "../../layouts/Page";
import { getRequest } from "../../utils/api.utils";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { getCurrency } from "../../utils/helper.utils";
import CongratulationBannerChdhavaa from "./orderCards/CongratulationBannerChdhavaa";
import ChdhavaaDetails from "./orderCards/ChdhavaaDetails";
import ChadhavaParticipantDetails from "./orderCards/ChadhavaParticipantDetails";
import OfferingsChadhava from "./orderCards/OfferingsChadhava";
import ChadhavaUpdates from "./orderCards/ChadhavaUpdates";
import AuthBlur from "../auth/AuthBlur";
import DakshinaPaymentForChadhawa from "./components/DakshinaPaymentForChadhawa";
import { Typography } from "antd";
import ChadhawaRecommends from "../../components/ChadhawaRecommends";
import SectionHeader from "../../components/SectionHeader";

export default function ChadhaavaOrderDetails() {
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();

  const userJSON = localStorage.getItem("user");
  const user = userJSON ? JSON.parse(userJSON) : null;
  const [chadhaavaDetailData, setChadhaavaDetailData] = useState(null);

  const currency = getCurrency(
    order?.details?.chadawa_details?.currency_code || "INR"
  );

  const navigate = useNavigate();

  const getMyOrder = async () => {
    try {
      setLoading(true);
      const res = await getRequest(
        `/api/v1/chadawa/GetChadawaOrderByIdFromTable?order_id=${id}`
      );
      setOrder(res?.results);
    } catch (error) {
      console.error("Error fetching order:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getMyOrder();
  }, []);

  useEffect(() => {
    const fetchChadhaavaDetails = async (chadawa_id) => {
      const res = await getRequest(
        `/noauth-api/v1/chadawa/getProductListing?product_id=${chadawa_id}`
      );
      if (res && res.results && res.results?.id) {
        setChadhaavaDetailData(res.results);
      }
    };

    if (order?.id) {
      fetchChadhaavaDetails(order.chadawa_id);
    }
  }, [order]);

  if (loading) {
    return (
      <Page>
        <div className="flex justify-center items-center h-screen">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-gray-500"></div>
        </div>
      </Page>
    );
  }

  if (!order) {
    return (
      <Page>
        {!user && (
          <AuthBlur
            outSideClickHandler={() => navigate("/")}
            btnText="See Order Details"
            onSubmit={() => window.location.reload()}
          />
        )}

        <div className="text-center text-gray-500 mt-8">Order not found.</div>
      </Page>
    );
  }

  const isDakshina =
    order?.dakshina ||
    order?.details?.offerings?.find((item) =>
      item.name.toLowerCase()?.includes("dakshina")
    );

  let guidelines =
    order?.details?.chadawa_details?.packages?.guidelines?.puja_guidelines;

  let isPending = order.chadawa_status === "pending";
  let isVideoDelivered = order.video_url;

  return (
    <Page>
      <CongratulationBannerChdhavaa order={order} />
      {/* {!isDakshina && <DakshinaPaymentForChadhawa order={order} />} */}

      {guidelines?.prayers && isPending && (
        <div className="text-center px-4 bg-orange-50 rounded-lg p-2 pb-10">
          <Typography.Text className="text-sm block font-semibold ">
            📿 Mantra chanting on puja day 📿
          </Typography.Text>
          <div className="h-[1px] bg-orange-200 w-full my-2" />
          <Typography.Text className="text-xs block mb-2">
            {guidelines?.prayers?.mantra?.sanskrit}
          </Typography.Text>

          <Typography.Text className="text-xs block">
            {guidelines?.prayers?.mantra?.english}
          </Typography.Text>
        </div>
      )}

      {isVideoDelivered && <ChadhavaUpdates order={order} />}

      {chadhaavaDetailData && chadhaavaDetailData?.recommended_products && (
        <div className="px-4 ">
          <SectionHeader
            title="Upcoming Chadawas"
            onClick={() => {
              navigate("/chadhaava");
            }}
          />
          <ChadhawaRecommends
            chadhaIds={chadhaavaDetailData?.recommended_products}
          />
        </div>
      )}
      <ChdhavaaDetails order={order} />
      <ChadhavaParticipantDetails order={order} />

      {order.city && (
        <div className="px-4 mt-4">
          <div className="bg-white px-4 py-4 w-full border rounded-xl">
            <Typography.Text className="block text-sm font-semibold text-gray-800 ">
              Delivery Address
            </Typography.Text>

            <Typography.Text className="block">{order.city}</Typography.Text>
          </div>
        </div>
      )}
      {/* <OfferingsChadhava  order={order}/> */}
    </Page>
  );
}
