import { Typography } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { FaCalendarAlt, FaMapMarkerAlt } from "react-icons/fa";
import { getCurrency } from "../../../utils/helper.utils";

const ChdhavaaDetails = ({ order }) => {
  const [activePanels, setActivePanels] = useState({});

  const togglePanel = (panel) => {
    setActivePanels((prev) => ({
      ...prev,
      [panel]: !prev[panel], // Toggle the current panel
    }));
  };

  let packageDetails =[]
//   order?.packages["Packages"].find(
//     (item) => item.id == pujaOrderDetails.package_id
//   );

  

  if (!order) {
    return <></>;
  }
  return (
    <div className="bg-white rounded-tl-3xl rounded-tr-3xl p-4 ">
      {/* Header */}
      <div className="text-sm font-semibold text-gray-800 mb-4">
        Chadhawa Details
      </div>

      {/* First Collapsible Section */}
      <div
        className="cursor-pointer border border-gray-300 rounded-xl p-4 transition-shadow hover:shadow-md"
        onClick={() => togglePanel(1)}
      >
        {/* Collapsible Header */}
        <div className="flex justify-between items-center">
          <div className="w-[90%]">
            {order.details.chadawa_details?.tithi && (
              <div className="flex flex-col  w-full">
                <Typography.Text className="text-xs font-semibold text-[#D61F69]  line-clamp-2">
                  {order.details.chadawa_details.tithi}
                </Typography.Text>
                <div className="pt-[0.5px] w-[80%] my-2 bg-gradient-to-r from-transparent via-[#D61F69] to-transparent"></div>
              </div>
            )}

            <p
              className={`text-black font-semibold mb-1 text-sm  ${
                !activePanels[1] && "line-clamp-2"
              }`}
            >
              {order?.details.chadawa_details.name}
            </p>
            <p
              className={`font-medium text-xs ${
                !activePanels[1] && "line-clamp-1"
              }`}
            >
              {order?.details.chadawa_details?.benefits}
            </p>
        
          </div>
          {/* Arrow Icon */}
          <svg
            className={`w-6 h-6 text-gray-600 transition-transform ${
              activePanels[1] ? "rotate-180" : ""
            }`}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M19 9l-7 7-7-7"
            />
          </svg>
        </div>

        {/* Collapsible Content with Animation */}
        <div
          className={`overflow-hidden transition-all duration-300 ${
            activePanels[1] ? "" : "max-h-0"
          }`}
        >
          <Typography.Text className="text-sm font-medium my-2">
           {getCurrency(order?.details.chadawa_details.currency_code)} {order.total_order_amount}
          </Typography.Text>

          <div className="border-t pt-4 ">
            {/* Location */}
            <div className="flex items-center text-gray-600 text-xs mb-2">
              <FaMapMarkerAlt className="mr-2 text-orange-500" />
              {order?.details.chadawa_details.mandir_name}, {order?.details.chadawa_details.location}
            </div>

            {/* Date */}
            <div className="flex items-center text-gray-600 text-xs">
              <FaCalendarAlt className="mr-2 text-orange-500" />
              {moment(order.chadawa_starting_at).format("DD MMMM")}
            </div>
          </div>

          {/* {order?.details.chadawa_details.offerings?.length > 0 && (
            <div className="text-sm ">
              <div className="border border-dashed border-gray-300 mb-2 mt-3"></div>
              <span className="text-gray-500 font-semibold">
                Offerings selected
              </span>{" "}
              -{" "}
              {order?.details.chadawa_details.offerings.addons.map((item, index) => {
                return (
                  <Typography.Text key={index} className="text-sm">
                    {item.name}
                    {order?.details.chadawa_details.offerings?.addons.length - 1 !== index && ", "}
                  </Typography.Text>
                );
              })}
            </div>
          )} */}
        </div>
      </div>
    </div>
  );
};

export default ChdhavaaDetails;
