import { Typography } from 'antd'
import React from 'react'

export default function Title({label}) {
  return (
 <div className='flex flex-col'>
       <div className="w-full  mt-2">
        <Typography.Text className="font-bold text-base text-orange-500">
          {label}
        </Typography.Text>
      </div>
     <div className="pt-[0.5px] w-[40%] bg-gradient-to-l from-transparent via-[#D61F69] to-transparent"></div>

 </div>
  )
}
