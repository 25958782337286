import axios, { AxiosInstance, AxiosResponse } from "axios";

// Function to get the current token from localStorage
const getAuthToken = () => {
  const userJSON = localStorage.getItem("user");
  const user: any = userJSON ? JSON.parse(userJSON) : null;
  return user?.token || null;
};

const session_id = localStorage.getItem("session_id");

// Create a custom Axios instance with a default base URL
const apiClient: AxiosInstance = axios.create({
  baseURL:
    process.env.REACT_APP_API ||
    window.location.hostname.includes("dev.devpunya.com") ||
    window.location.hostname.includes("localhost")
      ?
      // "https://e377-2401-4900-1c68-58ee-cce9-8244-e472-a3ad.ngrok-free.app"
      // "http://localhost:4040"
      "https://devpunya-backend-3gtio2vspq-el.a.run.app"
      // "https://devpunya-backend-dev-721636515726.asia-south1.run.app"
      : "https://devpunya-backend-3gtio2vspq-el.a.run.app",
  headers: {
    "Content-Type": "application/json",
    session_id:session_id
  },
});

// Request Interceptor to dynamically add Authorization header
apiClient.interceptors.request.use(
  (config) => {
    const token = getAuthToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
// Define your HTTP request functions using the custom instance
export async function getRequest<T>(url: string, token?: string): Promise<T> {
  // Use the provided token or get it from localStorage
  const effectiveToken = token || getAuthToken();

  const headers = {
    "Content-Type": "application/json",
    ...(effectiveToken && { Authorization: `Bearer ${effectiveToken}` }),
  };

  const response: AxiosResponse<T> = await apiClient.get<T>(url, { headers });
  return response.data;
}

export function postRequest(url: string, data: any, token?: string) {
  const userJSON = localStorage.getItem("user");
  const user: any = userJSON ? JSON.parse(userJSON) : null;

  // Use the provided token or get it from localStorage
  const effectiveToken = token || (user?.token ? user.token : null);

  const headers = {
    "Content-Type": "application/json",
    ...(effectiveToken && { Authorization: `Bearer ${effectiveToken}` }),
  };

  return apiClient.post(url, data, { headers });
}

export function patchRequest(url: string, data: any) {
  return apiClient.patch(url, data);
}

export function putRequest(url: string, data: any) {
  return apiClient.put(url, data);
}

export function deleteRequest(url: string) {
  return apiClient.delete(url);
}
