import ReactGA from "react-ga4";

export const purchaseGA = (eventOpt:any) => {
  ReactGA.event("purchase",eventOpt);
};

export const ChadhawaPurchaseGA = (eventOpt:any) => {
  ReactGA.event("ChadhawaPurchase",eventOpt);
};


export const ChadhawaAddToCartGA = (eventOpt:any) => {
  ReactGA.event("ChadhawaAddToCart",eventOpt);
};



export const addToCartGA = (eventOpt:any) => {
  ReactGA.event("add_to_cart",eventOpt);
};

export const initiateCheckoutGA = (eventOpt:any) => {
  ReactGA.event("begin_checkout",eventOpt);
};

export const productPageOpenGa = () => {
  ReactGA.event({
    category: "page",
    action: "product_page_open",
    label: "",
  });
};

export const catPageOpenGa = (action: any) => {
  ReactGA.event({
    category: "page",
    action: "category_open",
    label: action,
  });
};

export const addToCartGa = () => {
  ReactGA.event({
    category: "button_click",
    action: "add_to_cart",
    label: "",
  });
};

export const buyNowGa = () => {
  ReactGA.event({
    category: "button_click",
    action: "buy_now",
    label: "",
  });
};

export const cartPlaceOrderGa = () => {
  ReactGA.event({
    category: "button_click",
    action: "cart_place_order",
    label: "",
  });
};

export const orderPaymentStartGa = () => {
  ReactGA.event({
    category: "button_click",
    action: "order_payment_start",
    label: "", // optional
  });
};

export const orderStatusPageGa = () => {
  ReactGA.event({
    category: "page",
    action: "order_status_page",
    label: "",
  });
};

export const selectPujaPlan = () => {
  ReactGA.event({
    category: "button_click",
    action: "select_puja_plan",
    label: "",
  });
};

export const confirmPujaPlan = () => {
  ReactGA.event({
    category: "button_click",
    action: "conirm_puja",
    label: "",
  });
};
