import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getRequest } from "../../../utils/api.utils";
import Loader from "../../../components/Loader";
import { FiPackage, FiShoppingBag } from "react-icons/fi";
import {
  AiOutlineArrowLeft,
  AiOutlineMail,
  AiOutlinePhone,
} from "react-icons/ai";
import { BsWhatsapp } from "react-icons/bs";
import Page from "../../../layouts/Page";
import HelpAndSupport from "../components/HelpAndSupport";

export default function OrderSuccessPage() {
  const { id } = useParams();
  const [order, setOrder] = useState(null);
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const fetchOrderDetails = async () => {
    const res = await getRequest(
      `/api/v1/abhimantrit_product/getOrders?order_id=${id}`
    );
    if (res.results && res.results.length > 0) {
      setOrder(res.results[0]);
      fetchProductById(res.results[0].product_id);
    }
    setLoading(false);
  };

  const fetchProductById = async (product_id) => {
    const res = await getRequest(
      `/api/v1/abhimantrit_product/getProductById?id=${product_id}`
    );
    if (res.results) {
      setProduct(res.results);
    }
  };

  useEffect(() => {
    fetchOrderDetails();
  }, []);

  if (loading) return <Loader />;

  return (
    <Page>
      <div className="min-h-screen bg-white px-4 py-6 flex flex-col items-center">
        {/* Header */}
        <div className="w-full max-w-3xl">
          <h2 className="text-2xl font-semibold text-gray-800">
            Order Summary
          </h2>
          <p className="text-gray-500 text-sm">
            Your order has been successfully placed.
          </p>
        </div>

        {/* Product Details Card */}
        {product && (
          <div className="w-full max-w-3xl bg-white border border-gray-300 rounded-lg mt-4 p-4">
            <div className="flex items-center">
              <FiShoppingBag className="text-blue-500 text-xl mr-2" />
              <h3 className="text-lg font-semibold text-gray-800">
                Product Details
              </h3>
            </div>
            <div className="mt-3 flex items-center gap-3">
              <img
                src={product?.images?.[0]}
                alt={product?.name}
                className="w-16 h-16 border border-gray-300 rounded-md"
              />
              <div>
                <h4 className="text-md font-medium text-gray-800">
                  {product?.name}
                </h4>
                <p className="text-sm text-gray-600">{product?.decoration}</p>
                <p className="text-sm font-semibold text-gray-800 mt-1">
                  ₹{product?.discounted_price || product?.price}
                </p>
              </div>
            </div>
          </div>
        )}

        {/* Order Details Card */}
        <div className="w-full max-w-3xl bg-white border border-gray-300 rounded-lg mt-4 p-4">
          <div className="flex items-center">
            <FiPackage className="text-blue-500 text-xl mr-2" />
            <h3 className="text-lg font-semibold text-gray-800">
              Order Details
            </h3>
          </div>
          <div className="mt-3 text-sm text-gray-600 space-y-2">
            <p>
              <strong>Order ID:</strong> {order?.id}
            </p>
            <p>
              <strong>Transaction ID:</strong> {order?.transaction_id || "-"}
            </p>
            <p>
              <strong>Amount Paid:</strong> ₹{order?.amount || "N/A"}
            </p>
          </div>
        </div>

        {/* Delivery Address Card */}
        {order?.address && (
          <div className="w-full max-w-3xl bg-white border border-gray-300 rounded-lg mt-4 p-4">
            <h3 className="text-lg font-semibold text-gray-800">
              Delivery Address
            </h3>
            <p className="text-sm text-gray-600 mt-1">{order.address.name}</p>
            <p className="text-sm text-gray-600">
              {order.address.flatNumber}, {order.address.locality}
            </p>
            <p className="text-sm text-gray-600">
              {/* {order.address.city}, {order.address.state},{" "} */}
              {order.address.zipCode}
            </p>
            <p className="text-sm text-gray-600">
              Phone: {order.address.phone}
            </p>
          </div>
        )}

        {/* Help & Support */}
        <div className="w-full max-w-3xl bg-white border border-gray-300 rounded-lg mt-4 p-4">
          <HelpAndSupport noCall />
        </div>
      </div>
    </Page>
  );
}
