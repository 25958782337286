import React, { useEffect, useRef } from "react";
import type { CollapseProps } from "antd";
import { Collapse, Typography } from "antd";
import Title from "./Title";

export default function FaqPuja({ data, label, fullData }: any) {
  const items: CollapseProps["items"] = data?.map((item: any, index: any) => {
    return {
      key: index + 1,
      label: (
        <Typography.Text className="font-bold text-sm">{item.title}</Typography.Text>
      ),
      children: <Typography.Text className="text-xs">{item.description}</Typography.Text>,
    };
  });

    const faqRef:any = useRef();
  
    useEffect(() => {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              // window.location.href = "#FAQ_tab";
            } else {
            }
          });
        },
        { threshold: 0.3 } // Trigger when 10% of the component is visible
      );
  
      if (faqRef.current) {
        observer.observe(faqRef.current);
      }
  
      return () => {
        if (faqRef.current) {
          observer.unobserve(faqRef.current);
        }
      };
    }, []);

  return (
    <div ref={faqRef}>
     {label &&  <Title label={label}/>
     
}
      <div className="my-4">
        <Collapse  accordion items={items} />
      </div>
    </div>
  );
}
