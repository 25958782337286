import axios from "axios";
import { siteConfig } from "../constants/Constants";
import { message } from "antd";

export function findColorName(array: any, idToFind: any) {
  const obj =
    array && array?.find((item: any) => Number(item.id) === Number(idToFind));
  return obj?.name || "";
}

export const loadScript = (src: any) => {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
};

export const sizes = ["s", "m", "l", "xl"];

export const qrPrices = [
  590, 690, 790, 890, 990, 1490, 1990, 1380, 1580, 1780, 1290,
];
// amount_590.jpg

export const createUrl = (product_name: string) => {
  return product_name ? product_name?.split(" ").join("-") : "";
};

export const getDiscountInPercentage = (product: any) => {
  let per = (product?.mrp - product?.discounted_price) / product?.mrp;
  return (per * 100).toFixed(0);
};

export function calculateGST(amount: number) {
  const gstPercentage = 18; // GST percentage
  const gstAmount = (amount * gstPercentage) / 100;
  return gstAmount;
}

export function generateDateArrayWithMonthAndDayNames() {
  const datesArray = [];
  const currentDate = new Date();
  const monthNamesLong = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const monthNamesShort = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const dayNamesShort = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const dayNamesLong = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  for (let i = 0; i < 6; i++) {
    const futureDate = new Date(currentDate);
    futureDate.setDate(currentDate.getDate() + i);

    let flag = "";
    if (i === 0) {
      flag = "Today";
    } else if (i === 1) {
      flag = "Tomorrow";
    }

    // Getting month and day names
    const monthIndex = futureDate.getMonth(); // getMonth() returns 0-11
    const monthLong = monthNamesLong[monthIndex];
    const monthShort = monthNamesShort[monthIndex];
    const dayShort = dayNamesShort[futureDate.getDay()]; // getDay() returns 0-6
    const dayLong = dayNamesLong[futureDate.getDay()]; // getDay() returns 0-6

    datesArray.push({
      date: futureDate.toISOString().split("T")[0], // Format date as YYYY-MM-DD
      monthLong: monthLong, // Long month name
      monthShort: monthShort, // Short month name
      dayShort: dayShort, // Short day name
      flag: flag,
      dayLong,
    });
  }

  return datesArray;
}

export function transformDate(customDate: any) {
  // Mapping month names to their numerical values
  const months: any = {
    January: 1,
    February: 2,
    March: 3,
    April: 4,
    May: 5,
    June: 6,
    July: 7,
    August: 8,
    September: 9,
    October: 10,
    November: 11,
    December: 12,
  };

  // Extracting the numerical month value
  const monthNumber = months[customDate?.month];

  // Constructing the new date string
  const newDateStr = `${customDate.year}-${String(monthNumber).padStart(
    2,
    "0"
  )}-${String(customDate.day).padStart(2, "0")}T00:00:00.000Z`;

  return newDateStr;
}

export function setDateTimeWithToday(hourMinutePeriod: any) {
  // Getting today's date
  const today = new Date();

  // Extracting hour, minute, and period from the input
  let { hour, minute, period } = hourMinutePeriod;

  // Converting 12-hour format to 24-hour format
  if (period === "PM" && hour !== "12") {
    hour = parseInt(hour, 10) + 12;
  } else if (period === "AM" && hour === "12") {
    hour = "00";
  }

  // Setting the hours and minutes
  today.setHours(hour, minute, 0, 0); // Sets hours, minutes, seconds, and milliseconds

  // Formatting the date as an ISO string and slicing to remove milliseconds and Z
  const formattedDate = today.toISOString().slice(0, -5) + "Z";

  return formattedDate;
}

export function formatDate(dateStr: any) {
  const date = new Date(dateStr);
  const day = date.getDate();
  const month = date.toLocaleString("default", { month: "long" });

  let daySuffix;
  if (day % 10 === 1 && day !== 11) {
    daySuffix = "st";
  } else if (day % 10 === 2 && day !== 12) {
    daySuffix = "nd";
  } else if (day % 10 === 3 && day !== 13) {
    daySuffix = "rd";
  } else {
    daySuffix = "th";
  }

  return `${day}${daySuffix} ${month}`;
}

export const getCurrency = (currency: any) => {
  
  if (!currency) {
    return "₹";
  }
  if (currency?.toLowerCase() === "inr") {
    return siteConfig.global.currency + " ";
  } else if (currency?.toLowerCase() === "usd") {
    return "$";
  }
  return currency + " ";
};

export const countryCodes = [
  { code: "91", country: "IN", currency: "INR", symbol: "₹" },
  { code: "1", country: "USA", currency: "USD", symbol: "USD" },
  // { code: "44", country: "USA", currency: "GBP", symbol: "£" },
  // { code: "65", country: "USA", currency: "SGD", symbol: "SGD" },
];

export function capitalizeFirstLetter(string: any) {
  if (!string) return "";
  return string?.charAt(0).toUpperCase() + string?.slice(1);
}

/**
 * Store UTM Parameters in localStorage with a 60-second uniqueness check for the same utm_source.
 */
interface UTMParams {
  utm_timestamp: string | Date;
  [key: string]: string | Date; // Allows dynamic UTM keys
}

export const storeUTMParams = (): void => {
  try {
    const queryParams = new URLSearchParams(window.location.search);
    const now = new Date();


    // Collect all parameters with "utm_" prefix
    const utmParams: UTMParams = { utm_timestamp: now.toISOString() };
    queryParams.forEach((value, key) => {
      if (key.startsWith("utm_")) {
        utmParams[key] = value;
      }
    });

    // If no UTM params exist in the URL, do nothing
    if (Object.keys(utmParams).length === 1) return;

    let utmParamsArr: UTMParams[] = [];
    const existingUTM = localStorage.getItem("utm");

    // Parse existing UTM from localStorage
    if (existingUTM) {
      try {
        const parsedUTM = JSON.parse(existingUTM);
        if (Array.isArray(parsedUTM)) {
          utmParamsArr = parsedUTM;
        }
      } catch (error) {
        console.error("Error parsing existing UTM parameters from localStorage:", error);
      }
    }

    // ✅ Check for duplicate utm_source within the last 30 seconds
    if (utmParams.utm_source) {
      const recentEntry = utmParamsArr.find((entry) => {
        const entryTime = new Date(entry.utm_timestamp).getTime();
        return entryTime > now.getTime() - 30 * 1000; // ✅ 30-second window check
      });

      if (recentEntry) {
        // ✅ If a duplicate UTM source was found in the last 30 sec, skip storing
        return;
      }
    }

    // ✅ Add the new UTM params to the array
    utmParamsArr.push(utmParams);

    // ✅ Store updated UTM array in localStorage
    localStorage.setItem("utm", JSON.stringify(utmParamsArr));
    localStorage.setItem("referrer_url",window.location.href);
  } catch (error) {
    console.error("Error storing UTM parameters:", error);
  }
};

/**
 * Retrieve UTM Parameters from localStorage.
 */
export const getUTMParams = (): UTMParams[] | null => {
  try {
    const storedUTM = localStorage.getItem("utm");
    if (storedUTM) {
      return JSON.parse(storedUTM) as UTMParams[];
    }
    return null;
  } catch (error) {
    console.error("Error parsing UTM parameters from localStorage:", error);
    return null;
  }
};

export const isDevMode = () => {
  if(window.location.href.includes("localhost:")){
    return true
  }
  return false;
};

const GOOGLE_API_KEY = "AIzaSyA3Y8AxVdw_325oxSL1QWTTA9Kpt5X2xU0"; // Khelgully GCP

/**
 * Helper function to fetch user's country using Google Geolocation and Geocoding APIs
 * @returns {Promise<string>} Country name or error message
 */

export const fetchUserCountry = async (): Promise<string | null> => {
  try {
    // Step 1: Get latitude and longitude based on IP
    const geoResponse = await axios.post(
      `https://www.googleapis.com/geolocation/v1/geolocate?key=${GOOGLE_API_KEY}`,
      { considerIp: true }
    );
    const { lat, lng } = geoResponse.data.location;

    // Step 2: Reverse geocode to get address components
    const reverseGeocodeResponse = await axios.get(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${GOOGLE_API_KEY}`
    );

    // Step 3: Extract country from the address components
    const results = reverseGeocodeResponse.data.results;

    localStorage.setItem("location_info", JSON.stringify(results));

    for (const result of results) {
      const countryComponent = result.address_components.find(
        (component: any) => component.types.includes("country")
      );

      if (countryComponent) {
        localStorage.setItem("user_location", JSON.stringify(countryComponent));

        return countryComponent; // Return the country name
      }
    }

    return "";
  } catch (error) {
    console.error("Error fetching country:", error);
    return "Error fetching country";
  }
};

export const countryOptions =
  // { code: "1", name: "USA", short_name: "US" },
  // { code: "44", name: "UK", short_name: "GB" },
  // { code: "61", name: "Australia", short_name: "AU" },
  // { code: "91", name: "India", short_name: "IN" },
  // { code: "33", name: "France", short_name: "FR" },
  // { code: "49", name: "Germany", short_name: "DE" },
  // { code: "971", name: "UAE", short_name: "AE" },
  // { code: "81", name: "Japan", short_name: "JP" },
  // { code: "55", name: "Brazil", short_name: "BR" },
  // { code: "7", name: "Russia", short_name: "RU" },
  // { code: "31", name: "Neitherland", short_name: "NL" }

  [
    {
      name: "Afghanistan",
      dial_code: "93",
      emoji: "🇦🇫",
      short_name: "AF",
    },
    {
      name: "Aland Islands",
      dial_code: "358",
      emoji: "🇦🇽",
      short_name: "AX",
    },
    {
      name: "Albania",
      dial_code: "355",
      emoji: "🇦🇱",
      short_name: "AL",
    },
    {
      name: "Algeria",
      dial_code: "213",
      emoji: "🇩🇿",
      short_name: "DZ",
    },
    {
      name: "AmericanSamoa",
      dial_code: "1684",
      emoji: "🇦🇸",
      short_name: "AS",
    },
    {
      name: "Andorra",
      dial_code: "376",
      emoji: "🇦🇩",
      short_name: "AD",
    },
    {
      name: "Angola",
      dial_code: "244",
      emoji: "🇦🇴",
      short_name: "AO",
    },
    {
      name: "Anguilla",
      dial_code: "1264",
      emoji: "🇦🇮",
      short_name: "AI",
    },
    {
      name: "Antarctica",
      dial_code: "672",
      emoji: "🇦🇶",
      short_name: "AQ",
    },
    {
      name: "Antigua and Barbuda",
      dial_code: "1268",
      emoji: "🇦🇬",
      short_name: "AG",
    },
    {
      name: "Argentina",
      dial_code: "54",
      emoji: "🇦🇷",
      short_name: "AR",
    },
    {
      name: "Armenia",
      dial_code: "374",
      emoji: "🇦🇲",
      short_name: "AM",
    },
    {
      name: "Aruba",
      dial_code: "297",
      emoji: "🇦🇼",
      short_name: "AW",
    },
    {
      name: "Australia",
      dial_code: "61",
      emoji: "🇦🇺",
      short_name: "AU",
    },
    {
      name: "Austria",
      dial_code: "43",
      emoji: "🇦🇹",
      short_name: "AT",
    },
    {
      name: "Azerbaijan",
      dial_code: "994",
      emoji: "🇦🇿",
      short_name: "AZ",
    },
    {
      name: "Bahamas",
      dial_code: "1242",
      emoji: "🇧🇸",
      short_name: "BS",
    },
    {
      name: "Bahrain",
      dial_code: "973",
      emoji: "🇧🇭",
      short_name: "BH",
    },
    {
      name: "Bangladesh",
      dial_code: "880",
      emoji: "🇧🇩",
      short_name: "BD",
    },
    {
      name: "Barbados",
      dial_code: "1246",
      emoji: "🇧🇧",
      short_name: "BB",
    },
    {
      name: "Belarus",
      dial_code: "375",
      emoji: "🇧🇾",
      short_name: "BY",
    },
    {
      name: "Belgium",
      dial_code: "32",
      emoji: "🇧🇪",
      short_name: "BE",
    },
    {
      name: "Belize",
      dial_code: "501",
      emoji: "🇧🇿",
      short_name: "BZ",
    },
    {
      name: "Benin",
      dial_code: "229",
      emoji: "🇧🇯",
      short_name: "BJ",
    },
    {
      name: "Bermuda",
      dial_code: "1441",
      emoji: "🇧🇲",
      short_name: "BM",
    },
    {
      name: "Bhutan",
      dial_code: "975",
      emoji: "🇧🇹",
      short_name: "BT",
    },
    {
      name: "Bolivia, Plurinational State of",
      dial_code: "591",
      emoji: "🇧🇴",
      short_name: "BO",
    },
    {
      name: "Bosnia and Herzegovina",
      dial_code: "387",
      emoji: "🇧🇦",
      short_name: "BA",
    },
    {
      name: "Botswana",
      dial_code: "267",
      emoji: "🇧🇼",
      short_name: "BW",
    },
    {
      name: "Brazil",
      dial_code: "55",
      emoji: "🇧🇷",
      short_name: "BR",
    },
    {
      name: "British Indian Ocean Territory",
      dial_code: "246",
      emoji: "🇮🇴",
      short_name: "IO",
    },
    {
      name: "Brunei Darussalam",
      dial_code: "673",
      emoji: "🇧🇳",
      short_name: "BN",
    },
    {
      name: "Bulgaria",
      dial_code: "359",
      emoji: "🇧🇬",
      short_name: "BG",
    },
    {
      name: "Burkina Faso",
      dial_code: "226",
      emoji: "🇧🇫",
      short_name: "BF",
    },
    {
      name: "Burundi",
      dial_code: "257",
      emoji: "🇧🇮",
      short_name: "BI",
    },
    {
      name: "Cambodia",
      dial_code: "855",
      emoji: "🇰🇭",
      short_name: "KH",
    },
    {
      name: "Cameroon",
      dial_code: "237",
      emoji: "🇨🇲",
      short_name: "CM",
    },
    {
      name: "Canada",
      dial_code: "1",
      emoji: "🇨🇦",
      short_name: "CA",
    },
    {
      name: "Cape Verde",
      dial_code: "238",
      emoji: "🇨🇻",
      short_name: "CV",
    },
    {
      name: "Cayman Islands",
      dial_code: "345",
      emoji: "🇰🇾",
      short_name: "KY",
    },
    {
      name: "Central African Republic",
      dial_code: "236",
      emoji: "🇨🇫",
      short_name: "CF",
    },
    {
      name: "Chad",
      dial_code: "235",
      emoji: "🇹🇩",
      short_name: "TD",
    },
    {
      name: "Chile",
      dial_code: "56",
      emoji: "🇨🇱",
      short_name: "CL",
    },
    {
      name: "China",
      dial_code: "86",
      emoji: "🇨🇳",
      short_name: "CN",
    },
    {
      name: "Christmas Island",
      dial_code: "61",
      emoji: "🇨🇽",
      short_name: "CX",
    },
    {
      name: "Cocos (Keeling) Islands",
      dial_code: "61",
      emoji: "🇨🇨",
      short_name: "CC",
    },
    {
      name: "Colombia",
      dial_code: "57",
      emoji: "🇨🇴",
      short_name: "CO",
    },
    {
      name: "Comoros",
      dial_code: "269",
      emoji: "🇰🇲",
      short_name: "KM",
    },
    {
      name: "Congo",
      dial_code: "242",
      emoji: "🇨🇬",
      short_name: "CG",
    },
    {
      name: "Congo, The Democratic Republic of the Congo",
      dial_code: "243",
      emoji: "🇨🇩",
      short_name: "CD",
    },
    {
      name: "Cook Islands",
      dial_code: "682",
      emoji: "🇨🇰",
      short_name: "CK",
    },
    {
      name: "Costa Rica",
      dial_code: "506",
      emoji: "🇨🇷",
      short_name: "CR",
    },
    {
      name: "Cote d'Ivoire",
      dial_code: "225",
      emoji: "🇨🇮",
      short_name: "CI",
    },
    {
      name: "Croatia",
      dial_code: "385",
      emoji: "🇭🇷",
      short_name: "HR",
    },
    {
      name: "Cuba",
      dial_code: "53",
      emoji: "🇨🇺",
      short_name: "CU",
    },
    {
      name: "Cyprus",
      dial_code: "357",
      emoji: "🇨🇾",
      short_name: "CY",
    },
    {
      name: "Czech Republic",
      dial_code: "420",
      emoji: "🇨🇿",
      short_name: "CZ",
    },
    {
      name: "Denmark",
      dial_code: "45",
      emoji: "🇩🇰",
      short_name: "DK",
    },
    {
      name: "Djibouti",
      dial_code: "253",
      emoji: "🇩🇯",
      short_name: "DJ",
    },
    {
      name: "Dominica",
      dial_code: "1767",
      emoji: "🇩🇲",
      short_name: "DM",
    },
    {
      name: "Dominican Republic",
      dial_code: "1849",
      emoji: "🇩🇴",
      short_name: "DO",
    },
    {
      name: "Ecuador",
      dial_code: "593",
      emoji: "🇪🇨",
      short_name: "EC",
    },
    {
      name: "Egypt",
      dial_code: "20",
      emoji: "🇪🇬",
      short_name: "EG",
    },
    {
      name: "El Salvador",
      dial_code: "503",
      emoji: "🇸🇻",
      short_name: "SV",
    },
    {
      name: "Equatorial Guinea",
      dial_code: "240",
      emoji: "🇬🇶",
      short_name: "GQ",
    },
    {
      name: "Eritrea",
      dial_code: "291",
      emoji: "🇪🇷",
      short_name: "ER",
    },
    {
      name: "Estonia",
      dial_code: "372",
      emoji: "🇪🇪",
      short_name: "EE",
    },
    {
      name: "Ethiopia",
      dial_code: "251",
      emoji: "🇪🇹",
      short_name: "ET",
    },
    {
      name: "Falkland Islands (Malvinas)",
      dial_code: "500",
      emoji: "🇫🇰",
      short_name: "FK",
    },
    {
      name: "Faroe Islands",
      dial_code: "298",
      emoji: "🇫🇴",
      short_name: "FO",
    },
    {
      name: "Fiji",
      dial_code: "679",
      emoji: "🇫🇯",
      short_name: "FJ",
    },
    {
      name: "Finland",
      dial_code: "358",
      emoji: "🇫🇮",
      short_name: "FI",
    },
    {
      name: "France",
      dial_code: "33",
      emoji: "🇫🇷",
      short_name: "FR",
    },
    {
      name: "French Guiana",
      dial_code: "594",
      emoji: "🇬🇫",
      short_name: "GF",
    },
    {
      name: "French Polynesia",
      dial_code: "689",
      emoji: "🇵🇫",
      short_name: "PF",
    },
    {
      name: "Gabon",
      dial_code: "241",
      emoji: "🇬🇦",
      short_name: "GA",
    },
    {
      name: "Gambia",
      dial_code: "220",
      emoji: "🇬🇲",
      short_name: "GM",
    },
    {
      name: "Georgia",
      dial_code: "995",
      emoji: "🇬🇪",
      short_name: "GE",
    },
    {
      name: "Germany",
      dial_code: "49",
      emoji: "🇩🇪",
      short_name: "DE",
    },
    {
      name: "Ghana",
      dial_code: "233",
      emoji: "🇬🇭",
      short_name: "GH",
    },
    {
      name: "Gibraltar",
      dial_code: "350",
      emoji: "🇬🇮",
      short_name: "GI",
    },
    {
      name: "Greece",
      dial_code: "30",
      emoji: "🇬🇷",
      short_name: "GR",
    },
    {
      name: "Greenland",
      dial_code: "299",
      emoji: "🇬🇱",
      short_name: "GL",
    },
    {
      name: "Grenada",
      dial_code: "1473",
      emoji: "🇬🇩",
      short_name: "GD",
    },
    {
      name: "Guadeloupe",
      dial_code: "590",
      emoji: "🇬🇵",
      short_name: "GP",
    },
    {
      name: "Guam",
      dial_code: "1671",
      emoji: "🇬🇺",
      short_name: "GU",
    },
    {
      name: "Guatemala",
      dial_code: "502",
      emoji: "🇬🇹",
      short_name: "GT",
    },
    {
      name: "Guernsey",
      dial_code: "44",
      emoji: "🇬🇬",
      short_name: "GG",
    },
    {
      name: "Guinea",
      dial_code: "224",
      emoji: "🇬🇳",
      short_name: "GN",
    },
    {
      name: "Guinea-Bissau",
      dial_code: "245",
      emoji: "🇬🇼",
      short_name: "GW",
    },
    {
      name: "Guyana",
      dial_code: "595",
      emoji: "🇬🇾",
      short_name: "GY",
    },
    {
      name: "Haiti",
      dial_code: "509",
      emoji: "🇭🇹",
      short_name: "HT",
    },
    {
      name: "Holy See (Vatican City State)",
      dial_code: "379",
      emoji: "🇻🇦",
      short_name: "VA",
    },
    {
      name: "Honduras",
      dial_code: "504",
      emoji: "🇭🇳",
      short_name: "HN",
    },
    {
      name: "Hong Kong",
      dial_code: "852",
      emoji: "🇭🇰",
      short_name: "HK",
    },
    {
      name: "Hungary",
      dial_code: "36",
      emoji: "🇭🇺",
      short_name: "HU",
    },
    {
      name: "Iceland",
      dial_code: "354",
      emoji: "🇮🇸",
      short_name: "IS",
    },
    {
      name: "India",
      dial_code: "91",
      emoji: "🇮🇳",
      short_name: "IN",
    },
    {
      name: "Indonesia",
      dial_code: "62",
      emoji: "🇮🇩",
      short_name: "ID",
    },
    {
      name: "Iran, Islamic Republic of Persian Gulf",
      dial_code: "98",
      emoji: "🇮🇷",
      short_name: "IR",
    },
    {
      name: "Iraq",
      dial_code: "964",
      emoji: "🇮🇷",
      short_name: "IQ",
    },
    {
      name: "Ireland",
      dial_code: "353",
      emoji: "🇮🇪",
      short_name: "IE",
    },
    {
      name: "Isle of Man",
      dial_code: "44",
      emoji: "🇮🇲",
      short_name: "IM",
    },
    {
      name: "Israel",
      dial_code: "972",
      emoji: "🇮🇱",
      short_name: "IL",
    },
    {
      name: "Italy",
      dial_code: "39",
      emoji: "🇮🇹",
      short_name: "IT",
    },
    {
      name: "Jamaica",
      dial_code: "1876",
      emoji: "🇯🇲",
      short_name: "JM",
    },
    {
      name: "Japan",
      dial_code: "81",
      emoji: "🇯🇵",
      short_name: "JP",
    },
    {
      name: "Jersey",
      dial_code: "44",
      emoji: "🇯🇪",
      short_name: "JE",
    },
    {
      name: "Jordan",
      dial_code: "962",
      emoji: "🇯🇴",
      short_name: "JO",
    },
    {
      name: "Kazakhstan",
      dial_code: "77",
      emoji: "🇰🇿",
      short_name: "KZ",
    },
    {
      name: "Kenya",
      dial_code: "254",
      emoji: "🇰🇪",
      short_name: "KE",
    },
    {
      name: "Kiribati",
      dial_code: "686",
      emoji: "🇰🇮",
      short_name: "KI",
    },
    {
      name: "Korea, Democratic People's Republic of Korea",
      dial_code: "850",
      emoji: "🇰🇵",
      short_name: "KP",
    },
    {
      name: "Korea, Republic of South Korea",
      dial_code: "82",
      emoji: "🇰🇷",
      short_name: "KR",
    },
    {
      name: "Kuwait",
      dial_code: "965",
      emoji: "🇰🇼",
      short_name: "KW",
    },
    {
      name: "Kyrgyzstan",
      dial_code: "996",
      emoji: "🇰🇬",
      short_name: "KG",
    },
    {
      name: "Laos",
      dial_code: "856",
      emoji: "🇱🇦",
      short_name: "LA",
    },
    {
      name: "Latvia",
      dial_code: "371",
      emoji: "🇱🇻",
      short_name: "LV",
    },
    {
      name: "Lebanon",
      dial_code: "961",
      emoji: "🇱🇧",
      short_name: "LB",
    },
    {
      name: "Lesotho",
      dial_code: "266",
      emoji: "🇱🇸",
      short_name: "LS",
    },
    {
      name: "Liberia",
      dial_code: "231",
      emoji: "🇱🇷",
      short_name: "LR",
    },
    {
      name: "Libyan Arab Jamahiriya",
      dial_code: "218",
      emoji: "🇱🇾",
      short_name: "LY",
    },
    {
      name: "Liechtenstein",
      dial_code: "423",
      emoji: "🇱🇮",
      short_name: "LI",
    },
    {
      name: "Lithuania",
      dial_code: "370",
      emoji: "🇱🇹",
      short_name: "LT",
    },
    {
      name: "Luxembourg",
      dial_code: "352",
      emoji: "🇱🇺",
      short_name: "LU",
    },
    {
      name: "Macao",
      dial_code: "853",
      emoji: "🇲🇴",
      short_name: "MO",
    },
    {
      name: "Macedonia",
      dial_code: "389",
      emoji: "🇲🇰",
      short_name: "MK",
    },
    {
      name: "Madagascar",
      dial_code: "261",
      emoji: "🇲🇬",
      short_name: "MG",
    },
    {
      name: "Malawi",
      dial_code: "265",
      emoji: "🇲🇼",
      short_name: "MW",
    },
    {
      name: "Malaysia",
      dial_code: "60",
      emoji: "🇲🇾",
      short_name: "MY",
    },
    {
      name: "Maldives",
      dial_code: "960",
      emoji: "🇲🇻",
      short_name: "MV",
    },
    {
      name: "Mali",
      dial_code: "223",
      emoji: "🇲🇱",
      short_name: "ML",
    },
    {
      name: "Malta",
      dial_code: "356",
      emoji: "🇲🇹",
      short_name: "MT",
    },
    {
      name: "Marshall Islands",
      dial_code: "692",
      emoji: "🇲🇭",
      short_name: "MH",
    },
    {
      name: "Martinique",
      dial_code: "596",
      emoji: "🇲🇶",
      short_name: "MQ",
    },
    {
      name: "Mauritania",
      dial_code: "222",
      emoji: "🇲🇷",
      short_name: "MR",
    },
    {
      name: "Mauritius",
      dial_code: "230",
      emoji: "🇲🇺",
      short_name: "MU",
    },
    {
      name: "Mayotte",
      dial_code: "262",
      emoji: "🇾🇹",
      short_name: "YT",
    },
    {
      name: "Mexico",
      dial_code: "52",
      emoji: "🇲🇽",
      short_name: "MX",
    },
    {
      name: "Micronesia, Federated States of Micronesia",
      dial_code: "691",
      emoji: "🇫🇲",
      short_name: "FM",
    },
    {
      name: "Moldova",
      dial_code: "373",
      emoji: "🇲🇩",
      short_name: "MD",
    },
    {
      name: "Monaco",
      dial_code: "377",
      emoji: "🇲🇨",
      short_name: "MC",
    },
    {
      name: "Mongolia",
      dial_code: "976",
      emoji: "🇲🇳",
      short_name: "MN",
    },
    {
      name: "Montenegro",
      dial_code: "382",
      emoji: "🇲🇪",
      short_name: "ME",
    },
    {
      name: "Montserrat",
      dial_code: "1664",
      emoji: "🇲🇸",
      short_name: "MS",
    },
    {
      name: "Morocco",
      dial_code: "212",
      emoji: "🇲🇦",
      short_name: "MA",
    },
    {
      name: "Mozambique",
      dial_code: "258",
      emoji: "🇲🇿",
      short_name: "MZ",
    },
    {
      name: "Myanmar",
      dial_code: "95",
      emoji: "🇲🇲",
      short_name: "MM",
    },
    {
      name: "Namibia",
      emoji: "🇳🇦",
      dial_code: "264",
      short_name: "NA",
    },
    {
      name: "Nauru",
      dial_code: "674",
      emoji: "🇳🇷",
      short_name: "NR",
    },
    {
      name: "Nepal",
      dial_code: "977",
      emoji: "🇳🇵",
      short_name: "NP",
    },
    {
      name: "Netherlands",
      dial_code: "31",
      emoji: "🇳🇱",
      short_name: "NL",
    },
    {
      name: "Netherlands Antilles",
      dial_code: "599",
      emoji: "🇧🇶",
      short_name: "AN",
    },
    {
      name: "New Caledonia",
      dial_code: "687",
      emoji: "🇳🇨",
      short_name: "NC",
    },
    {
      name: "New Zealand",
      dial_code: "64",
      emoji: "🇳🇿",
      short_name: "NZ",
    },
    {
      name: "Nicaragua",
      dial_code: "505",
      emoji: "🇳🇮",
      short_name: "NI",
    },
    {
      name: "Niger",
      dial_code: "227",
      emoji: "🇳🇪",
      short_name: "NE",
    },
    {
      name: "Nigeria",
      dial_code: "234",
      emoji: "🇳🇬",
      short_name: "NG",
    },
    {
      name: "Niue",
      dial_code: "683",
      emoji: "🇳🇺",
      short_name: "NU",
    },
    {
      name: "Norfolk Island",
      dial_code: "672",
      emoji: "🇳🇫",
      short_name: "NF",
    },
    {
      name: "Northern Mariana Islands",
      dial_code: "1670",
      emoji: "🇲🇵",
      short_name: "MP",
    },
    {
      name: "Norway",
      dial_code: "47",
      emoji: "🇳🇴",
      short_name: "NO",
    },
    {
      name: "Oman",
      dial_code: "968",
      emoji: "🇴🇲",
      short_name: "OM",
    },
    {
      name: "Pakistan",
      dial_code: "92",
      emoji: "🇵🇰",
      short_name: "PK",
    },
    {
      name: "Palau",
      dial_code: "680",
      emoji: "🇵🇼",
      short_name: "PW",
    },
    {
      name: "Palestinian Territory, Occupied",
      dial_code: "970",
      emoji: "🇵🇸",
      short_name: "PS",
    },
    {
      name: "Panama",
      dial_code: "507",
      emoji: "🇵🇦",
      short_name: "PA",
    },
    {
      name: "Papua New Guinea",
      dial_code: "675",
      emoji: "🇵🇬",
      short_name: "PG",
    },
    {
      name: "Paraguay",
      dial_code: "595",
      emoji: "🇵🇾",
      short_name: "PY",
    },
    {
      name: "Peru",
      dial_code: "51",
      emoji: "🇵🇪",
      short_name: "PE",
    },
    {
      name: "Philippines",
      dial_code: "63",
      emoji: "🇵🇭",
      short_name: "PH",
    },
    {
      name: "Pitcairn",
      dial_code: "872",
      emoji: "🇵🇳",
      short_name: "PN",
    },
    {
      name: "Poland",
      dial_code: "48",
      emoji: "🇵🇱",
      short_name: "PL",
    },
    {
      name: "Portugal",
      dial_code: "351",
      emoji: "🇵🇹",
      short_name: "PT",
    },
    {
      name: "Puerto Rico",
      dial_code: "1939",
      emoji: "🇵🇷",
      short_name: "PR",
    },
    {
      name: "Qatar",
      dial_code: "974",
      emoji: "🇶🇦",
      short_name: "QA",
    },
    {
      name: "Romania",
      dial_code: "40",
      emoji: "🇷🇴",
      short_name: "RO",
    },
    {
      name: "Russia",
      dial_code: "7",
      emoji: "🇷🇺",
      short_name: "RU",
    },
    {
      name: "Rwanda",
      dial_code: "250",
      emoji: "🇷🇼",
      short_name: "RW",
    },
    {
      name: "Reunion",
      dial_code: "262",
      emoji: "🇷🇪",
      short_name: "RE",
    },
    {
      name: "Saint Barthelemy",
      dial_code: "590",
      emoji: "🇧🇱",
      short_name: "BL",
    },
    {
      name: "Saint Helena, Ascension and Tristan Da Cunha",
      dial_code: "290",
      emoji: "🇸🇭",
      short_name: "SH",
    },
    {
      name: "Saint Kitts and Nevis",
      dial_code: "1869",
      emoji: "🇰🇳",
      short_name: "KN",
    },
    {
      name: "Saint Lucia",
      dial_code: "1758",
      emoji: "🇱🇨",
      short_name: "LC",
    },
    {
      name: "Saint Martin",
      dial_code: "590",
      emoji: "🇲🇫",
      short_name: "MF",
    },
    {
      name: "Saint Pierre and Miquelon",
      dial_code: "508",
      emoji: "🇵🇲",
      short_name: "PM",
    },
    {
      name: "Saint Vincent and the Grenadines",
      dial_code: "1784",
      emoji: "🇻🇨",
      short_name: "VC",
    },
    {
      name: "Samoa",
      dial_code: "685",
      emoji: "🇼🇸",
      short_name: "WS",
    },
    {
      name: "San Marino",
      dial_code: "378",
      emoji: "🇸🇲",
      short_name: "SM",
    },
    {
      name: "Sao Tome and Principe",
      dial_code: "239",
      emoji: "🇸🇹",
      short_name: "ST",
    },
    {
      name: "Saudi Arabia",
      dial_code: "966",
      emoji: "🇸🇦",
      short_name: "SA",
    },
    {
      name: "Senegal",
      dial_code: "221",
      emoji: "🇸🇳",
      short_name: "SN",
    },
    {
      name: "Serbia",
      dial_code: "381",
      emoji: "🇷🇸",
      short_name: "RS",
    },
    {
      name: "Seychelles",
      dial_code: "248",
      emoji: "🇸🇨",
      short_name: "SC",
    },
    {
      name: "Sierra Leone",
      dial_code: "232",
      emoji: "🇸🇱",
      short_name: "SL",
    },
    {
      name: "Singapore",
      dial_code: "65",
      emoji: "🇸🇬",
      short_name: "SG",
    },
    {
      name: "Slovakia",
      dial_code: "421",
      emoji: "🇸🇰",
      short_name: "SK",
    },
    {
      name: "Slovenia",
      dial_code: "386",
      emoji: "🇸🇮",
      short_name: "SI",
    },
    {
      name: "Solomon Islands",
      dial_code: "677",
      emoji: "🇸🇧",
      short_name: "SB",
    },
    {
      name: "Somalia",
      dial_code: "252",
      emoji: "🇸🇴",
      short_name: "SO",
    },
    {
      name: "South Africa",
      dial_code: "27",
      emoji: "🇿🇦",
      short_name: "ZA",
    },
    {
      name: "South Sudan",
      dial_code: "211",
      emoji: "🇸🇸",
      short_name: "SS",
    },
    {
      name: "South Georgia and the South Sandwich Islands",
      dial_code: "500",
      emoji: "🇬🇸",
      short_name: "GS",
    },
    {
      name: "Spain",
      dial_code: "34",
      emoji: "🇪🇸",
      short_name: "ES",
    },
    {
      name: "Sri Lanka",
      dial_code: "94",
      emoji: "🇱🇰",
      short_name: "LK",
    },
    {
      name: "Sudan",
      dial_code: "249",
      emoji: "🇸🇩",
      short_name: "SD",
    },
    {
      name: "Suriname",
      dial_code: "597",
      emoji: "🇸🇷",
      short_name: "SR",
    },
    {
      name: "Svalbard and Jan Mayen",
      dial_code: "47",
      emoji: "🇸🇯",
      short_name: "SJ",
    },
    {
      name: "Swaziland",
      dial_code: "268",
      emoji: "🇸🇿",
      short_name: "SZ",
    },
    {
      name: "Sweden",
      dial_code: "46",
      emoji: "🇸🇪",
      short_name: "SE",
    },
    {
      name: "Switzerland",
      dial_code: "41",
      emoji: "🇨🇭",
      short_name: "CH",
    },
    {
      name: "Syrian Arab Republic",
      dial_code: "963",
      emoji: "🇸🇾",
      short_name: "SY",
    },
    {
      name: "Taiwan",
      dial_code: "886",
      emoji: "🇹🇼",
      short_name: "TW",
    },
    {
      name: "Tajikistan",
      dial_code: "992",
      emoji: "🇹🇯",
      short_name: "TJ",
    },
    {
      name: "Tanzania, United Republic of Tanzania",
      dial_code: "255",
      emoji: "🇹🇿",
      short_name: "TZ",
    },
    {
      name: "Thailand",
      dial_code: "66",
      emoji: "🇹🇭",
      short_name: "TH",
    },
    {
      name: "Timor-Leste",
      dial_code: "670",
      emoji: "🇹🇱",
      short_name: "TL",
    },
    {
      name: "Togo",
      dial_code: "228",
      emoji: "🇹🇬",
      short_name: "TG",
    },
    {
      name: "Tokelau",
      dial_code: "690",
      emoji: "🇹🇰",
      short_name: "TK",
    },
    {
      name: "Tonga",
      dial_code: "676",
      emoji: "🇹🇴",
      short_name: "TO",
    },
    {
      name: "Trinidad and Tobago",
      dial_code: "1868",
      emoji: "🇹🇹",
      short_name: "TT",
    },
    {
      name: "Tunisia",
      dial_code: "216",
      emoji: "🇹🇳",
      short_name: "TN",
    },
    {
      name: "Turkey",
      dial_code: "90",
      emoji: "🇹🇷",
      short_name: "TR",
    },
    {
      name: "Turkmenistan",
      dial_code: "993",
      emoji: "🇹🇲",
      short_name: "TM",
    },
    {
      name: "Turks and Caicos Islands",
      dial_code: "1649",
      emoji: "🇹🇨",
      short_name: "TC",
    },
    {
      name: "Tuvalu",
      dial_code: "688",
      emoji: "🇹🇻",
      short_name: "TV",
    },
    {
      name: "Uganda",
      dial_code: "256",
      emoji: "🇺🇬",
      short_name: "UG",
    },
    {
      name: "Ukraine",
      dial_code: "380",
      emoji: "🇺🇦",
      short_name: "UA",
    },
    {
      name: "United Arab Emirates",
      dial_code: "971",
      emoji: "🇦🇪",
      short_name: "AE",
    },
    {
      name: "United Kingdom",
      dial_code: "44",
      emoji: "🇬🇧",
      short_name: "GB",
    },
    {
      name: "United States",
      dial_code: "1",
      emoji: "🇺🇸",
      short_name: "US",
    },
    {
      name: "Uruguay",
      dial_code: "598",
      emoji: "🇺🇾",
      short_name: "UY",
    },
    {
      name: "Uzbekistan",
      dial_code: "998",
      emoji: "🇺🇿",
      short_name: "UZ",
    },
    {
      name: "Vanuatu",
      dial_code: "678",
      emoji: "🇻🇺",
      short_name: "VU",
    },
    {
      name: "Venezuela, Bolivarian Republic of Venezuela",
      dial_code: "58",
      emoji: "🇻🇪",
      short_name: "VE",
    },
    {
      name: "Vietnam",
      dial_code: "84",
      emoji: "🇻🇳",
      short_name: "VN",
    },
    {
      name: "Virgin Islands, British",
      dial_code: "1284",
      emoji: "🇻🇬",
      short_name: "VG",
    },
    {
      name: "Virgin Islands, U.S.",
      dial_code: "1340",
      emoji: "🇻🇮",
      short_name: "VI",
    },
    {
      name: "Wallis and Futuna",
      dial_code: "681",
      emoji: "🇼🇫",
      short_name: "WF",
    },
    {
      name: "Yemen",
      dial_code: "967",
      emoji: "🇾🇪",
      short_name: "YE",
    },
    {
      name: "Zambia",
      dial_code: "260",
      emoji: "🇿🇲",
      short_name: "ZM",
    },
    {
      name: "Zimbabwe",
      dial_code: "263",
      emoji: "🇿🇼",
      short_name: "ZW",
    },
  ];

export const getCountryCode = () => {
  let user_location = localStorage.getItem("user_location")
    ? JSON.parse(localStorage.getItem("user_location") as string)
    : null;

  return user_location?.short_name?.toLowerCase() || null;
};



  // Phone number validation
  export const validatePhoneNumber = (phone: string) => {
    const trimmedPhone = phone.replace(/\D/g, "");
    if (trimmedPhone.length < 6) {
      message.error("Mobile number must be exactly 10 digits.");
      // setIsButtonEnable(false);
      return false;
    }
    if (trimmedPhone.startsWith("0")) {
      message.error("Mobile number cannot start with 0.");
      // setIsButtonEnable(false);
      return false;
    }
    // setIsButtonEnable(true);
    return true;
  };


  interface UTM {
    utm_timestamp: string;
    utm_source: string;
    utm_medium: string;
    utm_campaign: string;
    utm_content: string;
}

export const getUtm = (): UTM[] | null => {
    const utm = localStorage.getItem("utm");
    if (!utm) return null;

    try {
        const utmArray: UTM[] = JSON.parse(utm);

        if (!Array.isArray(utmArray) || utmArray.length === 0) return null;

        // ✅ Sort the array by `utm_timestamp` in ascending order (earliest first)
        utmArray.sort((a, b) => new Date(a.utm_timestamp).getTime() - new Date(b.utm_timestamp).getTime());

        return utmArray;
    } catch (error) {
        console.error("Error parsing UTM:", error);
        return null;
    }
};



  function getFBPCookie() {
    const cookies = document.cookie.split(";");
    const fbpCookie = cookies.find((cookie) =>
      cookie.trim().startsWith("_fbp=")
    );
    return fbpCookie ? fbpCookie.split("=")[1] : null;
  }

  function getFBCCookie() {
    const cookies = document.cookie.split(";");
    const fbpCookie = cookies.find((cookie) =>
      cookie.trim().startsWith("_fbc=")
    );
    return fbpCookie ? fbpCookie.split("=")[1] : null;
  }

  export const fbpValue = getFBPCookie();
  export const fbcValue = getFBCCookie();