// import { Collapse, CollapseProps, Typography } from "antd";
// import React from "react";
// import TitleDesc from "./TitleDesc";

// export default function BenefitsOfPooja({ data, label }: any) {
//   const items: CollapseProps["items"] = data?.map((item: any, index: any) => {
//     return {
//       key: index + 1,
//       label: (
//         <Typography.Text className="font-bold text-sm text-orange-500">
//           {item.title}
//         </Typography.Text>
//       ),
//       children: (
//         <Typography.Text className="text-xs">
//           {item.description}
//         </Typography.Text>
//       ),
//     };
//   });

//   return (
//     <div>
//       <div className="border border-gray-300 w-full px-2 py-1 border-dashed">
//         <Typography.Text className="font-bold text-base text-orange-500">
//           {label}
//         </Typography.Text>
//       </div>
//       {/* {data.map((item: any) => {
//         return <TitleDesc title={item.title} desc={item.description} />;
//       })} */}

//       <div className="my-4">

//         <Collapse defaultActiveKey={[1, 2, 3, 4]} items={items} />
//       </div>
//     </div>
//   );
// }
// import { Collapse, CollapseProps, Typography } from "antd";
// import React from "react";
// import TitleDesc from "./TitleDesc";

// export default function BenefitsOfPooja({ data, label }: any) {
//   const items: CollapseProps["items"] = data?.map((item: any, index: any) => {
//     return {
//       key: index + 1,
//       label: (
//         <div className="flex items-center">
//           <Typography.Text className="font-bold text-sm text-orange-500">
//             {index + 1}. {item.title}
//           </Typography.Text>
//         </div>
//       ),
//       children: (
//         <Typography.Text className="text-xs">
//           {item.description}
//         </Typography.Text>
//       ),
//       showArrow: false,  // This will remove the arrow
//     };
//   });

//   return (
//     <div>
//       <div className="border border-gray-300 w-full px-2 py-1 border-dashed">
//         <Typography.Text className="font-bold text-base text-orange-500">
//           {label}
//         </Typography.Text>
//       </div>

//       <div className="my-4">
//         <Collapse defaultActiveKey={[1, 2, 3, 4, 5]} items={items} />
//       </div>
//     </div>
//   );
// }

// import { Collapse, Typography } from "antd";
// import { PlusOutlined, CloseOutlined } from "@ant-design/icons";
// import React, { useState } from "react";

// export default function BenefitsOfPooja({ data, label }: any) {
//   const [activeKey, setActiveKey] = useState<string | null>(null);

//   const onToggle = (key: string) => {
//     setActiveKey(activeKey === key ? null : key);
//   };

//   return (
//     <div>
//       {/* Label for the section */}
//       <div className="border border-gray-300 w-full px-2 py-1 border-dashed">
//         <Typography.Text className="font-bold text-base text-orange-500">
//           {label}
//         </Typography.Text>
//       </div>

//       {/* Collapse Component */}
//       <div className="my-4">
//         {data.map((item: any, index: number) => {
//           const key = (index + 1).toString();
//           const isActive = activeKey === key;

//           return (
//             <div
//               key={key}
//               className={`p-3 mb-2 border ${isActive ? "bg-orange-200" : "bg-orange-100"}`}
//             >
//               {/* Header with Number, Title and Toggle Button */}
//               <div className="flex justify-between items-center">
//                 <Typography.Text className="font-bold text-sm text-orange-500">
//                   {`0${index + 1}`}
//                 </Typography.Text>
//                 <Typography.Text className="ml-2 font-bold text-orange-500">
//                   {item.title}
//                 </Typography.Text>
//                 <div
//                   className="cursor-pointer text-orange-500"
//                   onClick={() => onToggle(key)}
//                 >
//                   {isActive ? <CloseOutlined /> : <PlusOutlined />}
//                 </div>
//               </div>

//               {/* Description section */}
//               {isActive && (
//                 <div className="mt-2">
//                   <Typography.Text className="text-xs">
//                     {item.description}
//                   </Typography.Text>
//                 </div>
//               )}
//             </div>
//           );
//         })}
//       </div>
//     </div>
//   );
// }

// import { Typography } from "antd";
// import { PlusOutlined, CloseOutlined } from "@ant-design/icons";
// import React, { useState } from "react";

// export default function BenefitsOfPooja({ data, label }: any) {
//   const [activeKey, setActiveKey] = useState<string | null>(null);

//   const onToggle = (key: string) => {
//     setActiveKey(activeKey === key ? null : key);
//   };

//   return (
//     <div>
//       {/* Label for the section */}
//       <div className="border border-gray-300 w-full px-2 py-1 border-dashed">
//         <Typography.Text className="font-bold text-base text-orange-500">
//           {label}
//         </Typography.Text>
//       </div>

//       {/* Collapse Component */}
//       <div className="my-4">
//         {data.map((item: any, index: number) => {
//           const key = (index + 1).toString();
//           const isActive = activeKey === key;

//           return (
//             <div
//               key={key}
//               className={`p-3 border-b-[1px] border-gray-100 ${isActive ? "bg-[#FFEDDC]" : "bg-[#FFF9F4]"}`}
//             >
//               {/* Header with Number, Title and Toggle Button */}
//               <div className="flex justify-between items-start">
//                 <div className="flex flex-col">
//                   <Typography.Text className="font-bold text-xl text-orange-500">
//                     {`0${index + 1}`}
//                   </Typography.Text>
//                   <Typography.Text className="font-bold text-lg text-black">
//                     {item.title}
//                   </Typography.Text>
//                 </div>
//                 <div
//                   className="cursor-pointer text-orange-500 ml-auto"
//                   onClick={() => onToggle(key)}
//                 >
//                   {isActive ? <CloseOutlined /> : <PlusOutlined />}
//                 </div>
//               </div>

//               {/* Description section */}
//               {isActive && (
//                 <div className="mt-2">
//                   <Typography.Text className="text-sm text-gray-500">
//                     {item.description}
//                   </Typography.Text>
//                 </div>
//               )}
//             </div>
//           );
//         })}
//       </div>
//     </div>
//   );
// }

// import { Typography } from "antd";
// import { PlusOutlined, CloseOutlined } from "@ant-design/icons";
// import React, { useState } from "react";

// export default function BenefitsOfPooja({ data, label }: any) {
//   const [activeKey, setActiveKey] = useState<string | null>(null);

//   const onToggle = (key: string) => {
//     setActiveKey(activeKey === key ? null : key);
//   };

//   return (
//     <div>
//       {/* Label for the section */}
//       <div className="border border-gray-300 w-full px-2 py-1 border-dashed">
//         <Typography.Text className="font-bold text-base text-orange-500">
//           {label}
//         </Typography.Text>
//       </div>

//       {/* Collapse Component */}
//       <div className="my-4">
//         {data.map((item: any, index: number) => {
//           const key = (index + 1).toString();
//           const isActive = activeKey === key;

//           return (
//             <div
//               key={key}
//               className={`p-3 border-b-[1px] border-gray-100 ${isActive ? "bg-[#FFEDDC]" : "bg-[#FFF9F4]"}`}
//             >
//               {/* Header with Number, Title and Toggle Button */}
//               <div className="flex justify-between items-start">
//                 <div className="flex flex-col">
//                   <Typography.Text className="font-bold text-xl text-orange-500">
//                     {`0${index + 1}`}
//                   </Typography.Text>
//                   <Typography.Text className="font-bold text-lg text-black">
//                     {item.title}
//                   </Typography.Text>
//                 </div>
//                 <div
//                   className="cursor-pointer text-orange-500 bg-white rounded-full flex items-center justify-center"
//                   style={{ width: '24px', height: '24px' }}
//                   onClick={() => onToggle(key)}
//                 >
//                   {isActive ? <CloseOutlined /> : <PlusOutlined />}
//                 </div>
//               </div>

//               {/* Description section */}
//               {isActive && (
//                 <div className="mt-2">
//                   <Typography.Text className="text-sm text-gray-500">
//                     {item.description}
//                   </Typography.Text>
//                 </div>
//               )}
//             </div>
//           );
//         })}
//       </div>
//     </div>
//   );
// }
import { Typography } from "antd";
import { PlusOutlined, CloseOutlined } from "@ant-design/icons";
import React, { useEffect, useRef, useState } from "react";
import Title from "./Title";

export default function BenefitsOfPooja({ data, label }: any) {
  const [activeKey, setActiveKey] = useState<string | null>(null);

  const onToggle = (key: string) => {
    setActiveKey(activeKey === key ? null : key);
  };

  const benefits: any = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            window.location.href = "#Benefits_tab";
          }
        });
      },
      { threshold: 0.3 } // Trigger when 10% of the component is visible
    );

    if (benefits.current) {
      observer.observe(benefits.current);
    }

    return () => {
      if (benefits.current) {
        observer.unobserve(benefits.current);
      }
    };
  }, []);

  return (
    <div ref={benefits}>
      {/* Label for the section */}
      <Title label={label} />
      {/* Collapse Component */}
      <div className="my-4">
        {data.map((item: any, index: number) => {
          const key = (index + 1).toString();
          const isActive = activeKey === key;

          return (
            <div
              key={key}
              onClick={() => onToggle(key)}
              className={`p-3 border-b-[1px] border-gray-100 ${
                isActive ? "bg-[#FFEDDC]" : "bg-[#FFF9F4]"
              }`}
            >
              {/* Number and Title with + or x on the same line */}
              <div className="flex justify-between w-full items-center">
                <div className="w-full">
                  <Typography.Text className="font-bold text-2xl text-orange-500">
                    {`0${index + 1}`}
                  </Typography.Text>
                  <div className="flex items-center w-full justify-between">
                    <Typography.Text className="font-bold text-xl text-black block w-[92%]">
                      {item.title}
                    </Typography.Text>
                    <button
                      className={`cursor-pointer shadow-sm  ${
                        isActive
                          ? "bg-orange-400 text-white"
                          : "bg-white text-orange-500"
                      } rounded-full block outline-none border-none w-8 h-8`}
                      onClick={() => onToggle(key)}
                    >
                      {isActive ? <CloseOutlined /> : <PlusOutlined />}
                    </button>
                  </div>
                </div>
              </div>

              {/* Description section with Tailwind animation */}
              <div
                className={`transition-all duration-500 ease-in-out overflow-hidden ${
                  isActive ? "max-h-[500px] opacity-100" : "max-h-0 opacity-0"
                }`}
              >
                <div className="mt-2">
                  <Typography.Text className="text-sm text-gray-500">
                    {item.description}
                  </Typography.Text>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
