import React, { useState } from "react";
import { Button, Drawer, Typography, theme } from "antd";
import PoojaSlider from "./PoojaSlider";
import { useNavigate, useSearchParams } from "react-router-dom";
import { createUrl } from "../../../utils/helper.utils";
import { siteConfig } from "../../../constants/Constants";
import { selectPujaPlan } from "../../../events/gaEvent";
import {
  pujaParticipantPageInterKartEvent,
  pujaBokingStartPageInterKartEvent,
} from "../../../events/interkartEvents";
import Crown from "../../../assets/images/crown.png";
import ParticipateCard from "./ParticipateCard";
import { leadPX } from "../../../events/facebookPexelsEvent";
import { EditOutlined } from "@ant-design/icons";
import { IoArrowForward } from "react-icons/io5";

export default function OpenListPooja({ isEdit, title, data, fullData,navigateTo }: any) {
  const { token } = theme.useToken();
  const [open, setOpen] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const package_id = searchParams.get("package_id");
  const showDrawer = () => {
    // pujaParticipantPageInterKartEvent(fullData);
    leadPX();
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const navigate = useNavigate();
  const poojaView = (item: any) => {
    selectPujaPlan();
    onClose();
    if(navigateTo){
      navigateTo(item)
      window.location.reload()
      return
    }

    // pujaBokingStartPageInterKartEvent({...fullData,package:item});
    navigate(
      `/pooja/payment-confirmation?slug=${createUrl(fullData.name)}&pooja_id=${
        fullData.id
      }&package_id=${item.id}&price=${item.price}&lang=`,
      { state: { ...item } }
    );

    if (isEdit) {
      navigate(0);
      // window.location.reload();
    }
  };

  return (
    <div>
      {!isEdit ? (
        <div className="w-full   shadow px-4 py-2 bg-white">
          <button
            onClick={() => {
              window.location.href = "#Packages";
            }}
            className="bg-green-500 font-semibold w-full py-2 rounded-lg h-full text-white text-xl flex items-center justify-center space-x-2 relative group"
          >
            <span>{title}</span>
            <IoArrowForward className="text-white text-xl " />
          </button>
        </div>
      ) : (
        <p
          onClick={() => {
            showDrawer();
          }}
        >
          <EditOutlined />
        </p>
      )}

      <Drawer
        title="Choose Puja Package"
        placement="bottom"
        closable
        onClose={onClose}
        open={open}
        bodyStyle={{ padding: "8px" }}
        className="rounded-t-2xl"
        height={550}
      >
        {data?.map((item: any, index: any) => (
          <div
            onClick={() => poojaView(item)}
            className={`relative w-full border-b mb-3 py-2 border-dashed border-gray-400  ${
              item.id == package_id ? "bg-green-100 border-none" : "bg-white"
            }`}
            key={index}
          >
            <ParticipateCard fullData={fullData} item={item} />
          </div>
        ))}
      </Drawer>
    </div>
  );
}
