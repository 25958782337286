import { useState, useEffect, useRef } from "react";
import { Typography } from "antd";
import { useNavigate } from "react-router-dom";
import { createUrl, getCurrency } from "../../../utils/helper.utils";

import img_family_puja_extended_unselected from "../../../assets/images/packagesImages/img_family_puja_extended_unselected.webp";
import img_family_puja_extended_selected from "../../../assets/images/packagesImages/img_family_puja_extended_selected.webp";

import img_family_puja_selected from "../../../assets/images/packagesImages/img_family_puja_selected.webp";
import img_family_puja_unselected from "../../../assets/images/packagesImages/img_family_puja_unselected.webp";

import img_individual_puja_selected from "../../../assets/images/packagesImages/img_individual_puja_selected.webp";
import img_individual_puja_unselected from "../../../assets/images/packagesImages/img_individual_puja_unselected.webp";

import img_partner_puja_selected from "../../../assets/images/packagesImages/img_partner_puja_selected.webp";
import img_partner_puja_unselected from "../../../assets/images/packagesImages/img_partner_puja_unselected.webp";
import Title from "./Title";

const array = [
  {
    selected: img_individual_puja_selected,
    default: img_individual_puja_unselected,
    selectedColour: "orange",
    id: 1,
    title: "Single",
  },
  {
    selected: img_partner_puja_selected,
    default: img_partner_puja_unselected,
    selectedColour: "blue",
    id: 2,
    title: "Couple",
  },
  {
    selected: img_family_puja_selected,
    default: img_family_puja_unselected,
    selectedColour: "red",
    id: 3,
    title: "Family",
  },
  {
    selected: img_family_puja_extended_selected,
    default: img_family_puja_extended_unselected,
    selectedColour: "indigo",
    id: 4,
    title: "Joint Family",
  },
];

export default function Packages({
  data,
  detailsNotShow,
  label,
  fullData,
  filteredPackageData,
}: any) {
  const navigate = useNavigate();
  const packageRef = useRef<HTMLDivElement>(null);

  // ✅ Sort data by id and set the default package
  const sortedData = [...data].sort((a: any, b: any) => a.id - b.id);

  const getDefaultPackage = () => {
    const preferredOrder = [1, 2, 3]; // Check in this order

    for (const id of preferredOrder) {
      const found = sortedData.find((item) => item.id === id);
      if (found) return found;
    }
    return sortedData[0]; // If none match, pick the first one
  };

  const [selectedPackage, setSelectedPackage] = useState<any>(
    filteredPackageData || getDefaultPackage()
  );

  const poojaView = (item: any) => {
  
    navigate(
      `/pooja/payment-confirmation?slug=${createUrl(fullData.name)}&pooja_id=${
        fullData.id
      }&package_id=${item.id}&price=${item.price}&lang=`,
      { state: { ...item } }
    );
  };

  const handleSelectedItems = (item: any) => {
    if (detailsNotShow) {
      navigate(
        `/pooja/book-now-pay-later/${item.price}/${item.id}/${fullData.id}`
      );
      window.location.reload()
      return;
    }
    setSelectedPackage(item);
    const element = document.getElementById(item.id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "center" });
    }
    window.history.replaceState(null, "", `#${item.id}`);
  };

  const styleMap: any = {
    1: { color: "#ffa500", gradient: "linear-gradient(#fffaf0, #ffffff)" },
    2: { color: "#0000ff", gradient: "linear-gradient(#ecf1fe, #ffffff)" },
    3: { color: "#ff0000", gradient: "linear-gradient(#ffe6e6, #ffffff)" },
    4: { color: "#8A2BE2", gradient: "linear-gradient(#f3e5f5, #ffffff)" },
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            window.location.href = "#__";
          }
        });
      },
      { threshold: 0.2 }
    );

    if (packageRef.current) {
      observer.observe(packageRef.current);
    }

    return () => {
      if (packageRef.current) {
        observer.unobserve(packageRef.current);
      }
    };
  }, []);

  const styles = styleMap[selectedPackage?.id] || styleMap[1];
  let strData = selectedPackage?.description?.split(".");

  return (
    <div className="mb-4" ref={packageRef}>
      <Title label={label} />
      <div
        className={`flex items-center justify-center ${
          data.length < 4 ? "gap-4" : "justify-between"
        }`}
      >
        {sortedData.map((item: any, index: any) => {
          const fltredData: any = array.find(
            (fltItem: any) => fltItem.id === item.id
          );
          return (
            <div
              key={index}
              className={`m-1 relative ${
                data.length === 2 ? "w-[45%]" : "w-auto"
              }`}
              onClick={() => handleSelectedItems(item)}
            >
              <Typography.Text
                className="text-center text-xs line-clamp-1 w-full block whitespace-nowrap overflow-hidden text-ellipsis"
                style={{
                  color: fltredData.selectedColour,
                }}
              >
                {item.name}
              </Typography.Text>
              <div className="relative">
                <img
                  className="relative"
                  src={
                    selectedPackage?.id === item.id
                      ? fltredData?.selected
                      : fltredData?.default
                  }
                  alt={fltredData.title}
                />
                <span
                  className="absolute w-full font-bold text-center block"
                  style={{
                    color:
                      selectedPackage?.id === item.id
                        ? "white"
                        : fltredData.selectedColour,
                    fontSize: data.length > 2 ? "12px" : "18px",
                    bottom: selectedPackage?.id === item.id ? "12%" : "7%",
                    left: 0,
                  }}
                >
                  {getCurrency(fullData?.currency_code)}
                  {item.price}
                </span>
              </div>
            </div>
          );
        })}
      </div>

      {!detailsNotShow && (
        <div>
          <div
            onClick={() => poojaView(selectedPackage)}
            className="outline rounded-lg p-4"
            style={{
              background: styles.gradient,
              outlineColor: styles.color,
            }}
          >
            <div className="w-full text-xl flex justify-center items-center mb-1">
              <h1 style={{ color: styles.color }} className="text-xl font-bold">
                {selectedPackage?.name}
              </h1>
            </div>

            {strData?.map((item: any, idx: number) => {
              if (!item) return null;
              return (
                <div
                  key={idx}
                  className="flex w-full justify-between items-center mt-1"
                >
                  <div
                    style={{
                      width: 15,
                      height: 15,
                      borderRadius: "50%",
                      border: "1px solid orange",
                      marginRight: 10,
                    }}
                  />
                  <p className="w-[95%] text-sm">{item}</p>
                </div>
              );
            })}

            <button
              onClick={() => poojaView(selectedPackage)}
              className="w-full rounded-lg mt-4 bg-green-500 text-white font-bold h-[50px] text-xl shadow"
            >
              PARTICIPATE NOW
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
