import React, { useState, useEffect } from "react";
import { Input, Checkbox, Divider, message } from "antd";
import { IoInformationCircleOutline } from "react-icons/io5";
import { getCountryCode, countryOptions, getUTMParams, validatePhoneNumber } from "../../../utils/helper.utils";
import PhoneInput from "../../auth/PhoneInput";
import { useNavigate } from "react-router-dom";
import { postRequest } from "../../../utils/api.utils";

const PujaFormComponent = ({
  totalMembers,
  user,
  onFormChange,
  isAddress,
  isAutoFillGotra,
}) => {
  const cCode = getCountryCode();
  let country_code = countryOptions.find(
    (item) => item?.short_name?.toLowerCase() === cCode
  )?.dial_code;

  const [formData, setFormData] = useState(
    Array.from({ length: totalMembers }, () => ({ name: "", gotra: "" }))
  );
  const navigate = useNavigate()
  const [gotra, setGotra] = useState("");
  const [dontKnowGotra, setDontKnowGotra] = useState(false);
  const [whatsAppNumber, setWhatsAppNumber] = useState(
    user?.userDetails?.phone || ""
  );
  const [countryCode, setCountryCode] = useState(country_code || "91");
  const [wish, setWish] = useState("");
  const [city, setCity] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  let location_info = localStorage.getItem("location_info")
    ? JSON.parse(localStorage.getItem("location_info"))
    : null;

  let isIndia = cCode === "in";



  useEffect(() => {
    if (isAutoFillGotra) {
      setDontKnowGotra(true);
      setGotra("Kashyap");
    }
  }, [isAutoFillGotra]);

  useEffect(() => {
    const savedDetails = localStorage.getItem("sankalpDetails");
    if (savedDetails) {
      const { formData: savedFormData, gotra: savedGotra } =
        JSON.parse(savedDetails);

      const adjustedFormData = Array.from(
        { length: totalMembers },
        (_, index) =>
          savedFormData && savedFormData[index]
            ? savedFormData[index]
            : { name: "", gotra: "" }
      );

      setFormData(adjustedFormData);
      setGotra(savedGotra || "");
      setDontKnowGotra(savedGotra === "Kashyap");
    } else {
      setFormData(
        Array.from({ length: totalMembers }, () => ({ name: "", gotra: "" }))
      );
    }
  }, [totalMembers]);

  useEffect(() => {
    onFormChange({
      formData,
      gotra,
      dontKnowGotra,
      whatsAppNumber,
      countryCode,
      wish,
      city,
      whatsAppNumber,
      email
    });
  }, [formData, gotra, dontKnowGotra, whatsAppNumber, countryCode, wish, city]);

  const handleInputChange = (index, value) => {
    const updatedFormData = [...formData];
    updatedFormData[index].name = value;
    setFormData(updatedFormData);
  };

  let isIndiaExist = cCode === "in";

  return (
    <div className="px-2 ">
   {!user &&   <div className="mb-2 bg-white rounded-lg  p-4 border" id="gotra">
        <h3 className="text-sm font-semibold text-gray-800 mb-2">
          Provide Contact Details
        </h3>

        {/* WhatsApp Number Field */}
        <PhoneInput
          // disabled={OTPComponent}
          setWhatsAppNumber={setWhatsAppNumber}
          countryCode={countryCode}
          setCountryCode={setCountryCode}
          whatsAppNumber={whatsAppNumber}
          noTitle
        />
        <span className="text-xs text-gray-500">
          We will share Puja updates, timings & Puja video on your WhatsApp &
          email
        </span>

        {/* <label>
          <span className="input-label mt-4">Email</span>
          <div className="input-wrapper rounded-lg">
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className={`input-field `}
              placeholder="Enter Your Email"
            />
          </div>
        </label> */}
      </div>
}
      <div className="mb-2 bg-white rounded-lg  p-4 border" id="gotra">
        <div className="flex justify-between items-center">
          <h3 className="text-sm font-semibold text-gray-800 ">
            Gotra of Puja members (भक्त का गोत्र)
          </h3>
          {/* <IoInformationCircleOutline className="text-xl text-orange-500 cursor-pointer" /> */}
        </div>
        <p className="text-xs text-gray-500 mb-4">
          If you don’t know your gotra, write <strong>Kashyap</strong> according
          to Vedic norms.
        </p>
        <Input
          placeholder="Gotra"
          value={gotra}
          onChange={(e) => setGotra(e.target.value)}
          className={`mb-2 ${
            !isAutoFillGotra
              ? "border border-gray-300"
              : "border-2 border-green-600"
          } rounded-lg text-base p-2`}
        />
        <Checkbox
          checked={dontKnowGotra}
          onChange={(e) => {
            setDontKnowGotra(e.target.checked);
            if (e.target.checked) {
              setGotra("Kashyap");
            } else {
              setGotra("");
            }
          }}
        >
          I do not know my gotra
        </Checkbox>
      </div>

      <div className="mb-2 bg-white rounded-lg border p-4">
        <h3 className="text-sm font-semibold text-gray-800">
          Name of members participating in Puja
        </h3>
        <p className="text-xs text-gray-500 mb-4">
          Panditji will take these names along with gotra during the puja.
        </p>
        {formData.map((member, index) => (
          <Input
            key={index}
            placeholder={`Member ${index + 1} name`}
            value={member.name}
            onChange={(e) => handleInputChange(index, e.target.value)}
            className="mb-2 border border-gray-300 rounded-lg p-2 text-base"
          />
        ))}
      </div>
      <div className="mb-8 bg-white rounded-lg border p-4">
        <p className="text-sm font-semibold text-gray-800">
          Your Wish (मनोकामना 🙏){" "}
          <span className="text-gray-400 font-light">(Optional)</span>
        </p>
        <p className="text-xs text-gray-500 mb-4">
          Not mandatory, but we submit your wishes to Mandir.
        </p>
        <Input.TextArea
          placeholder="Devpunya team submits your wishes to temple and pray for you."
          value={wish}
          rows={4}
          onChange={(e) => setWish(e.target.value)}
          className="mb-4 border border-gray-300 rounded-lg p-2"
        />
      </div>

      {isAddress && (
        <div className="mb-8 bg-white rounded-lg border p-4">
          <h3 className="text-sm font-semibold text-gray-800">
            Delivery Address
          </h3>
          <p className="text-xs text-gray-500 mb-4">
            Enter your delivery address if required.
          </p>
          <Input.TextArea
            placeholder="Flat No, Building Name, Locality, City, State, Pincode."
            value={city}
            rows={4}
            onChange={(e) => setCity(e.target.value)}
            className="mb-4 border border-gray-300 rounded-lg text-base p-2"
          />
        </div>
      )}
    </div>
  );
};

export default PujaFormComponent;
