import React from "react";
import { ShareAltOutlined } from "@ant-design/icons";

export default function ShareWithFriends({ shareUrl, message = "", onShare }) {
  const handleShare = async () => {
    try {
      if (navigator.share) {
        await navigator.share({
          title: "",
          text: message || "Join me in this sacred Puja!",
          url: shareUrl,
        });
        onShare?.(); // tracking
      } else {
        await navigator.clipboard.writeText(shareUrl);
        message.success("🔗 Link copied to clipboard!");
        onShare?.();
      }
    } catch (error) {
      if (error.name === "AbortError") {
        console.log("User cancelled share.");
        return;
      }
      console.error("Sharing failed", error);
      message.warning("❌ Something went wrong while sharing.");
    }
  };

  return (
    <div className="mt-4 flex items-center justify-center w-full">
      <button
        onClick={handleShare}
        className="flex items-center justify-center gap-2 px-6 py-3 bg-orange-500 hover:bg-orange-700 text-white rounded-lg text-base font-medium shadow-md transition-all duration-200"
      >
        <ShareAltOutlined className="text-lg" />
        Share with Friends & Family
      </button>
    </div>
  );
}