import React, { useState } from "react";
import { siteConfig } from "../../../constants/Constants";
import Crown from "../../../assets/images/crown.png";
import { Button, Drawer, Typography, theme } from "antd";
import more from "../../../assets/images/more.png";
import less from "../../../assets/images/less.png";
import { getCurrency } from "../../../utils/helper.utils";

export default function ParticipateCard({ item,fullData }) {
  const [MAX_LIMIT_DESC, setMAX_LIMIT_DESC] = useState(50);
  const [readMore, setreadMore] = useState(true);

  return (
    <div>
      {/* <span className="absolute top-1 left-1 bg-green-500 text-white text-xs font-bold px-3 py-1 rounded-xl rounded-br-lg">
    {siteConfig.global.currency} {item.price}
  </span> */}

      {/* {item.id === 4 && (
        <span className="absolute top-[-5px] right-[-8px]  text-white text-xs font-bold px-3 py-1 rounded-xl rounded-br-lg">
          <img className="h-[50px] w-[50px] mb-2" src={Crown} alt="Crown" />
        </span>
      )} */}

      <div className="rounded-xl">
        {/* <div className="flex flex-col  w-full">
          <Typography.Text className="font-semibold text-lg">
            {item.name?.toUpperCase()}
          </Typography.Text>
        </div> */}

        <div className="flex items-center justify-between w-full">
          {item?.image && (
            <img
              className="h-[80px] w-[80px] mb-2 rounded-2xl"
              src={item.image}
              alt={item.name}
            />
          )}
          <div className=" ml-2 w-[55%]">
            <Typography.Text className="font-semibold text-base ">
              {item.name?.toUpperCase()}
            </Typography.Text>
            <div className="flex items-center">
              <Typography.Text type="secondary">
                {item.description?.slice(0, MAX_LIMIT_DESC)}
                {readMore ? "..." : " "}

                <img
                  onClick={(e) => {
                    e.stopPropagation();
                    setMAX_LIMIT_DESC(!readMore ? 50 : 1000);
                    setreadMore(!readMore);
                  }}
                  src={readMore ? more : less}
                  alt="more"
                  className="w-[15px] h-[15px] cursor-pointer inline"
                />
              </Typography.Text>
            </div>
          </div>

          <Typography.Text className="bg-green-500 text-white text-xs font-bold px-3 py-1 rounded-xl rounded-br-lg">
            {getCurrency(fullData.currency_code)} {item.price}
          </Typography.Text>
        </div>

        {/* <div className="flex items-center justify-center">
            <Button
              className=" text-white bg-green-500 font-bold"
              style={{
                border: "1px solid black",
              }}
            >
              Participate (भाग लें)
            </Button>
        </div> *
        /}

        {/* <Button
              className="w-full bg-orange-500 text-white font-bold"
              style={{
                border: "1px solid black",
              }}
            >
              Participate (भाग लें)
            </Button> 
        */}
      </div>
    </div>
  );
}
