import React from "react";
import { Modal, Button } from "antd";

const ConfirmationModal = ({
  visible,
  title,
  description,
  subDescription,
  primaryButtonText,
  secondaryButtonText,
  onPrimaryAction,
  onSecondaryAction,
}) => {
  if (!visible) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-40 flex justify-center items-center z-50">
      <div
        className="bg-white rounded-xl shadow-lg p-6 w-[90%] max-w-md"
        onClick={(e) => e.stopPropagation()}
      >
        <h3 className="text-lg font-semibold text-center text-gray-800">
          {title}
        </h3>
        <p className="text-sm text-center text-gray-600 mt-3">
          {description}
        </p>
        {subDescription && (
          <p className="text-xs text-center text-gray-500 mt-2">
            {subDescription}
          </p>
        )}
        <div className="mt-6 flex justify-between">
          <Button
            className="text-red-500 bg-transparent border-none hover:bg-gray-100 hover:text-blue-600"
            onClick={onPrimaryAction}
          >
            {primaryButtonText}
          </Button>
          <Button
            className="bg-green-600 text-white hover:bg-red-600 rounded-lg"
            onClick={onSecondaryAction}
          >
            {secondaryButtonText}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;