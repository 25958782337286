export const BNPL_STORAGE_KEY = "bnplData";
export const BNPL_EXPIRY_TIME = 60000 * 10; // 60 seconds

// Store BNPL Data in Local Storage (Only if Not Already Exists)
export const storeBnplData = (bnplInfo: any, callback: (data: any) => void) => {
  const existingBnpl = localStorage.getItem(BNPL_STORAGE_KEY);
  if (existingBnpl) return; // If already exists, don't store a new one

  const bnplWithTimestamp = {
    ...bnplInfo,
    timestamp: Date.now(), // Current Time in ms
  };

  localStorage.setItem(BNPL_STORAGE_KEY, JSON.stringify(bnplWithTimestamp));
  callback(bnplWithTimestamp); // Update State in Component

  // Auto Remove BNPL Data After 60 Seconds
  setTimeout(() => {
    removeBnplData(callback);
  }, BNPL_EXPIRY_TIME);
};

// Check BNPL Expiration on Page Load
export const checkBnplExpiration = (callback: (data: any) => void) => {
  const storedBnpl = localStorage.getItem(BNPL_STORAGE_KEY);
  if (storedBnpl) {
    const parsedBnpl = JSON.parse(storedBnpl);
    const timeElapsed = Date.now() - parsedBnpl.timestamp;

    if (timeElapsed > BNPL_EXPIRY_TIME) {
      removeBnplData(callback);
    } else {
      callback(parsedBnpl);
      // Set Timeout to Remove It at Correct Time
      setTimeout(() => {
        removeBnplData(callback);
      }, BNPL_EXPIRY_TIME - timeElapsed);
    }
  }
};

// Remove BNPL Data
export const removeBnplData = (callback: (data: any) => void) => {
  localStorage.removeItem(BNPL_STORAGE_KEY);
  callback(null); // Clear BNPL Data in State
};

// ✅ New Function: Check if BNPL Exists and is Active (Returns true/false)
export const isBnplActive = (): boolean => {
  const storedBnpl = localStorage.getItem(BNPL_STORAGE_KEY);
  if (!storedBnpl) return false; // No BNPL Data Found

  const parsedBnpl = JSON.parse(storedBnpl);
  const timeElapsed = Date.now() - parsedBnpl.timestamp;

  return timeElapsed < BNPL_EXPIRY_TIME; // Return True if BNPL is Active
};