import React from 'react';

const TextWithInlineImage = ({ text }:any) => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  const parts = text.split(urlRegex);

  return (
    <div>
      {parts.map((part:any, index:any) => 
        part.match(urlRegex) ? <img key={index} src={part} alt="Embedded" /> : <span key={index}>{part}</span>
      )}
    </div>
  );
};

export default TextWithInlineImage;
