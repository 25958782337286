import Route from "./routes";
import { useUser } from "./hooks/user.hook";
import { Provider } from "react-redux";
import store from "./redux/store";
import { v4 as uuidv4 } from "uuid";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Spin } from "antd";
import axios from "axios";
import vd from "./assets/open_loading.gif";
import { getRequest } from "./utils/api.utils";
import { viewContentPx } from "./events/facebookPexelsEvent";
import { fetchUserCountry, storeUTMParams } from "./utils/helper.utils";
import { checkBnplExpiration } from "./utils/bnpl.utils";

const App = () => {
  const user = useUser();
  const location = useLocation();
  const [bnplData, setBnplData] = useState(true);
  const [loading, setLoading] = useState(true);
  const [userLocationExists, setUserLocationExists] = useState(true);

  function getTokenFromUrl(url: string) {
    const urlParams = new URLSearchParams(url.split("?")[1]);
    return urlParams.get("token");
  }

  function getPlatformFromUrl(url: string) {
    const urlParams = new URLSearchParams(url.split("?")[1]);
    return urlParams.get("platform");
  }

  useEffect(() => {
    storeUTMParams();
      // Check BNPL Expiration
      checkBnplExpiration(setBnplData);
  }, [location]);

  const initializeApp = async () => {
    try {
      await fetchUserCountry();
      setUserLocationExists(false);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching country:", error);
      setLoading(false);
      setUserLocationExists(false);
      localStorage.setItem(
        "user_location",
        JSON.stringify({
          long_name: "India",
          short_name: "IN",
          types: ["country", "political"],
        })
      );
    }
  };

  useEffect(() => {
    initializeApp();
    setUserLocationExists(true);
    setLoading(false);
  }, []);

  useEffect(() => {
    let token: any = getTokenFromUrl(location?.search);
    let platform: any = getPlatformFromUrl(location?.search);
    const user: any = localStorage.getItem("user");
    if (!user && platform) {
      const fetchUserDetails = async () => {
        try {
          const res: any = await getRequest(`/api/v1/login/getUser`, token);
          localStorage.setItem("platform", platform);
          localStorage.setItem(
            "user",
            JSON.stringify({
              token,
              userDetails: res.results,
            })
          );
        } catch (error) {
          console.error("Error fetching user details:", error);
        }
      };
      fetchUserDetails();
    }
  }, []);

  useEffect(() => {
    const setNewSession = (): void => {
      const retainedData = localStorage.getItem("utm");
      if (retainedData) {
        localStorage.setItem("utm", retainedData);
      }
      const newSessionId: string = uuidv4()?.replace("-", "");
      const newSessionStart: string = new Date().toISOString();
      localStorage.setItem("session_id", JSON.stringify(newSessionId));
      localStorage.setItem("session_start", newSessionStart);

        // Get the title of the page
        const pageTitle = document.title || "No Title";

      viewContentPx({content_name:pageTitle});
    };

    const sessionStartString: string | null =
      localStorage.getItem("session_start");
    let sessionStart: Date | null = null;

    if (sessionStartString) {
      try {
        sessionStart = new Date(sessionStartString);
      } catch (error) {
        console.error("Error parsing session start time:", error);
        setNewSession();
        return;
      }
    }

    if (!sessionStart) {
      setNewSession();
    }
  }, [location]);

  useEffect(() => {
    const sessionStartString: string | null = localStorage.getItem(
      "cart_disabled_session_start"
    );
    let sessionStart: Date | null = null;

    if (sessionStartString) {
      try {
        sessionStart = new Date(sessionStartString);
      } catch (error) {
        console.error("Error parsing session start time:", error);
        return;
      }
    }

    if (sessionStart) {
      const currentTime: Date = new Date();
      const timeDiff: number = currentTime.getTime() - sessionStart.getTime();
      const ninetyDaysInMillis = 90 * 24 * 60 * 60 * 1000;

      if (timeDiff > ninetyDaysInMillis) {
        localStorage.removeItem("cart_disabled");
        localStorage.removeItem("cart_disabled_session_start");
      }
    }
  }, [location]);

  // Prevent rendering anything until user_location exists
  if (userLocationExists) {
    return (
      <div className="flex justify-center items-center h-screen">
        <img src={vd} alt="loading..." />
      </div>
    ); // Nothing will be rendered until location is available
  }

  return user ? (
    <div className="flex justify-center w-full relative ">
      <div
        className={`lg:max-w-lg ${
          location.pathname?.includes("kundali-report") ? "" : "w-full"
        }`}
      >
        <Provider store={store}>
          <Route />
        </Provider>
      </div>
    </div>
  ) : (
    <div className="flex justify-center items-center h-screen">
      <img src={vd} alt="loading..." />
    </div>
  );
};

export default App;
