import { Typography } from "antd";
import React, { useEffect, useRef } from "react";
import TextWithInlineImage from "../../../components/TextWithInlineImage";
import Title from "./Title";

export default function AboutPuja({ data, label, fullData }: any) {
  const impRef: any = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            window.location.href = "#imp_tab";
          } else {
          }
        });
      },
      { threshold: 0.3 } // Trigger when 10% of the component is visible
    );

    if (impRef.current) {
      observer.observe(impRef.current);
    }

    return () => {
      if (impRef.current) {
        observer.unobserve(impRef.current);
      }
    };
  }, []);

  return (
    <div className="px-4" ref={impRef}>
     <Title label={label}/>

      {data.map((item: any) => {
        return (
          <div key={item.title} className="mb-4">
            <Typography.Text className="font-bold text-lg my-1 block">
              🚩 {item.title}
            </Typography.Text>
            <TextWithInlineImage text={item.description} />
          </div>
        );
      })}
    </div>
  );
}
