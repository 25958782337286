// Extend the global Window object to declare Clarity
declare global {
    interface Window {
        clarity: (type: string, eventName: string, eventData?:any) => void;
    }
}
// Track Clarity Custom Events
export const addToCartCLARITY = (eventData?:any): void => {
    if (window.clarity) {
        window.clarity("event", "add_to_cart", eventData);
    } else {
        console.warn("Clarity not initialized yet");
    }
};

export const purchaseCLARITY = (eventData?:any): void => {
    if (window.clarity) {
        window.clarity("event", "purchase", eventData);
    } else {
        console.warn("Clarity not initialized yet");
    }
};

export const initiateCheckoutCLARITY = (eventData?:any): void => {
    if (window.clarity) {
        window.clarity("event", "begin_checkout", eventData);
    } else {
        console.warn("Clarity not initialized yet");
    }
};