import React from "react";
import { motion } from "framer-motion";
import { FaMapMarkerAlt, FaCalendarAlt, FaOm } from "react-icons/fa";
import { RiLeafLine } from "react-icons/ri";
import { Button, Typography } from "antd";
import { useNavigate } from "react-router-dom";

function TrendingChadhaavaCard({ item }: any) {
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-IN", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });
  };

  const navigate = useNavigate();

  const naviagetToChdhava = () => {
    navigate(`/chadhaava/${item.id}`);
  };
  return (
    <div
      onClick={naviagetToChdhava}
      className=" bg-white border  w-full rounded-lg border-gray-300 p-[1px]"
    >
      <div className="relative">
        <img
          src={item.default_image}
          alt={item.name}
          className="w-full h-40 object-cover rounded-t-lg"
        />

        {item.decoration_tag && (
          <div className="absolute top-1 right-1">
            <span className="bg-gradient-to-r from-orange-600 to-orange-400 text-white px-3 py-1 rounded-full text-sm font-medium flex items-center gap-1.5">
              {/* <RiLeafLine className="text-sm" /> */}
              {item.decoration_tag}
            </span>
          </div>
        )}
      </div>

      {/* Content */}
      <div className="py-1 px-2">
        {/* Title and Om Symbol */}
        <div className="flex flex-col items-center gap-2">
          {item.tithi && (
            <div className="flex flex-col w-full justify-center items-center ">
              <Typography.Text className="text-sm font-semibold text-[#D61F69]  line-clamp-2">
                {item.tithi}
              </Typography.Text>
              <div className="pt-[0.5px] w-full my-1  bg-gradient-to-r from-transparent via-[#D61F69] to-transparent"></div>
            </div>
          )}

          <h3 className="text-sm text-start w-full font-bold text-gray-900 leading-tight line-clamp-1">
            {item.name}
          </h3>
        </div>

        {/* Benefits */}
        {item.benefits && (
          <p className="text-xs text-orange-600 line-clamp-2">{item.benefits}</p>
        )}

        {/* Location */}
        <div className="flex items-center text-gray-600 mb-3">
          <FaMapMarkerAlt className="w-3 h-3 text-orange-500 mr-1.5" />
          <span className="text-xs line-clamp-1">
            {item.mandir_name}, {item.location}
          </span>
        </div>

        {/* Date and CTA */}
        <div className="flex items-center justify-between">
          <div className="flex items-center  px-2.5 py-1.5 rounded-md">
            <FaCalendarAlt className="w-3 h-3 text-orange-600 mr-1.5" />
            <span className="text-xs text-gray-500 font-medium line-clamp-1">
              {formatDate(item.startingAt)}
            </span>
          </div>

          <div className="mt-1">
            <Button
              className="w-full  px-2 bg-green-600 hover:bg-green-600 text-white font-medium rounded-lg border border-green-600 text-xs"
              onClick={naviagetToChdhava}
            >
              OFFER CHADHAWA →
            </Button>
          </div>

          {/* <button className="bg-gradient-to-r from-orange-600 to-orange-500 text-white px-4 py-1.5 rounded-md text-xs font-medium">
                Offer Chadhaava
              </button> */}
        </div>
      </div>
    </div>
  );
}

export default TrendingChadhaavaCard;
