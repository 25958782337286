import React from "react";
import { getCurrency } from "../../../utils/helper.utils";
import { Divider } from "antd";

const ChadhavaParticipantDetails = ({ order }) => {
  const userOrderDetails = order.details;

  const offeringsArray = order.details.offerings;

  const packageMultiplier = {
    1: "Single", // Single Person
    2: "Couple", // Couple
    3: "Family", // Family
  };

  return (
    <div className="px-4">
      <div className="bg-white px-4 py-4 w-full border rounded-xl">
        <div className="flex flex-col">
          {/* Offerings Display */}
          {offeringsArray.length > 0 && (
            <p className="text-gray-600 text-xs">
              <span className="font-bold">Offerings:</span>{" "}
              {offeringsArray.map((offering, index) => (
                <span key={offering.id}>
                  {offering.name}
                  {offering.count > 1 && `(${offering.count})`}
                  {index !== offeringsArray.length - 1 && ", "}
                </span>
              ))}
            </p>
          )}

          {userOrderDetails?.sankalp_deetails &&
            userOrderDetails?.sankalp_deetails?.length > 0 && (
              <div className="mt-2 flex items-center">
                 <span className="font-bold text-gray-600 text-xs">Devotees: </span>{` `}
                {userOrderDetails?.sankalp_deetails?.map((item, index) => {
                  return (
                    <p className="text-black  text-xs ml-1">
                      {item.name}
                      {index !==
                        userOrderDetails?.sankalp_deetails?.length - 1 &&
                        ", "}{" "}
                    </p>
                  );
                })}
                {order?.package_id && (
                  <p className="text-gray-600 text-xs ml-2">
                    ({packageMultiplier[order.package_id]})
                  </p>
                )}
              </div>
            )}
        </div>
      </div>
    </div>
  );
};

export default ChadhavaParticipantDetails;
