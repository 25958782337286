import axios from "axios";
import moment from "moment";

const isInterkartExpire = process.env.REACT_APP_INTERAKT_EXPIRE;
const INTERAKT_API_KEY = 
  "b1MyOHEweEItdlpyejdUTUtFcmFzbTBxWDhrb3M2ZEVYZWZEUWl0bENEczo=";
const INTERAKT_API = "https://api.interakt.ai/v1/public";

//new user create for interakt
export const createUserForInterKartEvent = async (user: any) => {
  return;
  if (!user) {
    return;
  }

  const eventData = {
    userId: user?.id,
    phoneNumber: user?.phone,
    traits: {
      name: user?.name,
      pincode: user?.pincode,
      email: user?.email,
    },
  };

  const options = {
    url: INTERAKT_API + "/track/users/",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Basic ${INTERAKT_API_KEY}`,
    },
    data: eventData,
  };

  try {
    await axios(options)
      .then((res: any) => {
        user?.callback && user?.callback();
      })
      .catch((err: any) => {});
  } catch (error) {}
};

/// Chadhaava Events Started
export const viewChadhaavaInteraktEvent = async (events: any) => {
  const userJSON = localStorage.getItem("user");
  const user = userJSON ? JSON.parse(userJSON) : null;

  if (!user) {
    return;
  }

  // ✅ Fields to exclude from traits
  const excludedFields = ["id", "phone_number", "user_id", "created_at_utc", "country_code", "event"];

  // ✅ Filter traits: only strings, numbers, booleans and exclude specific fields
  const filteredTraits: any = {};
  Object.keys(events).forEach((key) => {
    const value = events[key];
    if (
      (typeof value === "string" ||
        typeof value === "number" ||
        typeof value === "boolean") &&
      !excludedFields.includes(key)
    ) {
      filteredTraits[key] = value;
    }
  });

  // ✅ Handle offeringDetails: get comma-separated names
  const offeringNames = (events?.offeringDetails || [])
    .map((offering: any) => offering?.name)
    .join(", ");

  // ✅ Add default_image: first image from images array
  const defaultImage = events?.images && events?.images?.length > 0 ? events?.images[0] : null;

  // ✅ Format startingAt using moment.js
  const formattedStartingAt = events?.startingAt
    ? moment(events?.startingAt).format("DD MMM YYYY") // Example: 26 Feb 2025
    : null;

  // ✅ Prepare final event data
  const eventData = {
    userId: user?.userDetails.id,
    event: "View_Chadhaava",
    traits: {
      ...filteredTraits,
      chadhaava_id: events?.id,
      offering_names: offeringNames,
      default_image: defaultImage,
      startingAt: formattedStartingAt,
    },
  };

  // ✅ Send Event
  const options = {
    url: INTERAKT_API + "/track/events/",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Basic ${INTERAKT_API_KEY}`,
    },
    data: eventData,
  };

  try {
    await axios(options);
    console.log("Event sent successfully:", eventData);
  } catch (error) {
    console.error("Error sending event:", error);
  }
};

export const addToCartChadhaavaInteraktEvent = async (events: any) => {
  const userJSON = localStorage.getItem("user");
  const user: any = userJSON ? JSON.parse(userJSON) : null;

  if (!user) {
    return;
  }

  // ✅ Fields to exclude from traits
  const excludedFields = ["id", "phone_number", "user_id", "created_at_utc", "country_code", "event"];

  // ✅ Filter traits: only strings, numbers, booleans and exclude specific fields
  const filteredTraits: any = {};
  Object.keys(events).forEach((key) => {
    const value = events[key];
    if (
      (typeof value === "string" ||
        typeof value === "number" ||
        typeof value === "boolean") &&
      !excludedFields.includes(key)
    ) {
      filteredTraits[key] = value;
    }
  });

  // ✅ Handle offeringDetails: get comma-separated names
  const offeringNames = (events?.offeringDetails || [])
    .map((offering: any) => offering.name)
    .join(", ");

  // ✅ Add default_image: first image from images array
  const defaultImage = events?.images && events?.images.length > 0 ? events?.images[0] : null;

  // ✅ Format startingAt using moment.js
  const formattedStartingAt = events?.startingAt
    ? moment(events?.startingAt).format("DD MMM YYYY") // Example: 26 Feb 2025
    : null;

  // ✅ Prepare final event data
  const eventData = {
    userId: user?.userDetails.id,
    event: "Add_To_Cart_Chadhaava",
    traits: {
      ...filteredTraits,
      chadhaava_id: events?.id,
      offering_names: offeringNames,
      default_image: defaultImage,
      startingAt: formattedStartingAt,
    },
  };

  const options = {
    url: INTERAKT_API + "/track/events/",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Basic ${INTERAKT_API_KEY}`,
    },
    data: eventData,
  };

  try {
    await axios(options);
    console.log("Event sent successfully:", eventData);
  } catch (error) {
    console.error("Error sending event:", error);
  }
};

/// Chadhaava Events Ended


export const addToCartPujaInterKartEvent = async (events: any) => {
  const userJSON = localStorage.getItem("user");
  const user: any = userJSON ? JSON.parse(userJSON) : null;

  if (!user) {
    return;
  }

  // ✅ List of fields to exclude from traits
  const excludedFields = ["id", "phone_number", "user_id", "created_at_utc", "country_code", "event"];

  // ✅ Extract Benefits from tabs
  const benefitsList = (events?.tabs?.Benefits || [])
    .map((benefit: any) => benefit.title)
    .join(", ");

  // ✅ Use provided default_image or first image from images array
  const defaultImage = events?.default_image || (events?.images && events?.images.length > 0 ? events?.images[0] : null);

  // ✅ Prepare filtered package data
  const filteredPackage = events?._filteredPackageData || {};
  const cleanedPackageData = {
    package_id: filteredPackage.id,
    package_name: filteredPackage.name,
    package_price: filteredPackage.price,
    total_order_amount: filteredPackage.price
  };

  // ✅ Format startingAt using moment.js
  const formattedStartingAt = events?.startingAt
    ? moment(events?.startingAt).format("DD MMM YYYY") // Example: 26 Feb 2025
    : null;

  // ✅ Prepare cleaned event data with filtered traits
  const rawTraits: any = {
    puja_id: events?.id,
    puja_name: events?.name,
    description: events?.description,
    location: events?.location,
    startingAt: formattedStartingAt,
    mandir_name: events?.mandir_name,
    tithi: events?.tithi,
    benefits: benefitsList || events?.benefits,
    currency_code: events?.currency_code,
    default_image: defaultImage,
    decoration_tag: events?.decoration_tag,
    ...cleanedPackageData
  };

  // ✅ Remove excluded fields from traits
  const cleanedTraits = Object.keys(rawTraits).reduce((acc, key) => {
    if (!excludedFields.includes(key) && rawTraits[key] !== undefined && rawTraits[key] !== null) {
      acc[key] = rawTraits[key];
    }
    return acc;
  }, {} as Record<string, any>);

  // ✅ Final event data
  const eventData = {
    userId: user?.userDetails.id,
    event: "Add_To_Cart",
    traits: cleanedTraits
  };

  // ✅ Send Event to API
  const options = {
    url: INTERAKT_API + "/track/events/",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Basic ${INTERAKT_API_KEY}`,
    },
    data: eventData,
  };

  try {
    await axios(options);
    console.log("Event sent successfully:", eventData);
  } catch (error) {
    console.error("Error sending event:", error);
  }
};

export const openSankalpPujaInterKartEvent = async (events: any) => {
  const userJSON = localStorage.getItem("user");
  const user: any = userJSON ? JSON.parse(userJSON) : null;

  if (!user) {
    return;
  }

  // ✅ List of fields to exclude from traits
  const excludedFields = ["id", "phone_number", "user_id", "created_at_utc", "country_code", "event"];

  // ✅ Extract Benefits from tabs
  const benefitsList = (events?.tabs?.Benefits || [])
    .map((benefit: any) => benefit.title)
    .join(", ");

  // ✅ Use provided default_image or first image from images array
  const defaultImage = events?.default_image || (events?.images && events?.images.length > 0 ? events?.images[0] : null);

  // ✅ Prepare filtered package data
  const filteredPackage = events?._filteredPackageData || {};
  const cleanedPackageData = {
    package_id: filteredPackage.id,
    package_name: filteredPackage.name,
    package_price: filteredPackage.price,
    total_order_amount: events?.totalPrice || filteredPackage.price // Use totalPrice if provided
  };

  // ✅ Format startingAt using moment.js
  const formattedStartingAt = events?.startingAt
    ? moment(events?.startingAt).format("DD MMM YYYY") // Example: 26 Feb 2025
    : null;

  // ✅ Extract add_ons from selectedItems
  const addOns = (events?.selectedItems || [])
    .map((item: any) => item.name)
    .join(", ");

  // ✅ Prepare cleaned event data with filtered traits
  const rawTraits: any = {
    puja_id: events?.id,
    puja_name: events?.name,
    description: events?.description,
    location: events?.location,
    startingAt: formattedStartingAt,
    mandir_name: events.mandir_name,
    tithi: events.tithi,
    benefits: benefitsList || events.benefits,
    currency_code: events.currency_code,
    default_image: defaultImage,
    decoration_tag: events.decoration_tag,
    add_ons: addOns, // ✅ Add-ons field
    ...cleanedPackageData
  };

  // ✅ Remove excluded fields from traits
  const cleanedTraits = Object.keys(rawTraits).reduce((acc, key) => {
    if (!excludedFields.includes(key) && rawTraits[key] !== undefined && rawTraits[key] !== null) {
      acc[key] = rawTraits[key];
    }
    return acc;
  }, {} as Record<string, any>);

  // ✅ Final event data
  const eventData = {
    userId: user?.userDetails.id,
    event: "Open_Sankalp_Puja",
    traits: cleanedTraits
  };

  // ✅ Send Event to API
  const options = {
    url: INTERAKT_API + "/track/events/",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Basic ${INTERAKT_API_KEY}`,
    },
    data: eventData,
  };

  try {
    await axios(options);
    console.log("Event sent successfully:", eventData);
  } catch (error) {
    console.error("Error sending event:", error);
  }
};

export const closeSankalpPujaInterKartEvent = async (events: any) => {
  const userJSON = localStorage.getItem("user");
  const user: any = userJSON ? JSON.parse(userJSON) : null;

  if (!user) {
    return;
  }

  // ✅ List of fields to exclude from traits
  const excludedFields = ["id", "phone_number", "user_id", "created_at_utc", "country_code", "event"];

  // ✅ Extract Benefits from tabs
  const benefitsList = (events.tabs?.Benefits || [])
    .map((benefit: any) => benefit.title)
    .join(", ");

  // ✅ Use provided default_image or first image from images array
  const defaultImage = events.default_image || (events.images && events.images.length > 0 ? events.images[0] : null);

  // ✅ Prepare filtered package data
  const filteredPackage = events._filteredPackageData || {};
  const cleanedPackageData = {
    package_id: filteredPackage.id,
    package_name: filteredPackage.name,
    package_price: filteredPackage.price,
    total_order_amount: events.totalPrice || filteredPackage.price // Use totalPrice if provided
  };

  // ✅ Format startingAt using moment.js
  const formattedStartingAt = events.startingAt
    ? moment(events.startingAt).format("DD MMM YYYY") // Example: 26 Feb 2025
    : null;

  // ✅ Extract add_ons from selectedItems
  const addOns = (events.selectedItems || [])
    .map((item: any) => item.name)
    .join(", ");

  // ✅ Prepare cleaned event data with filtered traits
  const rawTraits: any = {
    puja_id: events.id,
    puja_name: events.name,
    description: events.description,
    location: events.location,
    startingAt: formattedStartingAt,
    mandir_name: events.mandir_name,
    tithi: events.tithi,
    benefits: benefitsList || events.benefits,
    currency_code: events.currency_code,
    default_image: defaultImage,
    decoration_tag: events.decoration_tag,
    add_ons: addOns, // ✅ Add-ons field
    ...cleanedPackageData
  };

  // ✅ Remove excluded fields from traits
  const cleanedTraits = Object.keys(rawTraits).reduce((acc, key) => {
    if (!excludedFields.includes(key) && rawTraits[key] !== undefined && rawTraits[key] !== null) {
      acc[key] = rawTraits[key];
    }
    return acc;
  }, {} as Record<string, any>);

  // ✅ Final event data
  const eventData = {
    userId: user?.userDetails.id,
    event: "Open_Sankalp_Puja",
    traits: cleanedTraits
  };

  // ✅ Send Event to API
  const options = {
    url: INTERAKT_API + "/track/events/",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Basic ${INTERAKT_API_KEY}`,
    },
    data: eventData,
  };

  try {
    await axios(options);
    console.log("Event sent successfully:", eventData);
  } catch (error) {
    console.error("Error sending event:", error);
  }
};


/// Puja Events Started

/// Puja Events Ended












export const removeFromCartInterKartEvent = async (product: any, cart: any) => {
  const userJSON = localStorage.getItem("user");
  const user: any = userJSON ? JSON.parse(userJSON) : null;

  return;
  if (!user) {
    return;
  }

  const eventData = {
    userId: user?.userDetails.id,
    event: "Product Removed From Cart",
    traits: {
      productName: product.name,
      productDiscountedPrice: product.discounted_price,
      productMrp: product.mrp,
      cartId: cart?.id,
      cartPrice: cart?.discounted_price,
      cartMrp: cart?.mrp,
    },
  };

  const options = {
    url: INTERAKT_API + "/track/events/",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Basic ${INTERAKT_API_KEY}`,
    },
    data: eventData,
  };
  await axios(options);
};

export const openTEMPLPageInterKartEvent = async (temple: any) => {
  const userJSON = localStorage.getItem("user");
  const user: any = userJSON ? JSON.parse(userJSON) : null;

  return;

  if (!user) {
    return;
  }

  const eventData = {
    userId: user?.userDetails.id,
    event: "Open Temple Page",
    traits: {
      templeName: temple.name,
      templeId: temple.id,
    },
  };

  const options = {
    url: INTERAKT_API + "/track/events/",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Basic ${INTERAKT_API_KEY}`,
    },
    data: eventData,
  };
  await axios(options);
};

export const addToCartInterKartEvent = async (product: any, cart: any) => {
  const userJSON = localStorage.getItem("user");
  const user: any = userJSON ? JSON.parse(userJSON) : null;

  return;
  if (!user) {
    return;
  }

  const eventData = {
    userId: user?.userDetails.id,
    event: "Added To Cart",
    traits: {
      productName: product.name,
      productDiscountedPrice: product.discounted_price,
      productMrp: product.mrp,
      cartId: cart?.id,
      cartPrice: cart?.discounted_price,
      cartMrp: cart?.mrp,
    },
  };

  const options = {
    url: INTERAKT_API + "/track/events/",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Basic ${INTERAKT_API_KEY}`,
    },
    data: eventData,
  };
  await axios(options);
};

export const addressSubmitInterKartEvent = async (address: any) => {
  const userJSON = localStorage.getItem("user");
  const user: any = userJSON ? JSON.parse(userJSON) : null;

  return;
  if (!user) {
    return;
  }

  const eventData = {
    userId: user?.userDetails.id,
    event: "Address Submit",
    traits: {
      ...address,
    },
  };

  const options = {
    url: INTERAKT_API + "/track/events/",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Basic ${INTERAKT_API_KEY}`,
    },
    data: eventData,
  };
  await axios(options).then((res: any) => {
    window.location.reload();
  });
};

export const templePayStartInterKartEvent = async (temple: any) => {
  const userJSON = localStorage.getItem("user");
  const user: any = userJSON ? JSON.parse(userJSON) : null;

  return;
  if (!user) {
    return;
  }

  const eventData = {
    userId: user?.userDetails.id,
    event: "Temple payment start",
    traits: {
      ...temple,
    },
  };

  const options = {
    url: INTERAKT_API + "/track/events/",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Basic ${INTERAKT_API_KEY}`,
    },
    data: eventData,
  };
  await axios(options);
};

export const templePrepiaStartInterKartEvent = async (temple: any) => {
  const userJSON = localStorage.getItem("user");
  const user: any = userJSON ? JSON.parse(userJSON) : null;

  return;
  if (!user) {
    return;
  }

  const eventData = {
    userId: user?.userDetails.id,
    event: "Prepaid Order",
    traits: {
      ...temple,
    },
  };

  const options = {
    url: INTERAKT_API + "/track/events/",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Basic ${INTERAKT_API_KEY}`,
    },
    data: eventData,
  };
  await axios(options);
};

export const templeCODStartInterKartEvent = async (temple: any) => {
  const userJSON = localStorage.getItem("user");
  const user: any = userJSON ? JSON.parse(userJSON) : null;

  return;
  if (!user) {
    return;
  }

  const eventData = {
    userId: user?.userDetails.id,
    event: "COD Order Start",
    traits: {
      ...temple,
    },
  };

  const options = {
    url: INTERAKT_API + "/track/events/",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Basic ${INTERAKT_API_KEY}`,
    },
    data: eventData,
  };
  await axios(options);
};

export const templePayCompleteStartInterKartEvent = async (temple: any) => {
  const userJSON = localStorage.getItem("user");
  const user: any = userJSON ? JSON.parse(userJSON) : null;

  return;
  if (!user) {
    return;
  }

  const eventData = {
    userId: user?.userDetails.id,
    event: "Temple payment complete",
    traits: {
      ...temple,
    },
  };

  const options = {
    url: INTERAKT_API + "/track/events/",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Basic ${INTERAKT_API_KEY}`,
    },
    data: eventData,
  };
  await axios(options);
};

export const homeButtonInterKartEvent = async (btnName: any) => {
  const userJSON = localStorage.getItem("user");
  const user: any = userJSON ? JSON.parse(userJSON) : null;

  return;
  if (!user) {
    return;
  }

  const eventData = {
    userId: user?.userDetails.id,
    event: btnName,
    traits: {},
  };

  const options = {
    url: INTERAKT_API + "/track/events/",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Basic ${INTERAKT_API_KEY}`,
    },
    data: eventData,
  };
  await axios(options);
};

export const counselorStartInterKartEvent = async (temple: any) => {
  const userJSON = localStorage.getItem("user");
  const user: any = userJSON ? JSON.parse(userJSON) : null;

  return;
  if (!user) {
    return;
  }

  const eventData = {
    userId: user?.userDetails.id,
    event: "Counselor OnBoard Start",
    traits: {
      ...temple,
    },
  };

  const options = {
    url: INTERAKT_API + "/track/events/",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Basic ${INTERAKT_API_KEY}`,
    },
    data: eventData,
  };
  await axios(options);
};

export const counselorFillInfoInterKartEvent = async (temple: any) => {
  const userJSON = localStorage.getItem("user");
  const user: any = userJSON ? JSON.parse(userJSON) : null;

  return;
  if (!user) {
    return;
  }

  const eventData = {
    userId: user?.userDetails.id,
    event: "Counselor Fill Information",
    traits: {
      ...temple,
    },
  };

  const options = {
    url: INTERAKT_API + "/track/events/",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Basic ${INTERAKT_API_KEY}`,
    },
    data: eventData,
  };
  await axios(options);
};

export const counselorDetailsInterKartEvent = async (temple: any) => {
  const userJSON = localStorage.getItem("user");
  const user: any = userJSON ? JSON.parse(userJSON) : null;

  return;
  if (!user) {
    return;
  }

  const eventData = {
    userId: user?.userDetails.id,
    event: "About The Guru",
    traits: {
      ...temple,
    },
  };

  const options = {
    url: INTERAKT_API + "/track/events/",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Basic ${INTERAKT_API_KEY}`,
    },
    data: eventData,
  };
  await axios(options);
};

export const counselorPayStartInterKartEvent = async (temple: any) => {
  const userJSON = localStorage.getItem("user");
  const user: any = userJSON ? JSON.parse(userJSON) : null;

  return;
  if (!user) {
    return;
  }

  const eventData = {
    userId: user?.userDetails.id,
    event: "Book Guru Payment Start",
    traits: {
      ...temple,
    },
  };

  const options = {
    url: INTERAKT_API + "/track/events/",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Basic ${INTERAKT_API_KEY}`,
    },
    data: eventData,
  };
  await axios(options);
};

export const counselorPayCompleteInterKartEvent = async (temple: any) => {
  const userJSON = localStorage.getItem("user");
  const user: any = userJSON ? JSON.parse(userJSON) : null;

  return;
  if (!user) {
    return;
  }

  const eventData = {
    userId: user?.userDetails.id,
    event: "Book Guru Payment Complete",
    traits: {
      ...temple,
    },
  };

  const options = {
    url: INTERAKT_API + "/track/events/",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Basic ${INTERAKT_API_KEY}`,
    },
    data: eventData,
  };
  await axios(options);
};

/// ----------------- Puja Events ----------------- ///
export const openPujaPageInterKartEvent = async (puja: any) => {
  const userJSON = localStorage.getItem("user");
  const user: any = userJSON ? JSON.parse(userJSON) : null;

  return;
  if (!user) {
    return;
  }

  const eventData = {
    userId: user?.userDetails.id,
    event: "Open_Puja_Page",
    traits: {
      puja_name: puja.name,
      puja_id: puja.id,
      puja_starting_date: puja.startingAt,
    },
  };

  const options = {
    url: INTERAKT_API + "/track/events/",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Basic ${INTERAKT_API_KEY}`,
    },
    data: eventData,
  };
  await axios(options);
};

export const pujaParticipantPageInterKartEvent = async (puja: any) => {
  const userJSON = localStorage.getItem("user");
  const user: any = userJSON ? JSON.parse(userJSON) : null;

  return;
  if (!user) {
    return;
  }

  const eventData = {
    userId: user?.userDetails.id,
    event: "Puja_Participation_Start",
    traits: {
      puja_name: puja.name,
      puja_id: puja.id,
      puja_starting_date: puja.startingAt,
    },
  };

  const options = {
    url: INTERAKT_API + "/track/events/",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Basic ${INTERAKT_API_KEY}`,
    },
    data: eventData,
  };
  await axios(options);
};

export const pujaBokingStartPageInterKartEvent = async (puja: any) => {
  const userJSON = localStorage.getItem("user");
  const user: any = userJSON ? JSON.parse(userJSON) : null;

  return;
  if (!user) {
    return;
  }

  const eventData = {
    userId: user?.userDetails.id,
    event: "Puja_Booking_Start",
    traits: {
      puja_name: puja.name,
      puja_id: puja.id,
      puja_starting_date: puja.startingAt,
      package_id: puja.package?.id,
      puja_amount: puja.package?.price,
    },
  };

  const options = {
    url: INTERAKT_API + "/track/events/",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Basic ${INTERAKT_API_KEY}`,
    },
    data: eventData,
  };
  await axios(options);
};

export const pujaPaymentStartPageInterKartEvent = async (puja: any) => {
  const userJSON = localStorage.getItem("user");
  const user: any = userJSON ? JSON.parse(userJSON) : null;

  return;
  if (!user) {
    return;
  }

  const eventData = {
    userId: user?.userDetails.id,
    event: "Puja_Payment_Start",
    traits: {
      puja_name: puja.name,
      mandir_name: puja.mandir_name,
      location: puja.location,
      puja_id: puja.id,
      puja_amount: puja.package?.price,
      package_id: puja.package?.id,
      order_id: puja?.order_id,
      package_name: puja.package?.name,
      packagePrice: puja?.package?.price,
      order_amount: Number(puja?.totalPrice),
      puja_starting_date: moment(puja.startingAt).format("DD MMM YYYY"),
      add_on: puja?.addon_id
        ? puja?.addon_id?.map((item: any) => item?.name)?.join(",")
        : "",
      booking_deep_link: window.location.href,
    },
  };

  const options = {
    url: INTERAKT_API + "/track/events/",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Basic ${INTERAKT_API_KEY}`,
    },
    data: eventData,
  };
  try {
    await axios(options);
  } catch (error) {}
};

export const pujaPaymentCompleteStartPageInterKartEvent = async (puja: any) => {
  const userJSON = localStorage.getItem("user");
  const user: any = userJSON ? JSON.parse(userJSON) : null;

  return;
  if (!user) {
    return;
  }

  const eventData = {
    userId: user?.userDetails.id,
    event: "Puja_Payment_Complete",
    traits: {
      puja_name: puja.puja_name,
      mandir_name: puja.mandir_name,
      location: puja.location,
      puja_id: puja.puja_id,
      puja_amount: puja.puja_package_amount,
      package_id: puja.package_id,
      package_name: puja.package_name,
      order_id: puja.orderId,
      order_amount: puja?.total_order_amount,
      packagePrice: puja?.puja_package_amount,
      puja_starting_date: puja.puja_starting_at,
      add_on: puja?.addons
        ? puja?.addons?.map((item: any) => item?.name)?.join(",")
        : "",
    },
  };

  const options = {
    url: INTERAKT_API + "/track/events/",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Basic ${INTERAKT_API_KEY}`,
    },
    data: eventData,
  };
  await axios(options);
};

export const PujKeButtonsClickInterKartEvent = async (event: string) => {
  const userJSON = localStorage.getItem("user");
  const user: any = userJSON ? JSON.parse(userJSON) : null;

  return;
  if (!user) {
    return;
  }

  const eventData = {
    userId: user?.userDetails.id,
    event: "Puja_Section_Scroll",
    traits: {
      tab_name: event,
    },
  };

  const options = {
    url: INTERAKT_API + "/track/events/",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Basic ${INTERAKT_API_KEY}`,
    },
    data: eventData,
  };
  await axios(options);
};

export const AstorGuruBookingStartInterKartEvent = async (
  user: any,
  events: any
) => {

  return
  if (!user) {
    return;
  }

  const eventData = {
    userId: user?.userDetails.id,
    event: "Ask_Astroguru_Booking_Start",
    traits: {
      ...events,
    },
  };

  const options = {
    url: INTERAKT_API + "/track/events/",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Basic ${INTERAKT_API_KEY}`,
    },
    data: eventData,
  };
  await axios(options);
};

export const AstorGuruBookingSuccessInterKartEvent = async (events: any) => {
  const userJSON = localStorage.getItem("user");
  const user: any = userJSON ? JSON.parse(userJSON) : null;
  return
  if (!user) {
    return;
  }

  const eventData = {
    userId: user?.userDetails.id,
    event: "Ask_Astroguru_Booking_Start",
    traits: {
      ...events,
    },
  };

  const options = {
    url: INTERAKT_API + "/track/events/",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Basic ${INTERAKT_API_KEY}`,
    },
    data: eventData,
  };
  await axios(options);
};

export const sankalpSubmitInterKartEvent = async (
  events: any
) => {

  const userJSON = localStorage.getItem("user");
  const user: any = userJSON ? JSON.parse(userJSON) : null;

  if (!user) {
    return;
  }

  const eventData = {
    userId: user?.userDetails.id,
    event: "Sankalp_Submit",
    traits: {
      ...events,
    },
  };

  const options = {
    url: INTERAKT_API + "/track/events/",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Basic ${INTERAKT_API_KEY}`,
    },
    data: eventData,
  };
  await axios(options);
};