import React from "react";
import { Button, Skeleton, Typography } from "antd";
import { IoLocateOutline, IoCalendarOutline } from "react-icons/io5";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const TrendingPujaCard = ({ item, loading }: any) => {
  const navigate = useNavigate();
  const navigateToPoojaDetails = () => {
    if (!loading) {
      navigate( `/pooja/${item.id}`)
      // window.location.href = `/pooja/${item.id}`;
    }
  };

  return (
    <div
      onClick={navigateToPoojaDetails}
      className="bg-white border rounded-xl overflow-hidden"
    >
      {loading ? (
        <div className="p-2">
          <Skeleton
            round
            active
            loading={loading}
            avatar
            paragraph={{ rows: 4 }}
          />
        </div>
      ) : (
        <>
          {/* Top Image Section */}
          <div className="relative">
            <img
              className="w-full h-44 object-cover"
              src={
                item?.default_image ||
                item?.images[0] ||
                "https://storage.googleapis.com/devp_content/48_banner.jpg"
              }
              alt={item.name}
            />
            {item.decoration_tag && (
              <div className="absolute top-2 left-2 bg-orange-500 text-white px-3 py-1 text-xs font-semibold rounded-md shadow-sm">
                {item.decoration_tag}
              </div>
            )}
          </div>

          {/* Content Section */}
          <div className="px-2 py-1 relative">
            {item.tithi && (
              <div className="flex flex-col items-center justify-center w-full">
                <Typography.Text className="text-sm font-semibold text-[#D61F69]  line-clamp-2">
                  {item.tithi}
                </Typography.Text>
                <div className="pt-[0.5px] w-full my-2 bg-gradient-to-r from-transparent via-[#D61F69] to-transparent"></div>
              </div>
            )}


            {/* Card Content */}
            <h2
              className="text-sm font-bold text-gray-800 mb-2 relative"
              style={{
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 2,
                overflow: "hidden",
              }}
            >
              {item.name}
            </h2>
            <p
              className="text-xs text-gray-500 mb-4 relative"
              style={{
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 2,
                overflow: "hidden",
              }}
            >
              {item.description}
            </p>
            <div className="flex items-center text-xs text-gray-500 relative">
              <IoLocateOutline className="mr-2 text-orange-500" />
              <span>
                {item.mandir_name}, {item.location}
              </span>
            </div>
            <div className="flex items-center text-xs text-gray-500 relative">
              <IoCalendarOutline className="mr-2 text-orange-500" />
              <span>
                {moment(item.startingAt).format("DD MMMM YYYY, dddd")}
                {item.tithi ? `, ${item.tithi}` : ""}
              </span>
            </div>
          </div>

          {/* Bottom Button */}
          <div className="px-4 pb-2 mt-1">
            <Button
              className="w-full bg-green-600 hover:bg-green-600 text-white font-semibold rounded-lg border border-green-600 h-10 text-lg"
              onClick={navigateToPoojaDetails}
            >
              PARTICIPATE →
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default TrendingPujaCard;