import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Input, Button, Checkbox, notification, message } from "antd";
import { IoArrowBack, IoInformationCircleOutline } from "react-icons/io5";
import InfoDrawer from "./components/InfoDrawer";
import ConfirmationDrawer from "./components/ConfirmationDrawer";
import ConfirmationModal from "./components/ConfirmationModal";
import { getRequest, postRequest } from "../../utils/api.utils";
import {
  initiateCheckoutPX,
  openSankalpFormPX,
  proceedToBackPX,
} from "../../events/facebookPexelsEvent";
import CustomDatePicker from "../../components/CustomDatePicker";
import { initiateCheckoutGA } from "../../events/gaEvent";
import { initiateCheckoutCLARITY } from "../../events/clarityEvetns";
import {
  countryOptions,
  fbcValue,
  fbpValue,
  getCountryCode,
  getUtm,
  getUTMParams,
  isDevMode,
  validatePhoneNumber,
} from "../../utils/helper.utils";
import vd from "../../assets/open_loading.gif";
import InputPhone from "react-phone-number-input/input";
import PhoneInput from "../auth/PhoneInput";
import { isBnplActive } from "../../utils/bnpl.utils";
import {
  closeSankalpPujaInterKartEvent,
  openSankalpPujaInterKartEvent,
} from "../../events/interkartEvents";

const PujaForm = () => {
  const { packageId } = useParams();
  const totalMembers = Number(packageId);

  const { state } = useLocation();
  const navigate = useNavigate();

  const cCode = getCountryCode();

  let country_code = countryOptions.find(
    (item) => item?.short_name?.toLowerCase() === cCode
  )?.dial_code;

  const [formData, setFormData] = useState(
    Array.from({ length: totalMembers }, () => ({ name: "", gotra: "" }))
  );

  const [gotra, setGotra] = useState("");
  const [wish, setWish] = useState("");
  const [city, setCity] = useState("");
  const [dob, setDob] = useState(null);
  const [countryCode, setCountryCode] = useState(country_code || "91");

  const [dontKnowGotra, setDontKnowGotra] = useState(false);
  const [showInfoDrawer, setShowInfoDrawer] = useState(false);
  const [showConfirmationDrawer, setShowConfirmationDrawer] = useState(false);
  const [showGoBackModal, setShowGoBackModal] = useState(false);

  const [loading, setLoading] = useState(false);

  let pooja_id = state.poojaDetails.id;
  let package_id = state.filteredPackageData.id;
  let selectedItems = state.selectedItems;
  let numberOfPersons = totalMembers;
  let filteredPackageData = state.filteredPackageData;
  let totalPrice = state.totalPrice;
  let poojaDetails = state.poojaDetails;
  let price = state.filteredPackageData.price;

  const userJSON = localStorage.getItem("user");
  const user = userJSON ? JSON.parse(userJSON) : null;

  let isd_code_details = countryOptions.find(
    (cnt) => cnt.short_name?.toLowerCase() === cCode?.toLowerCase()
  );

  let isIndia = cCode === "in";

  const [whatsAppNumber, setWhatsAppNumber] = useState("");

  let location_info = localStorage.getItem("location_info")
    ? JSON.parse(localStorage.getItem("location_info"))
    : null;

  useEffect(() => {
    openSankalpFormPX({
      totalPrice,
      ...poojaDetails,
      pooja_id,
      package_id,
      type: "puja",
    });

    openSankalpPujaInterKartEvent({
      ...state.poojaDetails,
      _filteredPackageData: state.filteredPackageData,
      totalPrice,
      selectedItems,
    });
  }, []);



    const [sankalpDetails, setSankalpDetails] = useState(null);
  
    const getLatestSankalpDetails = (data, packageId) => {
      if (!data || !data.results || !data.results.result) return null;
    
      const { result } = data.results;
    
      // Filter Sankalp details with non-zero length and valid package_id
      const filteredResults = result.filter(
        (order) =>
          order.sankalp_details?.length > 0 &&
          (!packageId || order.package_id == packageId)
      );
    
      // If no result with the same package ID and Sankalp details exists, fallback to the latest object
      if (filteredResults.length === 0) {
        return result
          .filter((order) => order.sankalp_details?.length > 0)
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))[0];
      }
    
      // Sort by createdAt (latest first) and return the one with maximum Sankalp details
      const latestOrder = filteredResults.sort((a, b) => {
        const dateComparison = new Date(b.createdAt) - new Date(a.createdAt);
        if (dateComparison !== 0) return dateComparison; // Latest first
        return b.sankalp_details.length - a.sankalp_details.length; // Maximum Sankalp details
      })[0];
    
      // ✅ Extracting all names from sankalp_details
      const allNames = result.flatMap(order => 
        order.sankalp_details?.map(detail => detail.name.trim()) || []
      );
    
      // ✅ Remove duplicate names by using a Set
      const uniqueNames = Array.from(new Set(allNames));
    
      console.log("Unique Names List:", uniqueNames);
    
      return { latestOrder, uniqueNames };
    };
    const getSankalpDetails = async () => {
      const user = localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : null;
  
      if (user) {
        const response = await getRequest(
          `/noauth-api/v1/pooja/getPujaOrderDetails?user_id=${user?.userDetails?.id}`
        );
  
        const packageId = package_id; // Example package ID
        const latestSankalp = getLatestSankalpDetails(response, packageId);
  
        console.log(latestSankalp,"latestSankalplatestSankalplatestSankalplatestSankalp");
        
        setSankalpDetails(latestSankalp?.latestOrder);
      }
    };
  
    // useEffect(() => {
    //   getSankalpDetails();
    // }, [pooja_id]);


  // Load saved details from localStorage
  useEffect(() => {
    const savedDetails = localStorage.getItem("sankalpDetails");
    if (savedDetails) {
      const { formData: savedFormData, gotra: savedGotra } =
        JSON.parse(savedDetails);

      // Adjust the formData to always match totalMembers
      const adjustedFormData = Array.from(
        { length: totalMembers },
        (_, index) =>
          savedFormData && savedFormData[index]
            ? savedFormData[index]
            : { name: "", gotra: "" }
      );

      setFormData(adjustedFormData);
      setGotra(savedGotra || "");
      setDontKnowGotra(savedGotra === "Kashyap");
    } else {
      // Initialize formData with empty fields if no data is stored
      setFormData(
        Array.from({ length: totalMembers }, () => ({ name: "", gotra: "" }))
      );
    }
  }, [totalMembers]);

  const mergeAccountHandler = async () => {
    let reqbody = {
      mergeAccount: true,
      phone: whatsAppNumber?.slice(3),
      isd_code: "+" + countryCode,
    };

    if (!validatePhoneNumber(reqbody.phone)) {
      message.error("Please enter a valid mobile number");
      return;
    }
    setLoading(true);
    refreshUserToken();
    // await postRequest(`/api/v1/login/updateUser`, reqbody)
    //   .then((res) => {
    //     refreshUserToken();
    //   })
    //   .catch((err) => {
    //     return false;
    //   });
  };

  const refreshUserToken = async () => {
    // let reqBody = {
    //   isdCode: "+" + countryCode,
    //   phone: whatsAppNumber?.slice(3),
    // };

    const utm = getUTMParams();

    let reqBody = {};

    const userAgent = window.navigator?.userAgent;
    const platform = window.navigator?.platform;
    const randomString =
      Math.random().toString(20).substring(2, 14) +
      Math.random().toString(20).substring(2, 14);

    const deviceID = `${userAgent}-${platform}-${randomString}`;

    if (!whatsAppNumber) {
      message.error("Please enter a valid mobile number");
      return;
    }

    reqBody = {
      countrycode: cCode || "in",
      phone: whatsAppNumber?.slice(3),
      isdCode: "+" + countryCode,
    };

    if (location_info) {
      reqBody.location = location_info;
    }
    if (deviceID) {
      reqBody.device = deviceID;
    }
    if (utm) {
      reqBody.utm = utm;
    }


    let anyName = formData?.filter(
      (item) => item.name.length > 0
    );


    if (anyName.length === 0) {
      message.error("Enter sankalp details please");
      return;
    }
    if (anyName?.length > 0) {
      reqBody.fullname = anyName[0].name;
    }

    setLoading(true);
    postRequest(`/noauth-api/v1/login/user/getotp`, reqBody)
      .then((data) => {
        postRequest("/noauth-api/v1/login/user/verifyotp", {
          ...reqBody,
          otp: "2262",
          session_id: data.data.results.session_id,
        })
          .then((res) => {
            localStorage.setItem(
              "user",
              JSON.stringify({ ...res?.data?.results })
            );

            finalyCreateOrder(res?.data?.results?.token);
          })
          .catch((err) => {
            setLoading(false);
          })
          .finally(() => {});
      })
      .catch(() => {
        setLoading(false);
        message.error("Something went wrong. Please try again later");
      });
  };
  const handleInputChange = (index, value) => {
    const updatedFormData = [...formData];
    updatedFormData[index].name = value;
    setFormData(updatedFormData);
  };

  // Save Sankalp details and Gotra to localStorage
  const saveSankalpDetails = () => {
    const sankalpDetails = { formData, gotra };
    localStorage.setItem("sankalpDetails", JSON.stringify(sankalpDetails));
  };

  const handleGoBack = () => {
    setShowGoBackModal(true);
    // saveSankalpDetails(); // Save details before showing the modal
    // if (formData.some((member) => !member.name)) {
    //   setShowGoBackModal(true);
    // } else {
    //   navigate(-1);
    // }
  };

  const confirmGoBack = () => {
    saveSankalpDetails(); // Save details before navigating back
    setShowGoBackModal(false);
    navigate(-1);
  };

  const handleSubmit = () => {
    if (formData.every((member) => !member.name)) {
      notification.error({
        message: "Incomplete Details",
        description: "Please enter atleast one name to proceed with booking.",
        duration: 2,
      });
      return;
    }

    if (whatsAppNumber) {
      mergeAccountHandler();
      return;
    }

    finalyCreateOrder();
    // setShowConfirmationDrawer(true);
  };

  const finalyCreateOrder = async (token) => {
    // if (isDevMode()) {
    //   message.warning("You are in dev mode!");
    //   return;
    // }

    let sankalp_details = formData.map((element) => {
      const details = { name: element.name, gotra };
      // if (package_id == 1) {
      //   details.dob = dob || ""; // Include dob only if package_id is 1
      // }
      return details;
    });

    const authToken = token || user?.token;
    let devotee_name = sankalp_details[0].name || user?.userDetails?.fullname;

    setLoading(true);

    try {
      const orderResponse = await postRequest(
        "/api/v1/pooja/createOrder",
        {
          puja_id: Number(pooja_id),
          package_id: Number(package_id),
          add_ons: selectedItems.map((_item) => _item.id),
          devotee_name,
          gotra: gotra || "Kashyap",
          utms: getUtm(),
          fbp: fbpValue,
          fbc: fbcValue,
          referrer_url: localStorage.getItem("referrer_url")
        },
        authToken
      );

      const orderId = orderResponse.data.results?.order_id;

      // Update Sankalp details
      await postRequest(
        "/api/v1/pooja/updatePoojaOrder",
        {
          id: orderResponse.data.results?.puja_order_id,
          sankalp_details,
          wish,
          city,
        },
        authToken
      );

      // Call event handlers
      const interkartEventsData = {
        ...poojaDetails,
        totalPrice,
        addon_id: selectedItems,
        package: { price, id: package_id, name: filteredPackageData?.name },
        order_id: orderId,
      };

      initiateCheckoutPX(
        "Puja",
        interkartEventsData.totalPrice,
        interkartEventsData.name,
        [pooja_id || ""],
        orderResponse.data.results.active_user_session || "", // event_id.
        orderResponse.data.results
      );

      initiateCheckoutGA({
        currency: "INR",
        value: interkartEventsData.totalPrice,
        items: [
          {
            item_id: pooja_id,
            item_name: interkartEventsData.name,
            item_category: "puja",
          },
        ],
      });

      initiateCheckoutCLARITY({
        currency: "INR",
        value: interkartEventsData.totalPrice,
        items: [
          {
            item_id: pooja_id,
            item_name: interkartEventsData.name,
            item_category: "puja",
          },
        ],
      });

      if (orderResponse.data.results.payment_gateway === "dodo") {
        window.location.href = orderResponse.data.results.payment_link;
      } else if (orderResponse.data.results.payment_gateway === "razorpay") {
        const orderId = orderResponse.data.results?.order_id;

        pay(orderId, orderResponse.data.results); // Open payment gateway
      } else {
        message.error("Payment gateway not found.!");
      }
    } catch (error) {
      message.error("Something went wrong while creating the order.");
      console.error(error);
    }
  };

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  const pay = async (orderId, orderResponse) => {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }
    const options = {
      ...(orderId && { order_id: orderId }),
      handler: async function (response) {
        if (response?.razorpay_order_id) {
          try {
            await postRequest("/noauth-api/v1/payment/paymentOrderUpdate", {
              ...response,
              order_id: orderId,
            });
          } catch (error) {
            console.error(error);
          }

          message.success("Order placed successfully!");

          navigate(
            `/success-page/${orderResponse?.puja_order_id}/puja/${poojaDetails?.name}?is_add_on_booking_check=true`
          );

          setLoading(false);
        }
        setLoading(false);
      },
      prefill: {
        contact: `${user?.userDetails?.isd_code || "91"}${
          user?.userDetails?.phone
        }`,
        email: user?.userDetails?.email || "devpunyahome@gmail.com",
      },
      theme: {
        color: "#FFA500",
      },
      modal: {
        ondismiss: function () {
          setLoading(false);
        },
      },
    };

    const paymentObject = new window.Razorpay(options);

    paymentObject.on("payment.failed", async function (response) {
      try {
        await postRequest("/noauth-api/v1/payment/paymentOrderUpdate", {
          ...response.error,
          ...response.error.metadata,
          order_id: orderId,
        });
      } catch (error) {
        console.error(error);
      }
      navigate(`/failure-page/${orderId}`);
      setLoading(false);
    });

    paymentObject.open();
  };


  const isAddressRequired = selectedItems.some(
    (addon) => addon.address_required
  );

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <img src={vd} alt="loading..." />
      </div>
    );
  }

  const isBNPL = isBnplActive();

  return (
    <div className="bg-gray-100">
      {/* Header */}
      <div className="flex items-center px-4 mb-6 py-2 border-b bg-white">
        <IoArrowBack
          onClick={handleGoBack}
          className="text-gray-500 text-2xl cursor-pointer"
        />
        <h2 className="text-lg ml-4 font-semibold text-gray-800">
          Fill Sankalp Form Details
        </h2>
      </div>

      <div className="px-2 min-h-screen">
        {/* Gotra */}
        <div className="mb-2 bg-white rounded-lg border p-4">
          <div className="flex justify-between items-center">
            <h3 className="text-sm font-semibold text-gray-800 ">
              Gotra of Puja members (भक्त का गोत्र)
            </h3>
            <IoInformationCircleOutline
              className="text-xl text-orange-500 cursor-pointer"
              onClick={() => setShowInfoDrawer(true)}
            />
          </div>
          <p className="text-xs text-gray-500 mb-4">
            If you don’t know your gotra, write <strong>Kashyap</strong>{" "}
            according to Vedic norms.
          </p>
          <Input
            placeholder="Gotra"
            value={gotra}
            onChange={(e) => setGotra(e.target.value)}
            className="mb-2 border border-gray-300 rounded-lg text-base p-2"
          />
          <Checkbox
            checked={dontKnowGotra}
            onChange={(e) => {
              setDontKnowGotra(e.target.checked);
              if (e.target.checked) {
                setGotra("Kashyap");
              } else {
                setGotra("");
              }
            }}
          >
            I do not know my gotra
          </Checkbox>
        </div>

        {!isIndia && (
          <div className="mb-2 bg-white rounded-lg border p-4">
            <div className="flex justify-between items-center">
              <h3 className="text-sm font-semibold text-gray-800 ">
                WhatsApp Number{" "}
              </h3>
            </div>

            <p className="text-xs text-gray-500 mb-4">
              For receiving puja updates & video on WhatsApp
            </p>

            <PhoneInput
              isOptional
              noTitle
              whatsAppNumber={whatsAppNumber}
              setWhatsAppNumber={setWhatsAppNumber}
              countryCode={countryCode}
              setCountryCode={setCountryCode}
            />
          </div>
        )}

        {/* Member Names */}
        <div className="mb-2 bg-white rounded-lg border p-4">
          <h3 className="text-sm font-semibold text-gray-800">
            Name of members participating in Puja
          </h3>
          <p className="text-xs text-gray-500 mb-4">
            Panditji will take these names along with gotra during the puja.
          </p>
          {formData.map((member, index) => (
            <Input
              key={index}
              placeholder={`Member ${index + 1} name`}
              value={member.name}
              onChange={(e) => handleInputChange(index, e.target.value)}
              className="mb-2 border border-gray-300 rounded-lg p-2 text-base"
            />
          ))}

          {/* {package_id == 1 && (
            <div className="mb-2">
              <label>Date of Birth</label>
              <CustomDatePicker
                className="w-full mt-2 flex"
                value={dob || null}
                onChange={(date) => setDob(date)}
              />
            </div>
          )} */}

          {/* Address */}
          {isAddressRequired && (
            <div className="">
              <div className="pt-[0.5px] w-full my-4 bg-gradient-to-r from-transparent via-[#D61F69] to-transparent"></div>
              <div className="flex justify-between items-center">
                <h3 className="text-base font-semibold text-gray-800 mb-2">
                  Delivery Address
                </h3>
              </div>

              <Input.TextArea
                placeholder="For example - Flat No, Building Name, Street Name, Locality, City, State, Pincode."
                value={city}
                rows={4}
                onChange={(e) => setCity(e.target.value)}
                className="mb-4 border border-gray-300 rounded-lg text-base p-2"
              />
            </div>
          )}
        </div>
        <div className="pt-[0.5px] w-full my-1 bg-gradient-to-r from-transparent via-[#D61F69] to-transparent"></div>

        <div className="mb-8 bg-white rounded-lg border p-4">
          <p className="text-sm font-semibold text-gray-800">
            Your Wish (मनोकामना 🙏){" "}
            <span className="text-gray-400 font-light">(Optional)</span>
          </p>
          <p className="text-xs text-gray-500 mb-4">
            Not mandatory, but we submit your wishes to Mandir.
          </p>
          <Input.TextArea
            placeholder="Devpunya team submits your wishes to temple and pray for you. Wishes our not mandatory to fill and are not used during the puja"
            value={wish}
            rows={4}
            onChange={(e) => setWish(e.target.value)}
            className="mb-4 border border-gray-300 rounded-lg p-2"
          />
        </div>
        <div className="pt-[0.5px] w-full my-10 bg-gradient-to-r from-transparent via-[#D61F69] to-transparent"></div>

        {/* Submit */}
        <div className="fixed bottom-0 left-0 w-full p-2 bg-white">
          <Button
            className="w-full rounded-lg bg-green-600 hover:bg-green-600 text-white text-lg font-semibold h-12  flex items-center justify-center space-x-2 z-50"
            onClick={handleSubmit}
          >
            <span>Proceed to Book Puja</span>
            <div className="text-white text-xl group-hover:translate-x-2 transition-transform duration-300">
              →
            </div>
          </Button>
        </div>
      </div>

      {/* Info Drawer */}
      <InfoDrawer
        visible={showInfoDrawer}
        onClose={() => setShowInfoDrawer(false)}
      />

      {/* Confirmation Drawer */}
      <ConfirmationDrawer
        visible={showConfirmationDrawer}
        onClose={() => setShowConfirmationDrawer(false)}
        formData={formData}
        wish={wish}
        gotra={gotra}
        onOrderPlace={finalyCreateOrder}
      />

      {/* Go Back Confirmation Modal */}
      <ConfirmationModal
        visible={showGoBackModal}
        title="Do you want to go back?"
        description="Enter your name and gotra details to be recited during the puja. A video will be shared with you by Pandit Ji."
        // subDescription="This cannot be changed later. Do you want to proceed with current information?"
        primaryButtonText="Yes, Proceed"
        secondaryButtonText="Continue Booking"
        onPrimaryAction={() => {
          proceedToBackPX({
            totalPrice,
            ...poojaDetails,
            pooja_id,
            package_id,
            type: "puja",
          });
          closeSankalpPujaInterKartEvent({
            ...state.poojaDetails,
            _filteredPackageData: state.filteredPackageData,
            totalPrice,
            selectedItems,
          });
          confirmGoBack();
        }}
        onSecondaryAction={() => setShowGoBackModal(false)}
      />
    </div>
  );
};

export default PujaForm;
