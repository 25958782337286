import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
// import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import { antThemeProvider } from './theme/antThemeProvider';
import ErrorBoundary from './ErrorBoundary';
import App from './App';
import * as Sentry from "@sentry/react";
import MaintenancePage from './pages/other/MaintenancePage';


if(window.location.origin?.includes("devpunya")){
  Sentry.init({
    dsn: "https://f7ea72189bcfbf4196c841b280b745c0@o1159879.ingest.us.sentry.io/4508511104008192",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [/^https:\/\/devpunya\.com\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
     <ErrorBoundary>
        <BrowserRouter>
            <ConfigProvider theme={antThemeProvider}>
                <App />
            </ConfigProvider>
        </BrowserRouter>
    </ErrorBoundary>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
