import React from "react";
import { ArrowRightOutlined } from "@ant-design/icons";

const SectionHeader = ({ title, onClick }) => {
  return (
    <div className="flex justify-between items-center ">
      {/* Title with Left Gradient Indicator */}
      <div className="flex items-center space-x-2">
        <div
          className="w-1 h-6 rounded-lg"
          style={{
            background: "linear-gradient(180deg, #FF9F02 0%, #FF5300 100%)",
          }}
        ></div>
        <h1 className="text-sm font-semibold text-gray-800">{title}</h1>
      </div>

      {/* Icon Button with Gradient Background */}
     {onClick && <button
        className="flex items-center justify-center h-6 w-6 rounded-full hover:scale-105 transition-transform duration-300 shadow-md"
        onClick={onClick}
        style={{
          background: "linear-gradient(180deg, #FF9F02 0%, #FF5300 100%)",
        }}
        aria-label="View All"
      >
        <ArrowRightOutlined
          style={{ fontSize: "16px", color: "white", fontWeight: "bold" }}
        />
      </button>}
    </div>
  );
};

export default SectionHeader;