import React, { useEffect, useState } from "react";
import Page from "../../layouts/Page";
import { Button, Divider, Form, Typography, message, notification } from "antd";
import temple from "../../assets/images/temple-icon.svg";
import StarIconRating from "../../assets/images/StarIconRating.svg";
import { ArrowRightOutlined } from "@ant-design/icons";
import calendar from "../../assets/images/calendar.svg";
import { getRequest, postRequest } from "../../utils/api.utils";
import Loader from "../../components/Loader";
import { useSearchParams, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { addToCartGA } from "../../events/gaEvent";
import { addToCartPX } from "../../events/facebookPexelsEvent";
import ChatWithUs from "../../components/generic/ChatWithUs";

import vd from "../../assets/open_loading.gif";
import SeoHelmet from "../../components/SeoHelmet";
import OpenListPooja from "./components/OpenListPooja";
import {
  countryCodes,
  countryOptions,
  getCountryCode,
  getCurrency,
  getUtm,
  getUTMParams,
  validatePhoneNumber,
} from "../../utils/helper.utils";
import AuthBlur from "../auth/AuthBlur";
import { addToCartCLARITY } from "../../events/clarityEvetns";
import { isBnplActive, storeBnplData } from "../../utils/bnpl.utils";
import Packages from "./components/Packages";
import PujaForm from "./PujaForm";
import PujaFormComponent from "./components/PujaSankalpFormComponent";
import AboutPuja from "./components/AboutPuja";
import Benefits from "./components/Benefits";
import FaqPuja from "./components/FaqPuja";

export default function BookNowPayLater() {
  const addons_from_localstorage = [];

  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const { package_id, price, pooja_id } = useParams();
  const totalMembers = Number(package_id);

  const userJSON = localStorage.getItem("user");
  const user = userJSON ? JSON.parse(userJSON) : null;
  const [totalPrice, setTotalPrice] = useState();
  const [selectedItems, setSelectedItems] = useState(
    addons_from_localstorage || []
  );

  let cCode = getCountryCode();

  let location_info = localStorage.getItem("location_info")
    ? JSON.parse(localStorage.getItem("location_info"))
    : null;

  let country_code = countryOptions.find(
    (item) => item?.short_name?.toLowerCase() === cCode
  )?.dial_code;

  let isIndia = cCode === "in";

  const [whatsAppNumber, setWhatsAppNumber] = useState(
    user?.userDetails?.phone || ""
  );

  const [poojaDetails, setPoojaDetails] = useState();
  const [whatsappError, setWhatsappError] = useState(false);
  const [loginModel, setLoginModel] = useState(false);
  const [names, setNames] = useState([]);
  const [activeTab, setActiveTab] = useState("");

  const bnpl = searchParams.get("bnpl");

  const [mobileNumber, setMobileNumber] = useState(
    user?.userDetails?.phone || ""
  );

  const [name, setName] = useState(user?.userDetails?.fullname || "");
  const [email, setEmail] = useState(user?.userDetails?.email || "");
  const [gotra, setGotra] = useState(user?.userDetails?.gotra);
  const [filteredPackageData, setFilteredPackageData] = useState();
  const [countryCode, setCountryCode] = useState("91");

  const navigate = useNavigate();

  const [form] = Form.useForm();

  let numberOfPersons;
  if (package_id === "3") {
    numberOfPersons = 4;
  } else if (package_id === "4") {
    numberOfPersons = 6;
  } else {
    numberOfPersons = Number(package_id) || 1;
  }

  useEffect(() => {
    if (bnpl) {
      storeBnplData({ bnpl }, () => {});
    }
  }, [bnpl]);

  useEffect(() => {
    getRequest(
      `/noauth-api/v1/pooja_addons?pooja_id=${pooja_id}&package_id=${package_id}`
    ).then((res) => {
      setData(res.results);
    });
  }, [package_id]);

  useEffect(() => {
    getRequest(`/noauth-api/v1/product/poojaById?pooja_id=${pooja_id}`).then(
      (res) => {
        // pujaBokingStartPageInterKartEvent({
        //   name: res?.results?.name,
        //   id: res?.results?.id,
        //   startingAt: res?.results?.startingAt,
        //   package: {
        //     id: package_id,
        //     price: price,
        //   },
        // });

        localStorage.setItem("listing_country", JSON.stringify(res?.results));

        if (!res?.results) {
          navigate("/");
          return;
        }

        const _filteredPackageData = res.results?.packages?.find(
          (pck) => pck.id == package_id
        );
        let defaultCounryCode =
          _filteredPackageData &&
          countryCodes?.find(
            (c) => c?.currency === _filteredPackageData?.currency
          );

        // setCountryCode(defaultCounryCode?.code);

        setFilteredPackageData(_filteredPackageData);

        if (user) {
          addToCartPX({
            totalPrice,
            ...res?.results,
            pooja_id,
            package_id,
            type: "puja"
          });

          addToCartGA({
            currency: res?.results?.currency_code?.toUpperCase() || "INR",
            value: totalPrice,
            items: [
              {
                item_id: pooja_id,
                item_name: res?.results?.name,
                item_category: "puja",
              },
            ],
          });

          addToCartCLARITY({
            currency: res?.results?.currency_code?.toUpperCase() || "INR",
            value: totalPrice,
            items: [
              {
                item_id: pooja_id,
                item_name: res?.results?.name,
                item_category: "puja",
              },
            ],
          });
        }

        setPoojaDetails(res.results);
      }
    );
  }, [pooja_id]);

  useEffect(() => {
    form.setFieldsValue({
      email,
      name,
    });
  }, []);

  useEffect(() => {
    localStorage.setItem("addons", JSON.stringify(selectedItems));
  }, [selectedItems]);

  const addOnList = (item) => {
    const index = selectedItems.findIndex(
      (selectedItem) => selectedItem.id === item.id
    );

    if (index === -1) {
      setSelectedItems([...selectedItems, item]);
      notification.success({
        message: (
          <span
            style={{ fontSize: "12px" }}
          >{`${item.name} - added successfully`}</span>
        ),
        duration: 1,
        placement: "top",
        style: {
          padding: "8px 12px", // Reduce padding
          minWidth: "200px", // Adjust the width
        },
      });
    } else {
      const updatedItems = [...selectedItems];
      updatedItems.splice(index, 1);
      setSelectedItems(updatedItems);
      notification.info({
        message: (
          <span
            style={{ fontSize: "12px" }}
          >{`${item.name} - removed successfully`}</span>
        ),
        duration: 1,
        placement: "top",
        style: {
          padding: "8px 12px", // Reduce padding
          minWidth: "200px", // Adjust the width
        },
      });
    }
  };

  useEffect(() => {
    if (selectedItems?.length > 0) {
      const totalAmt = selectedItems.reduce(
        (acc, item) => acc + parseInt(item.price),
        0
      );
      setTotalPrice(Number(filteredPackageData?.price) + Number(totalAmt));
    } else {
      setTotalPrice(Number(filteredPackageData?.price));
    }
  }, [selectedItems, filteredPackageData]);

  const [sankalpDetails, setSankalpDetails] = useState(null);
  const [dataForSankalp, setDataForSankalp] = useState(null);

  const getLatestSankalpDetails = (data, packageId) => {
    if (!data || !data.results || !data.results.result) return null;

    const { result } = data.results;

    // Filter Sankalp details with non-zero length and valid package_id
    const filteredResults = result.filter(
      (order) =>
        order.sankalp_details?.length > 0 &&
        (!packageId || order.package_id == packageId)
    );

    // If no result with the same package ID and Sankalp details exists, fallback to the latest object
    if (filteredResults.length === 0) {
      return result
        .filter((order) => order.sankalp_details?.length > 0)
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))[0];
    }

    // Sort by createdAt (latest first) and return the one with maximum Sankalp details
    return filteredResults.sort((a, b) => {
      const dateComparison = new Date(b.createdAt) - new Date(a.createdAt);
      if (dateComparison !== 0) return dateComparison; // Latest first
      return b.sankalp_details.length - a.sankalp_details.length; // Maximum Sankalp details
    })[0];
  };

  const getSankalpDetails = async () => {
    const user = localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user"))
      : null;

    if (user) {
      const response = await getRequest(
        `/noauth-api/v1/pooja/getPujaOrderDetails?user_id=${user.userDetails?.id}`
      );

      const packageId = package_id; // Example package ID
      const latestSankalp = getLatestSankalpDetails(response, packageId);

      setSankalpDetails(latestSankalp);
    }
  };

  const handleSubmit = () => {

    const utm = getUTMParams();

    let reqBody = {};

    const userAgent = window.navigator?.userAgent;
    const platform = window.navigator?.platform;
    const randomString =
      Math.random().toString(20).substring(2, 14) +
      Math.random().toString(20).substring(2, 14);

    const deviceID = `${userAgent}-${platform}-${randomString}`;

    let fullname = "";

    let anyName = dataForSankalp?.formData?.filter(
      (item) => item.name.length > 0
    );

    if (anyName.length === 0) {
      message.error("Enter sankalp details please");
      return;
    }
    if (anyName?.length > 0) {
      fullname = anyName[0].name;
    }

    if (!whatsAppNumber) {
      message.error("Please enter a valid mobile number");
      return;
    }

    reqBody = {
      fullname: fullname,
      countrycode: cCode || "in",
      phone: whatsAppNumber?.slice(3),
      isdCode: "+" + countryCode,
    };

    if (email) {
      reqBody.email = email;
    }

    if (location_info) {
      reqBody.location = location_info;
    }
    if (deviceID) {
      reqBody.device = deviceID;
    }
    if (utm) {
      reqBody.utm = utm;
    }

    setLoading(true);

    postRequest(`/noauth-api/v1/login/user/getotp`, reqBody)
      .then((data) => {
        // setSessionIdData(data.data.results);

        postRequest("/noauth-api/v1/login/user/verifyotp", {
          ...reqBody,
          otp: "2262",
          session_id: data.data.results.session_id,
        })
          .then((res) => {
            setLoading(false);

            localStorage.setItem(
              "user",
              JSON.stringify({ ...res?.data?.results })
            );

            createPujaByBNPL(res?.data?.results);
          })
          .catch((err) => {})
          .finally(() => {
            setLoading(false);
          });
      })
      .catch(() => {
        setLoading(false);
        message.error("Something went wrong. Please try again later");
      });
  };

  useEffect(() => {
    if (user?.userDetails?.id) {
      getSankalpDetails();
    }
  }, [pooja_id]);

  useEffect(() => {
    let initialValues = {
      mobileNumber,
      gotra,
    };

    if (sankalpDetails) {
      let _sankalpDetails = sankalpDetails?.sankalp_details;
      // Prepare initial values for form fields based only on names
      setNames(_sankalpDetails.map((item) => item.name));
      _sankalpDetails?.forEach((item, index) => {
        if (index < numberOfPersons) {
          initialValues[`name_${index}`] = item.name || "";
        }
      });

      // Fill remaining fields with empty strings if _sankalpDetails is less than numberOfPersons
      for (let i = _sankalpDetails.length; i < numberOfPersons; i++) {
        initialValues[`name_${i}`] = "";
      }
    }

    // Set initial form values
    form.setFieldsValue(initialValues);
  }, [sankalpDetails, form]);

  const [isAutoFillGotra, setIsAutoFillGotra] = useState(false);
  const createPujaByBNPL = async (user) => {
    let anyName = dataForSankalp?.formData?.filter(
      (item) => item.name.length > 0
    );

    if (!dataForSankalp?.gotra) {
      window.location.href = "#gotra";
      setIsAutoFillGotra(true);
      return;
    }

    if (anyName?.length === 0) {
      message.error("Enter name for Puja sankalp");
      return;
    }

    setLoading(true);

    const res = await postRequest("/api/v1/pooja/createBnplOrder", {
      bnpl_payment: "pending",
      puja_id: Number(pooja_id),
      package_id: Number(package_id),
      add_ons: selectedItems.map((_item) => _item.id),
      devotee_name: user.userDetails?.fullname,
      mobile_number: user.userDetails?.phone,
      is_addon_booking: false,
      utms: getUtm()
    });

    let orderId = res.data.results.order_id;

    await postRequest("/api/v1/pooja/updatePoojaOrder", {
      id: orderId,
      sankalp_details: dataForSankalp.formData?.map((item) => ({
        name: item.name,
        gotra: dataForSankalp.gotra,
      })),
      wish: dataForSankalp.wish,
      city: dataForSankalp.city,
    });

    // window.location.href = res.data.results.razorPayResponse.short_url
    navigate(`/order/success/${res.data.results.order_id}`);
  };

  useEffect(() => {
    if (!user) {
      if (dataForSankalp?.whatsAppNumber || dataForSankalp?.email) {
        setWhatsAppNumber(dataForSankalp?.whatsAppNumber);
        setEmail(dataForSankalp?.email);
      }
    }
  }, [dataForSankalp]);

  if (!data || !poojaDetails || loading) {
    return (
      <Page>
        <Loader />
      </Page>
    );
  }

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <img src={vd} alt="loading..." />
      </div>
    );
  }

  let isWhatsappTextIndian =
    poojaDetails?.packages?.length && poojaDetails?.packages[0]?.currency;
  const isAllSelected = data.length === selectedItems.length;

  const isDakshina = data.find((item) =>
    item.name.toLowerCase()?.includes("dakshina")
  );
  const isPrasadDelivery = data.find((item) =>
    item.name.toLowerCase()?.includes("prasad")
  );

  let isDakshinaSelected = selectedItems.find(
    (item) => item.id === isDakshina?.id
  )?.id;
  let isPrasadDeliverySelected = selectedItems.find(
    (item) => item.id === isPrasadDelivery?.id
  )?.id;

  const packagesData =
    poojaDetails?.tabs["पूजा पैकेज"] || poojaDetails?.tabs["Packages"];
  return (
    <Page>
      {/* {loginModel ? (
        <AuthBlur
          isOpen={loginModel}
          setIsOpenOutside={() => setLoginModel(false)}
          redirectData={{
            selectedItems,
            poojaDetails,
            filteredPackageData,
            totalPrice,
          }}
          eventHandler={() => {
            addToCartPX({
              totalPrice,
              ...poojaDetails,
              pooja_id,
              package_id,
              type: "puja",
            });
          }}
          onSubmit={createPujaByBNPL}
          btnText="Book Now Pay Later"
        />
      ) : (
        <AuthBlur />
      )} */}
      <ChatWithUs />
      <SeoHelmet
        title={poojaDetails?.name}
        description={poojaDetails?.description}
      />
      <div className=" h-8 flex items-center w-full justify-center bg-green-800">
        <p className="text-center w-full font-bold    text-white text-sm">
          Pay after receiving Puja video with your Name-Gotra
        </p>
      </div>

      <div className="p-2 bg-gray-100">
        <div className="  rounded-lg bg-white p-2  ml-1 mr-1 ">
          {poojaDetails.tithi && (
            <div className="flex flex-col items-center justify-center w-full">
              <Typography.Text className="text-lg font-semibold text-[#D61F69]  line-clamp-2">
                {poojaDetails.tithi}
              </Typography.Text>
              <div className="pt-[0.5px] w-full my-2 bg-gradient-to-r from-transparent via-[#D61F69] to-transparent"></div>
            </div>
          )}
          <div className="flex items-start w-full">
            <div className="w-[100%]">
              <Typography.Text className="block font-semibold text-xl">
                {poojaDetails?.name}
              </Typography.Text>
            </div>
          </div>
          <div>
            <div className="flex items-center">
              <img src={temple} className="mr-2 my-2 w-[15px] h-[15px]" />
              <Typography.Text className="text-sm">
                {poojaDetails?.mandir_name}, ({poojaDetails?.location})
              </Typography.Text>
            </div>

            <div className="flex items-center mb-1">
              <img src={calendar} className="mr-2 w-[15px] h-[15px]" />
              <Typography.Text className="text-sm">
                {moment(poojaDetails.startingAt).format("DD MMMM YYYY, dddd")}
              </Typography.Text>
            </div>

            <div className="flex items-center  justify-between mb-1">
              <div className="flex items-center">
                <img
                  src={StarIconRating}
                  className="mr-2 my-2 w-[15px] h-[15px]"
                />
                <Typography.Text className="text-sm">
                  {filteredPackageData?.name}{" "}
                  <Typography.Text className="font-semibold">
                    ({getCurrency(poojaDetails?.currency_code)}
                    {filteredPackageData?.price})
                  </Typography.Text>
                </Typography.Text>
              </div>
              {poojaDetails && (
                <OpenListPooja
                  isEdit
                  navigateTo={(item) => {
                    navigate(
                      `/pooja/book-now-pay-later/${price}/${item.id}/${pooja_id}`
                    );
                  }}
                  data={
                    poojaDetails?.tabs["पूजा पैकेज"] ||
                    poojaDetails?.tabs["Packages"]
                  }
                  fullData={poojaDetails}
                />
              )}
            </div>
          </div>
        </div>

        <div className="rounded-lg bg-white p-2 mt-2  ml-1 mr-1">
          <div className="flex items-center justify-evenly">
            {["Benefits","Importance","FAQs"].map((item) =>{
              return(
                <div onClick={() =>{
                  if(activeTab === item){
                    setActiveTab("")
                  }else{
                    setActiveTab(item)
                  }
                }} index={item} className={`mr-4 ${activeTab === item ? "text-orange-400":"text-black"}`} >
                  {item}
                  </div>
              )
            })}
          </div>
          {activeTab === "Importance" && (
            <AboutPuja
              label=""
              data={poojaDetails.tabs["Importance"]}
              fullData={poojaDetails}
            />
          )}

          {activeTab === "Benefits" && (
            <Benefits label="" data={poojaDetails.tabs["Benefits"]} />
          )}
          {activeTab === "FAQs" && (
            <FaqPuja label="" data={poojaDetails.tabs["FAQs"]} />
          )}
        </div>
        <div className="pt-[0.5px] mt-2 w-full bg-gradient-to-r from-transparent via-[#D61F69] to-transparent"></div>

        <div className="rounded-lg bg-white p-2 mt-2  ml-1 mr-1">
          <Packages
            label={"Select Package"}
            data={packagesData}
            fullData={poojaDetails}
            detailsNotShow
            filteredPackageData={filteredPackageData}
          />
          <p className="text-center w-full text-green-600 text-sm">
            We will share payment link after your Puja Video
          </p>
        </div>
        <div className="pt-[0.5px] w-full my-2 bg-gradient-to-r from-transparent via-[#D61F69] to-transparent"></div>
        <div className="rounded-lg flex justify-between items-center bg-white p-2 my-2 ml-1 mr-1">
          {isPrasadDelivery && (
            <div
              onClick={() => {
                addOnList(isPrasadDelivery);
              }}
              className={`${
                isPrasadDeliverySelected && "bg-green-100 border-green-600"
              } h-16 w-[48%] border rounded-lg  flex items-center justify-center p-2 font-medium`}
            >
              <div className="flex items-start break-words whitespace-normal">
                <div className="mr-2 text-xl">🍱</div>
                <div className="w-full flex flex-col items-center justify-center">
                  <h1 className="break-words whitespace-normal text-xs">
                    {isPrasadDelivery.name}
                  </h1>
                  <div className="flex items-center mt-2">
                  <h1 className="mr-2">
                    {getCurrency(poojaDetails.currency_code)}
                    {isPrasadDelivery.price}
                  </h1>
                  <div
                  className={`rounded-xl px-2  text-white border  ${!isPrasadDeliverySelected ? "bg-green-500 hover:bg-green-600":"bg-white hover:bg-white border-red-600"}`}
                >
                    {isPrasadDeliverySelected ? (
                    <span className="text-red-600">Remove</span>
                  ) : (
                    
                    <span className="text-white">Add</span>

                  )}
                </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {isDakshina && (
            <div
              onClick={() => {
                addOnList(isDakshina);
              }}
              className={`${
                isDakshinaSelected && "bg-green-100 border-green-600"
              } rounded-lg  h-16 w-[48%] border  flex items-center justify-center p-2 font-medium`}
            >
              <div className="flex items-start break-words whitespace-normal">
                <div className="mr-2 text-xl">🙏</div>
                <div className="w-full flex flex-col items-center justify-center">
                  <h1 className="break-words whitespace-normal text-xs">
                    {isDakshina.name}
                  </h1>
                  <div className="flex items-center mt-2">
                  <h1 className="mr-2">
                    {getCurrency(poojaDetails.currency_code)}
                    {isDakshina.price}
                  </h1>
                  <div
                  className={`rounded-xl px-2  text-white border  ${!isDakshinaSelected ? "bg-green-500 hover:bg-green-600":"bg-white hover:bg-white border-red-600"}`}
                >
                    {isDakshinaSelected ? (
                    <span className="text-red-600">Remove</span>
                  ) : (
                    
                    <span className="text-white">Add</span>

                  )}
                </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        {isDakshinaSelected && (
          <p class="text-center animate-pulse mt-2 text-sm text-green-800">
            🙏 Thank you for your generosity towards Pandit Ji
          </p>
        )}

        <div className="pt-[0.5px] w-full my-2 bg-gradient-to-r from-transparent via-[#D61F69] to-transparent"></div>
        <PujaFormComponent
          user={user}
          isAutoFillGotra={isAutoFillGotra}
          totalMembers={totalMembers}
          onFormChange={(e) => {
            setDataForSankalp(e);
          }}
          isAddress={isPrasadDeliverySelected}
          names={names}
        />

        <div className="flex fixed bottom-0 right-0 py-2 left-0 z-50 bg-white">
          <div className="w-[20%] flex items-center justify-center">
            <h1>
              {getCurrency(poojaDetails?.currency_code)} {totalPrice}
            </h1>
          </div>
          <div className=" px-2 w-[80%]">
            <button
              onClick={() => {
                handleSubmit();
              }}
              className="px-4 flex items-center justify-center w-full bg-green-600 text-white font-bold h-14 text-xl shadow rounded-lg flash-light-button"
            >
              <Typography.Text className=" text-white font-bold  text-lg flex items-center gap-2">
                Book Now Pay Later{" "}
                <ArrowRightOutlined className="font-semibold text-xl animate-bounce-horizontal" />
              </Typography.Text>
            </button>
          </div>
        </div>

        <Divider />
        <Divider />
      </div>
    </Page>
  );
}

export function Desc({ item }) {
  return (
    <div className="flex items-center ">
      <Typography.Text className="block" type="secondary">
        {item.description}
      </Typography.Text>
    </div>
  );
}
