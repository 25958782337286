import React from "react";
import { useNavigate, useLocation } from "react-router-dom";

import AstroGuru from "../assets/astrology.png";
import AstroGuru2 from "../assets/astro_color.png";
import book_puja from "../assets/book_puja.png";
import puja_color from "../assets/puja_color.png";
import yantra from "../assets/torus-yantra.png";
import yantra2 from "../assets/astro_color.png";
import My_Order from "../assets/check-out.png";
import My_Order2 from "../assets/check-out2.png";
import nametag from "../assets/name_tag.png";
import nametag2 from "../assets/name_tag2.png";
import { getCountryCode } from "../utils/helper.utils";

import Icon_Orders from "../assets/bottom_icons/Icon_Orders.png";
import Icon_puja from "../assets/bottom_icons/Icon_puja.png";
import icon_abhimantrit_products from "../assets/bottom_icons/icon_abhimantrit_products.png";
import icon_chadhawa from "../assets/bottom_icons/icon_chadhawa.png";


function BottomNavBar() {
  const navigate = useNavigate();
  const location = useLocation();
  const cCode = getCountryCode();
  const isIndia = cCode === "in";

  // Determine the active item based on the current path
  const getActiveItem = () => {
    const path = location.pathname;
    if (path.startsWith("/online-puja")) return "puja";
    if (path === "/") return "puja";
    if (path.startsWith("/astro-guru")) return "astro";
    if (path.startsWith("/abhimantrit-products")) return "products";
    if (path.startsWith("/name-correction")) return "name";
    if (path.startsWith("/orders")) return "orders";
    return null;
  };

  const activeItem = getActiveItem();

  const mobile = window.innerWidth < 700 

  return (
    <div className={`bottom-nav-bar  ${mobile && "fixed bottom-0 left-0 right-0"} bg-white flex justify-between items-center px-4 h-16  shadow-md border-t  rounded-tl-xl rounded-tr-xl border-gray-300`}>
      {/* Nav Items */}
      {[
        {
          key: "puja",
          label: "Puja",
          icon: Icon_puja,
          activeIcon: Icon_puja,
          link: "/online-puja",
        },
        // {
        //   key: "astro",
        //   label: "Astro",
        //   icon: AstroGuru,
        //   activeIcon: AstroGuru2,
        //   link: "/astro-guru/find",
        // },
        // Only include the Products tab if `isIndia` is true
        ...(isIndia
          ? [
              {
                key: "products",
                label: "Products",
                icon: icon_abhimantrit_products,
                activeIcon: icon_abhimantrit_products,
                link: "/abhimantrit-products",
              },
            ]
          : []),
        // {
        //   key: "name",
        //   label: "Name",
        //   icon: nametag,
        //   activeIcon: nametag2,
        //   link: "/name-correction/",
        // },
          {
          key: "chadhawa",
          label: "Chadhawa",
          icon: icon_chadhawa,
          activeIcon: icon_chadhawa,
          link: "/chadhaava",
        },
        {
          key: "orders",
          label: "Orders",
          icon: Icon_Orders,
          activeIcon: Icon_Orders,
          link: "/orders/1/puja",
        },
      ].map(({ key, label, icon, activeIcon, link }) => (
        <div
          key={key}
          className={`nav-item flex flex-col items-center cursor-pointer  border-b-2 ${
            activeItem === key ? "text-orange-600 border-orange-500" : "text-gray-500"
          }`}
          onClick={() => navigate(link)}
        >
          <div className="w-7 h-7 flex items-center justify-center mb-1">
            <img
              src={activeItem === key ? activeIcon : icon}
              className="w-full h-full object-contain"
              alt={`${label} Icon`}
            />
          </div>
          <span
            className={`text-xs font-medium ${
              activeItem === key ? "text-orange-600" : "text-gray-500"
            }`}
          >
            {label}
          </span>
        </div>
      ))}
    </div>
  );
}

export default BottomNavBar;
