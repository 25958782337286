import React, { useState } from "react";
import { Button } from "antd";
import { PlayCircleOutlined, DownCircleOutlined } from "@ant-design/icons";
import moment from "moment";
import WhatToDoDrawer from "../components/WhatToDoDrawer";

const CongratulationBannerChdhavaa = ({ order }) => {


  const chadhavaa_status = order.chadawa_status?.toLowerCase();
  const [isDrawerOpen, setisDrawerOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const goToPujaUpdates = () => {
    window.location.href = "#pujaUpdates";
  };

  let title = "";
  let description = "";
  let btnText = "";
  let icon = null;
  let css = "";
  let color = "";
  let href = ""
  
  if (!chadhavaa_status || chadhavaa_status === "pending") {
    title = "Chadhawa Booking Confirmed!";
     description = `Chadhawa Time will be shared with you by 6PM on ${moment(
      order.chadawa_starting_at
        )
          .subtract(1, "days")
          .format("DD MMMM")}`;
    btnText = "Chadhawa Updates";
    icon = <DownCircleOutlined />;
    css = "bg-green-700 from-green-600 to-green-700";
    color = "text-green-700";
  }
  else if (!chadhavaa_status || chadhavaa_status === "timing_shared") {
    title = "Chadhawa Timing Confirmed!";
      description = `Pandit Ji will offer your chadhawa with complete rituals at 11 AM, ${moment(
        order.chadawa_starting_at
        ).format("DD MMMM")}`;
    btnText = "Chadhawa Updates";
    icon = <DownCircleOutlined />;
    css = "bg-green-700 from-green-600 to-green-700";
    color = "text-green-700";
  }
  else if (!chadhavaa_status || chadhavaa_status === "started") {
    title = "Chadhawa has been started!";
    description = "Your chadhawa video will arrive in 2-3 days.";
    btnText = "Chadhawa Updates";
    icon = <DownCircleOutlined />;
    css = " bg-green-700 from-green-600 to-green-700";
    color = "text-green-700";
  } else if (chadhavaa_status === "conducted") {
    title = "Chadhawa has been offered";
    description =
      "Chadhawa video with your name will arrive in 2-3 days.";
    btnText = "See Chadhawa Updates";
    css = " bg-green-700 from-yellow-600 to-yellow-700";
    color = "text-green-800";

    icon = <DownCircleOutlined />;
  } else if (chadhavaa_status === "delivered") {
    title = "Your chadhawa Video has arrived.";
    description = ``;
    btnText = "Chadhawa Video";
    icon = <PlayCircleOutlined />;
    color = "text-green-700";
    css = " bg-green-700 from-green-600 to-green-700";
    href=order.video_url
  }

  const toggleDrawer = () => {
    setisDrawerOpen(!isDrawerOpen);
  };

  const toggleDrawerOfferings = () => {
    setIsOpen(!isOpen);
  };

  let isPending = !chadhavaa_status || chadhavaa_status === "pending";

  return (
    <div className="w-full">
      
      <WhatToDoDrawer
        isDrawerOpen={isDrawerOpen}
        toggleDrawer={toggleDrawer}
        title="Chadhawa Guidelines"
        pujaDetails={order?.details?.chadawa_details}
      />

      <div className={`${css} w-full text-white text-center py-8 pb-8 px-6`}>
        {/* Content */}
        <h1 className="text-xl font-bold mb-2">{title}</h1>
        <p className="text-sm mb-4">{description}</p>
        <div className="flex items-center justify-between flex-wrap">
          <Button
            onClick={( ) =>{
              if(href){
                window.location.href = href
              }else{
                goToPujaUpdates()
              }
            }}
            type="primary"
            className={`bg-white text-xs my-1 hover:bg-gray-100 focus:bg-gray-200 font-semibold rounded-full px-2 py-2 flex items-center justify-center mx-auto shadow-md transition-all ${color}`}
          >
            {btnText} {icon}
          </Button>
          {isPending && (
            <Button
              onClick={toggleDrawer}
              type="primary"
              className={`bg-white text-xs  my-1 hover:bg-gray-100 focus:bg-gray-200 font-semibold rounded-full px-2 py-2 flex items-center justify-center mx-auto shadow-md transition-all ${color}`}
            >
              Chadhawa Guidelines
            </Button>
          )}

          
        </div>
      </div>
    </div>
  );
};

export default CongratulationBannerChdhavaa;
